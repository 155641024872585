import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";
import TrainingService from "../../services/TrainingService";
import {useMessage} from "../message/MessageProvider";

/**
 * Диалоговое окно для отписки участника от тренировки (пока только незарегистрированного участника)
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.training - Объект тренировки.
 * @param {boolean} props.open - Флаг, указывающий, открыто ли окно.
 * @param {Function} props.handleClose - Функция для закрытия диалогового окна.
 * @param {Object} props.participant - Объект участника, которого нужно отписать.
 * @returns {JSX.Element} Компонент диалогового окна.
 */
const UnsubscribeParticipantDialog = ({training, open, handleClose, participant}) => {
    const {processError, processSuccess} = useMessage();

    /**
     * Обработчик для отписки участника от тренировки.
     */
    const unsubscribe = () => {
        TrainingService.removeUnregistered(training.id, participant.id)
            .then((_r) => {
                processSuccess("Вы успешно отписали участника", false);
                window.location.reload(); // TODO: задача на рефакторинг компонента расписания. не перезагружать страницу, а обновлять запрос
            })
            .catch((_e) => {
                processError(
                    `Произошла ошибка. Попробуйте выполнить действие позже или обратитесь к администратору`,
                    false
                );
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Отписка участника</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы хотите отписать из тренировки участника <b>{participant?.name}</b>. Вы уверены?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="secondary">Отмена</Button>
                <Button autoFocus onClick={unsubscribe}>Отписать</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsubscribeParticipantDialog;
