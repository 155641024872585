import React, {useEffect, useState} from 'react';
import GymService from "../../../services/GymService";
import DeleteModal from "../../DeleteModal";
import CreateOrEditGymModal from "./CreateOrEditGymModal";
import {globalProps} from '../../../config'
import AdministrationService from "../../../services/AdministrationService";
import {Table} from "react-bootstrap";
import Form from 'react-bootstrap/Form';

const GymsComponent = () => {
    const [gyms, setGyms] = useState([]);
    const getGyms = () => {
        AdministrationService.getGyms().then((res) => {
            setGyms(res.data);
        });
    }

    useEffect(() => {
        getGyms();
    }, []);

    return (
        <>
            <div className="mb-3">
                <CreateOrEditGymModal mode={globalProps.mode.create}/>
            </div>
            <Table responsive>
                <thead>
                <tr className="table-active">
                    <th>#</th>
                    <th>Название</th>
                    <th>Адрес</th>
                    <th>Активный</th>
                    <th></th>
                </tr>
                </thead>
                {gyms.length ?
                    <tbody>
                    {gyms.map((gym, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{gym.name}</td>
                            <td>{gym.address}</td>
                            <td>
                                <Form.Check
                                    disabled
                                    type="switch"
                                    checked={gym.active}
                                />
                            </td>
                            <td>
                                <div className={"d-flex gap-1"}>
                                    <CreateOrEditGymModal gym={gym} mode={globalProps.mode.edit}/>
                                    <DeleteModal id={gym.id} fnDelete={GymService.delete}/>
                                </div>
                            </td>
                        </tr>)
                    }
                    </tbody>
                    :
                    <caption className={"text-center"}>Залы не найдены</caption>
                }
            </Table>

        </>
    );
};

export default GymsComponent;
