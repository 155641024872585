import React, {useCallback, useEffect, useState} from "react";
import RequestsService from "../../../services/RequestsService";
import {useMessage} from "../../message/MessageProvider";
import PaginationComponent from "../../PaginationComponent";
import {Card} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {globalProps} from "../../../config";
import RequestsTable from "./tableView/RequestsTable";
import RequestsCards from "./cardsView/RequestsCards";

/** Станица с заявками в админке: фильтр по статусу, таблица или карточное представление, пагинация*/
const RequestsComponent = () => {
    const [isMobile] = useState(
        window.matchMedia("(max-width: 479px)").matches
    )

    const {processError, processSuccess} = useMessage();

    const [requestsPage, setRequestsPage] = useState([]);
    const [requests, setRequests] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);

    const [requestsPageCfg, setRequestsPageCfg] = useState({
        size: 15,
        page: 0,
        status: 'NEW'
    });

    const filterByStatus = (e) => {
        const newCfg = {
            ...requestsPageCfg,
            page: 0,
            status: e.target.value
        };
        setRequestsPageCfg(newCfg);
    };

    const getRequestsWithOptions = useCallback(() => {
        const options = {
            ...Object.fromEntries(
                Object.entries(requestsPageCfg).filter(([_, value]) => value !== "")
            )
        }
        RequestsService.getAllRequests(options).then(res => {
            setRequestsPage(res.data);
            setRequests(res.data.items);
        }).catch((error) => {
            processError(error.response.data.message, false);
        });
    }, [requestsPageCfg]);

    useEffect(() => {
        getRequestsWithOptions();
    }, [dataChanged, requestsPageCfg]);

    /** Принять заявку
     * @param requestId id заявки
     * */
    const accept = useCallback((requestId) => {
        RequestsService.acceptRequest(requestId).then(_res => {
            processSuccess('Заявка принята', false);
            setDataChanged(prev => !prev);
        }).catch(error => {
            processError(error.response.data.message, false);
        });
    }, [processSuccess, processError]);

    /** Отклонить заявку
     * @param requestId id заявки
     * */
    const reject = useCallback((requestId) => {
        RequestsService.rejectRequest(requestId).then(_res => {
            processSuccess('Заявка отклонена', false);
            setDataChanged(prev => !prev);
        }).catch(error => {
            processError(error.response.data.message, false);
        });
    }, [processSuccess, processError]);

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted">Статус</Card.Subtitle>
                    <Form.Select value={requestsPageCfg.status} onChange={filterByStatus}>
                        <option value=""></option>
                        <option value="NEW">{globalProps.requestsStatus.NEW}</option>
                        <option value="ACCEPTED">{globalProps.requestsStatus.ACCEPTED}</option>
                        <option value="REJECTED">{globalProps.requestsStatus.REJECTED}</option>
                    </Form.Select>
                </Card.Body>
            </Card>

            {isMobile ?
                <>
                    <RequestsCards requests={requests}
                                   acceptHandler={accept}
                                   rejectHandler={reject}
                    />

                    <PaginationComponent data={requestsPage}
                                         pageCfg={requestsPageCfg}
                                         setPageCfg={setRequestsPageCfg}
                                         changeItemsCount={false}
                                         hideTotalCount={true}
                                         maxVisiblePages={5}
                    />
                </>
                :
                <>
                    <RequestsTable requests={requests}
                                   acceptHandler={accept}
                                   rejectHandler={reject}
                    />

                    <PaginationComponent data={requestsPage}
                                         pageCfg={requestsPageCfg}
                                         setPageCfg={setRequestsPageCfg}
                                         changeItemsCount={true}
                    />
                </>
            }
        </>
    )
}

export default RequestsComponent;