import TrainingService from "../../services/TrainingService";
import React, {useEffect, useMemo, useState} from "react";
import {Box, Chip, Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import Badge from '@mui/material/Badge';
import {useLocalState} from "../../util/useLocatStorage";
import UnsubscribeParticipantDialog from "../trainings/UnsubscribeParticipantDialog";

/**
 * Компонент для отображения участников тренировки в карточке тренировки.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.training - Объект тренировки.
 * @returns {JSX.Element} Компонент участников тренировки.
 */
const TrainingPageParticipants = ({training}) => {
    const [currentUserId] = useLocalState({}, "currentUserId");
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [openUnsubscribeDialog, setOpenUnsubscribeDialog] = useState(false);

    const [isMobile] = useState(
        window.matchMedia("(max-width: 479px)").matches
    );

    const columnXS = useMemo(() => isMobile ? 12 : 4, [isMobile]);

    const [baseTrainingParticipants, setBaseTrainingParticipants] = useState([]);
    const [reserveTrainingParticipants, setReserveTrainingParticipants] = useState([]);

    /**
     * Загружает участников тренировки и обновляет состояния.
     */
    const loadTrainingParticipants = () => {
        TrainingService.getTrainingParticipants(training.id).then((res) => {
            if (res.data.base.length) {
                setBaseTrainingParticipants(res.data.base);
            }
            if (res.data.reserve?.length) {
                setReserveTrainingParticipants(res.data.reserve);
            }
        });
    }

    useEffect(() => {
        loadTrainingParticipants();
    }, []);

    /**
     * Закрывает диалоговое окно отписки участника.
     */
    const handleUnsubscribeDialogClose = () => {
        setOpenUnsubscribeDialog(false);
    };

    /**
     * Открывает диалоговое окно отписки участника.
     *
     * @param {Object} participant - Объект участника для отписки.
     */
    const handleUnsubscribeDialogOpen = (participant) => {
        setSelectedParticipant(participant);
        setOpenUnsubscribeDialog(true);
    };

    return (
        <Box sx={{flexGrow: 1, p: 2}} className="aanutfullina">
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {baseTrainingParticipants.length ?
                    baseTrainingParticipants.map((item) => (
                        <Grid xs={columnXS} key={`page-training-participant-${item.id}`}>
                            {training.trainingAdmin?.id === item.id ?
                                <Badge badgeContent="Админ" color="primary" className="w-100">
                                    <Card className="w-100">
                                        <Box sx={{p: 2}}>
                                            <div>{item.name}</div>
                                        </Box>
                                    </Card>
                                </Badge>
                                :
                                (
                                    (item.addedBy && (item.addedBy.id === currentUserId || currentUserRole === 'ADMIN')) ?
                                        <Badge color="error" className="w-100" badgeContent="x" role="button"
                                               onClick={() => handleUnsubscribeDialogOpen(item)}>
                                            <Card className="w-100">
                                                <Box sx={{p: 2}}>
                                                    <div>
                                                        {`${item.name} ${item.addedBy ? '(Записал ' + item.addedBy.lastName
                                                            + ' ' + item.addedBy.firstName + ')' : ''}`}
                                                    </div>
                                                </Box>
                                            </Card>
                                        </Badge>
                                        :
                                        <Card>
                                            <Box sx={{p: 2}}>
                                                <div>
                                                    {`${item.name} ${item.addedBy ? '(Записал ' + item.addedBy.lastName
                                                        + ' ' + item.addedBy.firstName + ')' : ''}`}
                                                </div>
                                            </Box>
                                        </Card>
                                )
                            }
                        </Grid>
                    ))
                    :
                    <Alert severity="info">Нет записанных участников</Alert>
                }
            </Grid>
            {(reserveTrainingParticipants.length > 0 && training.status === 'NEW') &&
                <>
                    <Typography gutterBottom component="div" className="mt-3">
                        Резерв:
                    </Typography>
                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                        {
                            reserveTrainingParticipants.map((item, index) => (
                                <Grid xs={columnXS} key={`page-training-participant-${item.id}`}>
                                    {
                                        (item.addedBy && (item.addedBy.id === currentUserId || currentUserRole === 'ADMIN')) ?
                                            <Badge color="error" badgeContent="x" role="button" className="w-100"
                                                   onClick={() => handleUnsubscribeDialogOpen(item)}>
                                                <Card sx={{backgroundColor: '#f0f0f0'}} className="w-100">
                                                    <Box sx={{p: 2}} className="d-flex gap-2">
                                                        <Chip label={index + 1} size="small" variant="outlined"/>
                                                        <div>
                                                            {`${item.name} ${item.addedBy ? '(Записал ' + item.addedBy.lastName
                                                                + ' ' + item.addedBy.firstName + ')' : ''}`}
                                                        </div>
                                                    </Box>
                                                </Card>
                                            </Badge>
                                            :
                                            <Card sx={{backgroundColor: '#f0f0f0'}} className="w-100">
                                                <Box sx={{p: 2}} className="d-flex gap-2">
                                                    <Chip label={index + 1} size="small" variant="outlined"/>
                                                    <div>
                                                        {`${item.name} ${item.addedBy ? '(Записал ' + item.addedBy.lastName
                                                            + ' ' + item.addedBy.firstName + ')' : ''}`}
                                                    </div>
                                                </Box>
                                            </Card>
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                </>
            }

            <UnsubscribeParticipantDialog
                open={openUnsubscribeDialog}
                handleClose={handleUnsubscribeDialogClose}
                training={training}
                participant={selectedParticipant}
            />
        </Box>
    )
}

export default TrainingPageParticipants;
