import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import UserService from "../../../services/UserService";
import BlockIcon from '@mui/icons-material/Block';
import {IconButton} from "@mui/material";
import {useMessage} from "../../message/MessageProvider";

/**
 * Компонент для отображения модального окна блокировки пользователя.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.user - Объект пользователя, которого нужно забанить.
 * @param {Function} props.setDataChanged - Функция для обновления данных страницы
 * @returns {JSX.Element} Модальное окно для блокировки пользователя.
 */
export default function BanUserModal({user, setDataChanged}) {
    const {processError, processSuccess} = useMessage();

    const [show, setShow] = useState(false);

    /**
     * Функция для блокировки пользователя.
     */
    const banUser = function () {
        UserService.ban(user.id).then(res => {
            processSuccess(res.data?.message ? res.data.message : "Пользователь успешно забанен!", false);
            setDataChanged(prev => !prev);
        }).catch(e => {
            processError(e.response && e.response.data ? e.response.data : "Сбой!", false);
        });
        setShow(false);
    };

    return (
        <>
            <IconButton onClick={() => setShow(true)} title="Забанить">
                <BlockIcon/>
            </IconButton>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Блокировка пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Вы хотите забанить пользователя <b>{user.lastName} {user.firstName}</b>. Вы уверены?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Отмена</Button>
                    <Button variant="primary" onClick={banUser}>Забанить</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
