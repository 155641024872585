import React, {useMemo} from "react";
import EditUserModal from "../EditUserModal";
import DeleteModal from "../../../DeleteModal";
import UserService from "../../../../services/UserService";
import BanUserModal from "../BanUserModal";
import {Alert} from "react-bootstrap";
import PaginationComponent from "../../../PaginationComponent";
import {globalProps} from "../../../../config";
import TableEntityCard from "../../TableEntityCard";
import UsersFilter from "./UsersFilter";
import Button from "react-bootstrap/Button";
import {formatDate1} from "../../../../util/dateFormatter";

/**
 * Компонент для отображения всех пользователей в мобильной версии.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.filters - Текущие фильтры.
 * @param {Function} props.filterByOption - Функция для фильтрации пользователей.
 * @param {Function} props.clearFilters - Функция для очистки фильтров.
 * @param {Array} props.users - Список пользователей.
 * @param {Object} props.usersPage - Конфигурация пагинации пользователей.
 * @param {Object} props.allUsersPageCfg - Конфигурация страницы всех пользователей.
 * @param {Function} props.setAllUsersPageCfg - Функция для установки конфигурации страницы.
 * @param {Function} props.setDataChanged - Функция для обновления данных страницы
 */
const AllUsersComponentMobile = ({
                                     filters,
                                     filterByOption,
                                     clearFilters,
                                     users,
                                     usersPage,
                                     allUsersPageCfg,
                                     setAllUsersPageCfg,
                                     setDataChanged
                                 }) => {

    /**
     * Формирует массив с атрибутами пользователя для отображения в карточке.
     *
     * @param {Object} user - Объект пользователя.
     * @returns {Array} Массив строк для отображения.
     */
    const generateCardPropsArray = (user) => {
        const formattedBirthday = formatDate1(user.birthday);
        return [
            `${user.lastName} ${user.firstName} ${user.middleName}`,
            formattedBirthday,
            user.email,
            user.phoneNumber,
            globalProps.userRole[user.role],
            globalProps.userStatus[user.status]
        ];
    };

    /**
     * Получает цвет границы карточки пользователя в зависимости от статуса и роли.
     *
     * @param {string} status - Статус пользователя.
     * @param {string} role - Роль пользователя.
     * @returns {string} Цвет границы.
     */
    const getBorderColor = (status, role) => {
        if (status === 'BANNED') {
            return 'danger';
        }
        if (status === 'ARCHIVED') {
            return 'warning';
        }
        if (role === 'ADMIN') {
            return 'success';
        }
        if (role === 'RESPONSIBLE_USER') {
            return 'info';
        }
        return 'transparent';
    };

    /**
     * Возвращает массив действий над пользователем в карточке.
     *
     * @param {Object} user - Объект пользователя.
     * @returns {Array} Массив React-компонентов действий.
     */
    const getUserActions = (user) => {
        let actions = [
            <EditUserModal user={user} key={`edit-${user.id}`} setDataChanged={setDataChanged}/>,
            <DeleteModal
                id={user.id}
                fnDelete={UserService.delete}
                title="Архивирование пользователя"
                text="Вы хотите архивировать пользователя, вы уверены?"
                key={`delete-${user.id}`}
            />
        ];
        if (user.status !== 'BANNED') {
            actions.push(<BanUserModal user={user} key={`ban-${user.id}`} setDataChanged={setDataChanged}/>)
        }
        return actions;
    };

    const showClearFiltersBtn = useMemo(
        () => Object.values(filters).some(value => value !== null && value !== undefined && value !== ''),
        [filters]
    );

    return (
        <>
            <div className="d-flex gap-0 flex-row-reverse mb-3">
                <UsersFilter initialFilters={filters} filter={filterByOption}/>
                {showClearFiltersBtn &&
                    <Button variant="link" size="sm" onClick={clearFilters}>
                        <small>Очистить фильтры</small>
                    </Button>
                }
            </div>
            {users.map(user =>
                <TableEntityCard props={generateCardPropsArray(user)}
                                 borderColor={getBorderColor(user.status, user.role)}
                                 actions={getUserActions(user)}
                                 key={user.id}
                />
            )}

            {!users.length && <Alert>Пользователи не найдены</Alert>}

            <PaginationComponent data={usersPage}
                                 pageCfg={allUsersPageCfg}
                                 setPageCfg={setAllUsersPageCfg}
                                 changeItemsCount={false}
                                 hideTotalCount={true}
            />
        </>
    );
}

export default AllUsersComponentMobile;
