import React, {useEffect, useState} from "react";
import AdministrationService from "../../../../services/AdministrationService";
import {globalProps} from "../../../../config";
import TableEntityCard from "../../TableEntityCard";
import {Alert} from "react-bootstrap";
import DeleteModal from "../../../DeleteModal";
import CreateOrEditSportTypeModal from "../CreateOrEditSportTypeModal";
import SportTypesService from "../../../../services/SportTypesService";

const SportTypesMobile = () => {
    const [sportTypes, setSportTypes] = useState([]);
    const getSportTypes = () => {
        AdministrationService.getSportTypes().then((res) => {
            setSportTypes(res.data);
        });
    }

    useEffect(() => {
        getSportTypes();
    }, []);

    const generateCardPropsArray = (sportType) => {
        return [
            sportType.name,
            sportType.active ? "Активный" : "Не активный"
        ]
    }

    const getUserActions = (sportType) => {
        return [
            <CreateOrEditSportTypeModal sportType={sportType}
                                        mode={globalProps.mode.edit}/>,
            <DeleteModal id={sportType.id} fnDelete={SportTypesService.delete}/>
        ]
    }

    return (
        <>
            <div className="mb-3">
                <CreateOrEditSportTypeModal mode={globalProps.mode.create}/>
            </div>
            {sportTypes.map(sportType =>
                <TableEntityCard props={generateCardPropsArray(sportType)}
                                 actions={getUserActions(sportType)}
                                 borderColor={sportType.active ? 'transparent' : 'danger'}
                                 key={sportType.id}
                />
            )}

            {!sportTypes.length && <Alert>Виды спорта не найдены</Alert>}
        </>
    )
}

export default SportTypesMobile;