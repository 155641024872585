import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as React from "react";
import {useCallback, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Grid, IconButton, Paper, Typography} from "@mui/material";
import {Close, AddPhotoAlternate} from "@mui/icons-material";
import NewsService from "../../../services/NewsService";

const CreateNews = () => {
    const [show, setShow] = useState(false);

    const [newsDescription, setNewsDescription] = useState('');
    const [error, setError] = useState('');

    const handleChange = (content) => {
        setNewsDescription(content);
    };

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newFiles = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        // если сделаем добавление нескольких фото в новости + в input добавить multiple
        //setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setSelectedFiles(newFiles);
    };

    const handleRemoveImage = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const saveNews = useCallback(() => {
        if (newsDescription.trim() === '' || newsDescription === '<p><br></p>') {
            setError('Текст новости не может быть пустым');
        } else {
            setError('');
            const formData = new FormData();
            selectedFiles.forEach((fileData) => {
                formData.append('file', fileData.file);
            });
            formData.append('description', newsDescription);

            NewsService.save(formData).then(_res => {
                window.location.reload();
            });
        }
    }, [selectedFiles, newsDescription]);

    return (
        <>
            <Button onClick={() => setShow(true)}>Создать новость</Button>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Создание новости</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactQuill value={newsDescription} onChange={handleChange}/>
                    {error && <div style={{color: 'red', marginTop: '0.5rem'}}>{error}</div>}

                    <div className="mt-5 mx-5 my-5">
                        <Grid container spacing={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        id="icon-button-file"
                                        style={{display: 'none'}}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AddPhotoAlternate/>
                                        </IconButton>
                                    </label>
                                </Grid>
                                {selectedFiles.map((fileData, index) => (
                                    <Grid item key={`create-news-file-${index}-${fileData.file.name}`} xs={6} sm={4}
                                          md={3}>
                                        <Paper elevation={3} style={{position: 'relative', padding: '10px'}}>
                                            <img
                                                src={fileData.preview}
                                                alt="Preview"
                                                style={{width: '100%', height: 'auto', marginBottom: '10px'}}
                                            />
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => handleRemoveImage(index)}
                                                style={{position: 'absolute', top: '5px', right: '5px', color: 'red'}}
                                            >
                                                <Close/>
                                            </IconButton>
                                            <Typography variant="body2" align="center" noWrap>
                                                {fileData.file.name}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Отмена
                    </Button>
                    <Button onClick={saveNews}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateNews;