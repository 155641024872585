import React, {useEffect, useState} from "react";
import DeleteModal from "../../DeleteModal";
import SportTypesService from "../../../services/SportTypesService";
import CreateOrEditSportTypeModal from "./CreateOrEditSportTypeModal";
import {globalProps} from "../../../config";
import AdministrationService from "../../../services/AdministrationService";
import {Table} from "react-bootstrap";
import Form from 'react-bootstrap/Form';

const SportTypesComponent = () => {
    const [sportTypes, setSportTypes] = useState([]);
    const getSportTypes = () => {
        AdministrationService.getSportTypes().then((res) => {
            setSportTypes(res.data);
        });
    }

    useEffect(() => {
        getSportTypes();
    }, []);

    return (
        <>
            <div className="mb-3">
                <CreateOrEditSportTypeModal mode={globalProps.mode.create}/>
            </div>
            <Table responsive>
                <thead>
                <tr className="table-active">
                    <th>#</th>
                    <th>Название</th>
                    <th>Активный</th>
                    <th></th>
                </tr>
                </thead>
                {sportTypes.length ?
                    <tbody>
                    {sportTypes.map((sportType, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{sportType.name}</td>
                            <td>
                                <Form.Check
                                    disabled
                                    type="switch"
                                    checked={sportType.active}
                                />
                            </td>
                            <td>
                                <div className={"d-flex gap-1"}>
                                    <CreateOrEditSportTypeModal sportType={sportType}
                                                                mode={globalProps.mode.edit}/>
                                    <DeleteModal id={sportType.id} fnDelete={SportTypesService.delete}/>
                                </div>
                            </td>
                        </tr>)
                    }
                    </tbody>
                    :
                    <caption className={"text-center"}>Виды спорта не найдены</caption>
                }
            </Table>
        </>
    )
}
export default SportTypesComponent;