import React, {useMemo, useState} from 'react';
import {Badge, Card, Image} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import basketballImg from '../../images/sportTypes/basketball.png';
import volleyballImg from '../../images/sportTypes/volleyball.png';
import UnsubscribeFromTrainingDialog from "./UnsubscribeFromTrainingDialog";
import TrainingParticipantsAccordion from "./TrainingParticipantsAccordion";
import {useLocalState} from "../../util/useLocatStorage";
import SubscribeToTrainingDialog from "./SubscribeToTrainingDialog";
import openTrainingCard from "../trainingPage/util";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {IconButton} from "@mui/material";
import {formatDate5} from "../../util/dateFormatter";

const CurrentUserTrainingComponent = (thisTraining) => {
    // Для стилей под мобилки
    const [matches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    //Открытие/закрытие окон подтверждения действия
    const [open, setOpen] = React.useState("");

    const handleClose = () => {
        setOpen("");
    };

    const isCurrentUserTrainingAdmin = useMemo(() => {
        return !!thisTraining.training.currentUserTrainingAdmin;
    }, thisTraining);

    return (
        <><Card className="mb-2">
            <Card.Body>
                <Stack direction="horizontal">
                    <div>
                        <Stack>
                            {thisTraining.training.status === 'CANCELLED' &&
                                <Badge bg="secondary" className="mb-2">Отменена</Badge>
                            }
                            {thisTraining.training.status === 'COMPLETED' &&
                                <Badge bg="success" className="mb-2">Завершена</Badge>
                            }

                            {isCurrentUserTrainingAdmin && <Badge bg="info">Вы администратор</Badge>}

                            <div>
                                <IconButton onClick={() => openTrainingCard(thisTraining.training.id)}>
                                    <OpenInNewIcon/>
                                </IconButton>
                                {thisTraining.training.sportType.name} - {thisTraining.training.trainingType?.name}
                            </div>
                            <div className="fw-bold">{formatDate5(Date.parse(thisTraining.training.date))}</div>
                            <div>{`${thisTraining.training.gym.address} (${thisTraining.training.gym.name})`}</div>
                            <div>{`${thisTraining.training.startTime} - ${thisTraining.training.endTime}`}</div>
                        </Stack>
                    </div>
                    <div className="ms-auto">
                        <Stack className="mx-auto" gap={4}>
                            <div>
                                {thisTraining.training.sportType.iconFileName &&
                                thisTraining.training.sportType.iconFileName === "basketball.png" ?
                                    <Image src={basketballImg} className="float-end"/>
                                    :
                                    thisTraining.training.sportType.iconFileName === "volleyball.png" &&
                                    <Image src={volleyballImg} className="float-end"/>
                                }
                            </div>
                            {thisTraining.training.status === 'NEW' &&
                                <Stack direction={matches ? "horizontal" : null} gap={2}>
                                    {thisTraining.training.currentUserRegisteredForTraining ?
                                        <Button variant="outline-danger"
                                                onClick={() => setOpen("unsubscribe")}>
                                            Отписаться
                                        </Button>
                                        :
                                        <Button variant="outline-success"
                                                onClick={() => setOpen("subscribe")}>
                                            Записаться участником
                                        </Button>
                                    }
                                    {(thisTraining.training.currentUserRegisteredForTraining &&
                                            !thisTraining.training.trainingAdmin &&
                                            (currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER')) &&
                                        <Button variant="outline-success"
                                                onClick={() => setOpen("subscribeAsAdmin")}>
                                            Записаться админом
                                        </Button>
                                    }
                                    {isCurrentUserTrainingAdmin &&
                                        <Button variant="outline-danger" onClick={() => setOpen("unsubscribeAsAdmin")}>
                                            Отписаться от администрирования
                                        </Button>
                                    }
                                </Stack>
                            }
                        </Stack>
                    </div>
                </Stack>

                <TrainingParticipantsAccordion training={thisTraining.training}/>
            </Card.Body>
        </Card>

            {/*Окно подтвеждения записи*/}
            <SubscribeToTrainingDialog training={thisTraining.training} open={open} handleClose={handleClose}/>

            {/*Окно потверждения отписки от тренировки*/}
            <UnsubscribeFromTrainingDialog training={thisTraining.training} open={open} handleClose={handleClose}/>
        </>
    );
};

export default CurrentUserTrainingComponent;
