import axios from "../axios";

class RequestsService {
    createChangeGameLevelRequest(sportTypeId, gameLevelId) {
        return axios.post('requests/create?requestType=CHANGE_GAME_LEVEL', {
            sportTypeId: sportTypeId,
            gameLevelId: gameLevelId
        });
    }

    getAllRequests(options) {
        return axios.post('administration/requests', options);
    }

    acceptRequest(requestId) {
        return axios.post('requests/accept?requestId=' + requestId);
    }

    rejectRequest(requestId) {
        return axios.post('requests/reject?requestId=' + requestId);
    }
}

export default new RequestsService();
