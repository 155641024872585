import {Nav} from "react-bootstrap";
import {useLocalState} from "../util/useLocatStorage";
import React from "react";

const MobileNavigationComponent = () => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [selectedItem, setSelectedItem] = useLocalState('homePage', 'selectedItem');

    const navLinkClasses = "d-flex flex-column align-items-center";
    const selectedNavLinkStyle = {color: "white"};
    const navTextStyle = {fontSize: "0.5rem"};

    return (
        <footer className={'position-fixed bottom-0 w-100 bg-light overflow-hidden'}>
            {/*иконки*/}
            <svg style={{display: "none"}}>
                <symbol id="icon-home-page">
                    <path
                        d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
                </symbol>
                <symbol id="icon-schedule">
                    <path
                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </symbol>
                <symbol id="icon-news">
                    <path
                        d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
                    <path
                        d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                </symbol>
                <symbol id="icon-task">
                    <path fillRule="evenodd"
                          d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"/>
                    <path
                        d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z"/>
                    <path fillRule="evenodd"
                          d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"/>
                </symbol>
                <symbol id="icon-user">
                    <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                </symbol>
                <symbol id="icon-admin">
                    <path
                        d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                    <path
                        d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                </symbol>
            </svg>

            <Nav className="justify-content-center" defaultActiveKey="/">
                <Nav.Item onClick={() => setSelectedItem('homePage')}
                          className={selectedItem === 'homePage' ? "bg-primary" : null}>
                    <Nav.Link href="/" className={navLinkClasses}
                              style={selectedItem === 'homePage' ? selectedNavLinkStyle : null}>
                        <svg width="24" height="24" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                            <use href="#icon-home-page"/>
                        </svg>
                        <span style={navTextStyle}>Главная</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setSelectedItem('schedule')}
                          className={selectedItem === 'schedule' ? "bg-primary" : null}>
                    <Nav.Link href="/trainings/schedule" className={navLinkClasses}
                              style={selectedItem === 'schedule' ? selectedNavLinkStyle : null}>
                        <svg width="24" height="24" fill="currentColor" className="bi bi-calendar2" viewBox="0 0 16 16">
                            <use href="#icon-schedule"/>
                        </svg>
                        <span style={navTextStyle}>Расписание</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setSelectedItem('news')}
                          className={selectedItem === 'news' ? "bg-primary" : null}>
                    <Nav.Link href="/news" className={navLinkClasses}
                              style={selectedItem === 'news' ? selectedNavLinkStyle : null}>
                        <svg width="24" height="24" fill="currentColor" className="bi bi-newspaper" viewBox="0 0 16 16">
                            <use href="#icon-news"/>
                        </svg>
                        <span style={navTextStyle}>Новости</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setSelectedItem('user')}
                          className={selectedItem === 'user' ? "bg-primary" : null}>
                    <Nav.Link href="/page/user" className={navLinkClasses}
                              style={selectedItem === 'user' ? selectedNavLinkStyle : null}>
                        <svg width="24" height="24" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <use href="#icon-user"/>
                        </svg>
                        <span style={navTextStyle}>Профиль</span>
                    </Nav.Link>
                </Nav.Item>
                {(currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER') &&
                    <Nav.Item color={"21x9"} onClick={() => setSelectedItem('admin')}
                              className={selectedItem === 'admin' ? "bg-primary" : null}>
                        <Nav.Link href="/admin-menu" className={navLinkClasses}
                                  style={selectedItem === 'admin' ? selectedNavLinkStyle : null}>
                            <svg width="24" height="24" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                                <use href="#icon-admin"/>
                            </svg>
                            <span style={navTextStyle}>Админка</span>
                        </Nav.Link>
                    </Nav.Item>
                }
            </Nav>
        </footer>
    )
}
export default MobileNavigationComponent;