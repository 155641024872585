import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import TrainingService from "../../services/TrainingService";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {formatDate3} from "../../util/dateFormatter";

const TrainingMarkPaymentComponent = ({training, open, onClose, showMessage}) => {
    const [attendedUsers, setAttendedUsers] = useState([]);

    const [trainingParticipants, setTrainingParticipants] = useState([]);
    const getTrainingParticipants = () => {
        TrainingService.getTrainingParticipants(training.id).then((res) => {
            if (res.data.base && res.data.base.length) {
                setTrainingParticipants(res.data.base);
                let attendedUsersForAdd = [];
                for (let i = 0; i < res.data.base.length; i++) {
                    attendedUsersForAdd.push(res.data.base[i].id);
                }
                setAttendedUsers(attendedUsersForAdd);
            }
        });
    }

    const editAttendedUsers = (userId, checked) => {
        let attendedUsersForEdit = attendedUsers;
        if (checked) {
            attendedUsersForEdit.push(userId);
        } else {
            attendedUsersForEdit.splice(attendedUsersForEdit.indexOf(userId), 1);
        }
        setAttendedUsers(attendedUsersForEdit);
    }

    const markPayers = () => {
        TrainingService.markPayers(training.id, attendedUsers.toString()).then(res => {
            onClose();
            showMessage(true, res.data.message ? res.data.message : "Оплата отмечена");
        }).catch(e => {
            let messageText = "Произошла непредвиденная ошибка. Обратитесь к администратору";
            if (e.response && e.response.data && e.response.data.message) {
                messageText = e.response.data.message;
            } else if (e.message) {
                messageText = e.message;
            }
            showMessage(true, messageText, "danger");
        });
    }

    useEffect(() => {
        getTrainingParticipants();
    }, []);

    return (<Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>Отметка оплаты</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {training.sportTypeStr}
                <br/>
                {training.trainingTypeStr ? training.trainingTypeStr : ''}
                <br/>
                {'Адрес: ' + training.gymStr}
                <br/>
                {"Дата: " + formatDate3(training.date)}
                <br/>
                {"Время: с " + training.startTime + " до " + training.endTime}
            </DialogContentText>
            <Table hover>
                <thead>
                <tr>
                    <th></th>
                    <th>Фамилия Имя</th>
                    <th>Оплачено</th>
                </tr>
                </thead>
                <tbody>
                {trainingParticipants.map((user, index) => <tr>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>
                        <Form.Check
                            type={"checkbox"}
                            defaultChecked={true}
                            onChange={(event) => editAttendedUsers(user.id, event.target.checked)}
                        />
                    </td>
                </tr>)}
                </tbody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} variant={"secondary"}>Отмена</Button>
            <Button autoFocus onClick={markPayers}>Сохранить</Button>
        </DialogActions>
    </Dialog>)
}

export default TrainingMarkPaymentComponent;