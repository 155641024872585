import axios from "../axios";

class UserService {
    getUserData() {
        return axios.get('user/');
    }

    save(user) {
        return axios.post('user/save', user);
    }

    registration(user) {
        return axios.post('user/registration', user);
    }

    delete(userId) {
        return axios.post('user/archive', {id: userId});
    }

    getCurrentUserGameLevels() {
        return axios.get('user/game/levels');
    }

    getUserGameLevels(userId) {
        return axios.get('user/game/levels/by/user?userId=' + userId);
    }

    ban(userId) {
        return axios.post('user/ban?userId=' + userId);
    }

    activate(userId) {
        return axios.post('user/activate?userId=' + userId);
    }

    getTrainingAdmins() {
        return axios.get('user/users/admins');
    }

    resetPassword(email) {
        return axios.post('user/password/reset?email=' + email);
    }

    saveNewPassword(code, newPassword) {
        return axios.post('user/password/save?token=' + code + '&newPassword=' + newPassword);
    }

    updatePassword(oldPassword, newPassword) {
        return axios.post('user/password/update?oldPassword=' + oldPassword + '&newPassword=' + newPassword);
    }

    getTelegramBotUrlForLink() {
        return axios.get('bot/urlForLink');
    }

    unLinkTelegram() {
        return axios.post('bot/unlink');
    }
}

export default new UserService();
