import {format} from "date-fns";
import {ru} from "date-fns/locale";

/** Форматирование даты по шаблону "dd.MM.yyyy"
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate1(date) {
    if (date) {
        return format(new Date(date), "dd.MM.yyyy", {locale: ru});
    } else {
        return '';
    }
}

/** Форматирование даты по шаблону "dd.MM.yyyy - EEEE" дата и день недели
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate2(date) {
    if (date) {
        return format(new Date(date), "dd.MM.yyyy - EEEE", {locale: ru});
    } else {
        return '';
    }
}

/** Форматирование даты по шаблону "dd MMMM" численный день и месяц строкой
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate3(date) {
    if (date) {
        return format(new Date(date), "dd MMMM", {locale: ru});
    } else {
        return '';
    }
}

/** Форматирование даты по шаблону "yyyy" только год
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate4(date) {
    if (date) {
        return format(new Date(date), "yyyy", {locale: ru});
    } else {
        return '';
    }
}

/** Форматирование даты по шаблону "dd MMMM - EEEE" численный день, месяц строкой и день недели
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate5(date) {
    if (date) {
        return format(new Date(date), "dd MMMM - EEEE", {locale: ru});
    } else {
        return '';
    }
}

/** Форматирование даты по шаблону "yyyy-MM-dd" для вставки в контрол даты
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate6(date) {
    if (date) {
        return format(new Date(date), "yyyy-MM-dd", {locale: ru});
    } else {
        return '';
    }
}


/** Форматирование даты по шаблону "dd.MM.yyyy HH:mm:ss" Со врменем
 * @param date дата для форматирования
 * @return отформатированная дата
 * */
function formatDate7(date) {
    if (date) {
        return format(new Date(date), "dd.MM.yyyy HH:mm:ss", {locale: ru});
    } else {
        return '';
    }
}

export {formatDate1, formatDate2, formatDate3, formatDate4, formatDate5, formatDate6, formatDate7}