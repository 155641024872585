import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {Col, FloatingLabel, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import TrainingService from "../../../services/TrainingService";
import AdministrationService from "../../../services/AdministrationService";
import GameLevelService from '../../../services/GameLevelService';
import {formatDate1} from "../../../util/dateFormatter";

const EditTrainingDialog = ({open, handleClose, training, customizeMessage}) => {

    const [trainingGym, setTrainingGym] = useState(training.gym.id);
    const [startTime, setStartTime] = useState(training.startTime);
    const [endTime, setEndTime] = useState(training.endTime);
    const [price, setPrice] = useState(training.price);
    const [maxParticipants, setMaxParticipants] = useState(training.maxParticipants);
    const [minParticipants, setMinParticipants] = useState(training.minParticipants);
    const [gameLevel, setGameLevel] = useState(training.minGameLevel.id);


    const changeGym = (event) => {
        setTrainingGym(event.target.value);
    }
    const changeStartTime = (event) => {
        setStartTime(event.target.value);
    }
    const changeEndTime = (event) => {
        setEndTime(event.target.value);
    }
    const changePrice = (event) => {
        setPrice(event.target.value);
    }
    const changeMaxParticipants = (event) => {
        setMaxParticipants(event.target.value);
    }
    const changeMinParticipants = (event) => {
        setMinParticipants(event.target.value);
    }
    const changeGameLevel = (event) => {
        setGameLevel(event.target.value);
    }

    const saveTraining = () => {
        const currentTraining = {
            trainingId: training.id,
            date: formatDate1(training.date),
            sportTypeId: training.sportType.id,
            gymId: trainingGym,
            startTime: startTime,
            endTime: endTime,
            price: price,
            maxParticipants: maxParticipants,
            minParticipants: minParticipants,
            gameLevelId: gameLevel
        }

        TrainingService.save(currentTraining).then((res) => {
            const message = res.data && res.data instanceof String ? res.data : "Тренировка успешно сохранена";
            customizeMessage(true, "success", message);
        }).catch(e => {
            const message = e.response && e.response.data && e.response.data instanceof String ?
                e.response.data : "Возникла непредвиденная ошибка";
            customizeMessage(true, "danger", message);
        });
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (trainingGym && startTime && endTime && price && maxParticipants && gameLevel) {
            event.preventDefault();
            saveTraining();
        }
        setValidated(true);
    };

    const [gyms, setGyms] = useState([]);
    const [gameLevels, setGameLevels] = useState([]);

    const getTrainingEditOptions = () => {
        AdministrationService.getGyms().then((res) => {
            if (res.data && res.data.length) {
                setGyms(res.data);
            }
        });
        GameLevelService.getGameLevels().then((res) => {
            if (res.data && res.data.length) {
                setGameLevels(res.data);
            }
        });
    }

    useEffect(() => {
        if (open) {
            getTrainingEditOptions();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Редактировать тренировку</DialogTitle>
            <DialogContent>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            Вид спорта
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control plaintext readOnly value={training.sportType.name}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            Дата
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control plaintext readOnly value={formatDate1(training.date)}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={1}>
                            Зал
                        </Form.Label>
                        <Col sm={11}>
                            <Form.Select id={"gym"} value={trainingGym} onChange={changeGym}>
                                {gyms ? gyms.map((gym, index) =>
                                    <option key={index} value={gym.id}>{gym.name} ({gym.address})</option>
                                ) : null}
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <FloatingLabel label="Время начала">
                                <Form.Control required type="time" onChange={changeStartTime}
                                              value={startTime}/>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <FloatingLabel label="Время окончания">
                                <Form.Control required type="time" onChange={changeEndTime} value={endTime}/>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <FloatingLabel label="Взнос" className="mb-3">
                        <Form.Control required type="text" onChange={changePrice} value={price}/>
                    </FloatingLabel>

                    <FloatingLabel label="Максимальное количество участников" className="mb-3">
                        <Form.Control required type="number" onChange={changeMaxParticipants}
                                      value={maxParticipants}/>
                    </FloatingLabel>

                    <FloatingLabel label="Минимальное количество участников" className="mb-3">
                        <Form.Control required type="number" onChange={changeMinParticipants}
                                      value={minParticipants}/>
                    </FloatingLabel>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                            Уровень игры
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Select id={"gameLevel"} value={gameLevel} onChange={changeGameLevel}>
                                {gameLevels ? gameLevels.map((gameLevel, index) =>
                                    <option key={index} value={gameLevel.id}>{gameLevel.name}</option>
                                ) : null}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
                <Button onClick={handleSubmit}>Сохранить</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditTrainingDialog;