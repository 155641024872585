import {useEffect, useState} from "react";
import TrainingCard from "./TrainingCard";
import TrainingService from "../../services/TrainingService";
import {Alert} from "react-bootstrap";
import {useLocalState} from "../../util/useLocatStorage";
import {globalProps} from "../../config";

const ResponsibleUserTrainings = () => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [responsibleUserTrainings, setResponsibleUserTrainings] = useState([]);
    const getCurrentResponsibleUserTrainings = () => {
        TrainingService.getResponsibleUserTrainings().then((res) => {
            setResponsibleUserTrainings(res.data);
        });
    }

    const [trainingsToMarkPayment, setTrainingsToMarkPayment] = useState([]);
    const getTrainingsToMarkPayment = () => {
        TrainingService.getTrainingsToMarkPayment().then((res) => {
            setTrainingsToMarkPayment(res.data);
        });
    }

    useEffect(() => {
        getCurrentResponsibleUserTrainings();
        if (currentUserRole === 'ADMIN') {
            getTrainingsToMarkPayment();
        }
    }, []);

    return (<>
            {responsibleUserTrainings.length ? responsibleUserTrainings.map(training => <TrainingCard
                    training={training} key={training.id} action={globalProps.actionsWithTraining.FINISH}/>) :
                <Alert>Здесь будут тренировки, в которых вы назначены администратором. На данный момент у вас нет
                    тренировок, по которым необходимо совершить действия</Alert>}
        {currentUserRole === 'ADMIN' &&
            <div>
                <hr className={"mb-0"}/>
                <small>{globalProps.actionsWithTraining.MARK_PAYMENT}</small>
                <div className={"mt-3"}>
                    {trainingsToMarkPayment.length ? trainingsToMarkPayment.map(training => <TrainingCard
                            training={training} key={training.id} action={globalProps.actionsWithTraining.MARK_PAYMENT}/>) :
                        <Alert>Здесь будут тренировки, готовые к отметке оплаты (тернировка состоялась и админ отметил присутствующих)</Alert>}
                </div>

            </div>
        }
        </>);
}

export default ResponsibleUserTrainings;