import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableHead from "@mui/material/TableHead";

const headCells = [
    {
        id: 'date',
        label: 'Дата',
    },
    {
        id: 'start-time',
        label: 'Время начала',
    },
    {
        id: 'end-time',
        label: 'Время окончания',
    },
    {
        id: 'price',
        label: 'Стоимость',
    },
    {
        id: 'sport-type',
        label: 'Вид спорта',
    },
    {
        id: 'game-level',
        label: 'Уровень',
    },
    {
        id: 'gym',
        label: 'Зал',
    },
    {
        id: 'status',
        label: 'Статус',
    },
    {
        id: 'actions',
        label: '',
    }
];

const TrainingsTableHead = ({onSelectAllClick, numSelected, rowCount}) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default TrainingsTableHead;