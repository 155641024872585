import axios from "axios";
import {jwtDecode} from "jwt-decode";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST + "/api/",
    headers: {
        'Content-Type': 'application/json',
    }
});

/**
 * Роуты, которые не требуют авторизацию.
 */
function noAuthRoute(uri) {
    return /^(news\/|user\/registration|user\/password\/reset|user\/password\/save|auth\/refreshToken)$/.test(uri);
}

axiosInstance.interceptors.request.use(async function (config) {
    return new Promise(async resolve => {
        if (noAuthRoute(config.url)) {
            resolve(config);
            return;
        }

        try {
            let jwt = localStorage.getItem("jwt");
            if (jwt) {
                jwt = JSON.parse(jwt);
            }
            const decoded = jwtDecode(jwt);

            let expTime = decoded.exp * 1000;
            let curTime = new Date().getTime();

            if (expTime - curTime <= -1) {
                let refreshToken = localStorage.getItem("refreshToken");
                if (refreshToken) {
                    refreshToken = JSON.parse(refreshToken);
                }
                await axiosInstance.post('auth/refreshToken', {refreshToken: refreshToken}).then(res => {
                    localStorage.setItem("jwt", JSON.stringify(res.data.token));
                    jwt = res.data.token;
                    config.headers.Authorization = jwt;
                    resolve(config);
                }).catch(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/login";
                });
            } else {
                config.headers.Authorization = jwt;
                resolve(config);
            }
        } catch (err) {
            console.log("axiosInstance.interceptors.request.use", err);
            resolve(config);
        }
    });
}, function (error) {
    return Promise.reject(error);
});

/**
 * Получение ответа:
 * если запрос отправлен, но сервер все равно вернул 401 - показываем модальное окно повторного входа
 */
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    try {
        if (noAuthRoute(error.response.config.url)) {
            return Promise.reject(error);
        }

        if (error.response.status === 401) {
            window.location.href = '/login'
        }
    } catch (e) {

    }
    return Promise.reject(error);
});

export default axiosInstance;