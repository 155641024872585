import {useState} from "react";
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TrainingService from "../../../services/TrainingService";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {useMessage} from "../../message/MessageProvider";
import {IconButton} from "@mui/material";

/**
 * Компонент для отображения модального окна отметки оплаты должника.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.debtor - Объект должника, для которого отмечается оплата.
 * @param {Function} props.setDataChanged - Функция для обновления состояния данных.
 * @returns {JSX.Element} Модальное окно для отметки оплаты должника.
 */
export default function MarkTheDebtorModal({debtor, setDataChanged}) {
    const {processError, processSuccess} = useMessage();
    const [show, setShow] = useState(false);

    /**
     * Закрытие модального окна.
     */
    const handleClose = () => {
        setShow(false);
    };

    /**
     * Открытие модального окна.
     */
    const handleShow = () => setShow(true);

    /**
     * Отметка оплаты для должника.
     */
    const markTheDebtor = () => {
        handleClose();
        TrainingService.markTheDebtor(debtor.participantId).then(_r => {
            setDataChanged(prev => !prev);
            processSuccess("Действие выполнено успешно", false);
        }).catch(_e => {
            processError("Произошла ошибка во время выполнения действия. Повторите попытку позже", false);
        });
    };

    return (
        <>
            <IconButton onClick={handleShow} title="Отметить оплату">
                <CreditScoreIcon/>
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Отметка оплаты</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    У пользователя {debtor.fio} будет отмечена оплата за тренировку {debtor.trainingStr}. Вы уверены?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Отмена</Button>
                    <Button variant="primary" onClick={markTheDebtor}>Ок</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
