import React, {useEffect, useState} from 'react';
import TrainingComponent from "./TrainingComponent";
import TrainingService from "../../services/TrainingService";
import {PulseLoader} from "react-spinners";
import Accordion from 'react-bootstrap/Accordion';
import {Alert, Button, ToggleButton, useAccordionButton} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import GameLevelService from '../../services/GameLevelService';
import {formatDate5} from "../../util/dateFormatter";


const AccordionComponent = ({initialTodayTrainings, sportType}) => {
    const [matches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    const todayDate = new Date();
    const tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(todayDate.getDate() + 1);

    let [isTomorrowTrainingsOpen, setIsTomorrowTrainingsOpen] = useState(false);
    let [isOtherTrainingsOpen, setIsOtherTrainingsOpen] = useState(false);
    let [isTomorrowTrainingsLoading, setTomorrowTrainingsLoading] = useState(false);
    let [isOtherTrainingsLoading, setOtherTrainingsLoading] = useState(false);

    const [todayTrainings, setTodayTrainings] = useState(initialTodayTrainings);
    const [tomorrowTrainings, setTomorrowTrainings] = useState([]);

    function getTodayTrainings(gameLevelsId) {
        TrainingService.getTodayTrainings(sportType.id, gameLevelsId).then((res) => {
            if (res.data[0] && res.data[0].trainings && res.data[0].trainings) {
                setTodayTrainings(res.data[0].trainings);
            }
        });
    }

    function getTomorrowTrainings(gameLevelsId) {
        setTomorrowTrainingsLoading(true);
        if (!isTomorrowTrainingsOpen || gameLevelsId) {
            TrainingService.getTomorrowTrainings(sportType.id, gameLevelsId ? gameLevelsId :
                (gameLevelsForFilter ? gameLevelsForFilter : null)).then((res) => {
                setTomorrowTrainingsLoading(false);
                if (res.data[0] && res.data[0].trainings && res.data[0].trainings) {
                    setTomorrowTrainings(res.data[0].trainings);
                }
            });
            setIsTomorrowTrainingsOpen(true);
        } else {
            setIsTomorrowTrainingsOpen(false);
            setTomorrowTrainingsLoading(false);
        }
    }

    const [otherTrainingsByDate, setOtherTrainingsByDate] = useState([]);

    function getOtherTrainings(gameLevelsId) {
        setOtherTrainingsLoading(true);
        if (!isOtherTrainingsOpen || gameLevelsId) {
            TrainingService.getOtherTrainings(sportType.id, gameLevelsId ? gameLevelsId :
                (gameLevelsForFilter ? gameLevelsForFilter : null)).then((res) => {
                setOtherTrainingsLoading(false);
                if (res.data[0] && res.data[0].trainings && res.data[0].trainings) {
                    setOtherTrainingsByDate(collectTrainingsByDate(res.data[0].trainings));
                }
            });
            setIsOtherTrainingsOpen(true);
        } else {
            setIsOtherTrainingsOpen(false);
            setOtherTrainingsLoading(false);
        }
    }

    function collectTrainingsByDate(trainings) {
        let result = [];
        const dateForIteration = new Date(tomorrowDate)
        dateForIteration.setDate(tomorrowDate.getDate() + 1);
        for (let i = 0; i < 6; i++) {
            let trainingsByDate = {};
            trainingsByDate.date = dateForIteration;
            trainingsByDate.trainings = [];
            for (let training of trainings) {
                if (new Date(training.date).getDate() === dateForIteration.getDate()) {
                    trainingsByDate.trainings.push(training);
                }
            }
            if (trainingsByDate.trainings.length) {
                result.push(trainingsByDate);
            }
            dateForIteration.setDate(dateForIteration.getDate() + 1);
        }
        return result;
    }

    /*Фильтрация по уровню игры*/
    const [gameLevelsFilter, setGameLevelsFilter] = useState([]);
    const [gameLevelsForFilter, setGameLevelsForFilter] = useState([]);

    const gameLevelFilterCheckHandle = (e) => {
        let checkedGameLevelsId = [];
        let gameLevelsFilterChange = gameLevelsFilter;
        for (let i = 0; i < gameLevelsFilterChange.length; i++) {
            if (gameLevelsFilterChange[i].id === e.target.id) {
                gameLevelsFilterChange[i].checked = e.target.checked;
            }
            if (gameLevelsFilterChange[i].checked) {
                checkedGameLevelsId.push(
                    gameLevelsFilterChange[i].id.slice(gameLevelsFilterChange[i].id.indexOf('_gameLevel-') + 11)
                );
            }
        }
        setGameLevelsFilter(gameLevelsFilterChange);
        getTodayTrainings(checkedGameLevelsId);
        if (isTomorrowTrainingsOpen) {
            getTomorrowTrainings(checkedGameLevelsId);
        }
        if (isOtherTrainingsOpen) {
            getOtherTrainings(checkedGameLevelsId);
        }
        setGameLevelsForFilter(checkedGameLevelsId);
    }

    const getGameLevels = () => {
        GameLevelService.getGameLevels().then(res => {
            if (res.data && res.data.length) {
                let gameLevelsFilter = [];
                for (let i = 0; i < res.data.length; i++) {
                    const gameLevel = res.data[i];
                    gameLevelsFilter.push({
                        id: 'sportType-' + sportType.id + '_gameLevel-' + gameLevel.id,
                        checked: false,
                        title: gameLevel.name
                    });
                }
                setGameLevelsFilter(gameLevelsFilter);
            }
        });
    }

    useEffect(() => {
        getGameLevels();
    }, []);

    function CustomToggle({children, eventKey}) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button onClick={decoratedOnClick} variant="secondary" className={"mb-2"}>
                {children}
            </Button>
        );
    }

    return (
        <div>
            <Accordion className={"m-2"}>
                <CustomToggle eventKey={"sportType-" + sportType.id + "-filters"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-funnel-fill" viewBox="0 0 16 16">
                        <path
                            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                    </svg>
                </CustomToggle>
                <Accordion.Collapse eventKey={"sportType-" + sportType.id + "-filters"}>
                    <ButtonGroup vertical={!matches} className={!matches ? "w-100" : null}>
                        {gameLevelsFilter.map(gameLevel =>
                            <ToggleButton
                                id={gameLevel.id}
                                type="checkbox"
                                variant="outline-secondary"
                                checked={gameLevel.checked}
                                onChange={gameLevelFilterCheckHandle}
                                key={sportType.id + gameLevel.id}
                                className={"w-100"}
                                value={gameLevel.checked}
                            >
                                {gameLevel.title}
                            </ToggleButton>
                        )
                        }
                    </ButtonGroup>
                </Accordion.Collapse>
            </Accordion>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{'Тренировки сегодня - ' + formatDate5(todayDate)}</Accordion.Header>
                    <Accordion.Body>
                        {todayTrainings && todayTrainings.length
                            ?
                            <div className={matches ? "schedule-table" : "schedule-table-mobile"}>
                                {todayTrainings.map(todayTraining => <TrainingComponent training={todayTraining}
                                                                                        key={todayTraining.id}/>)}
                            </div>
                            :
                            <Alert variant="info">
                                Тренировок еще нет. Попробуйте поискать тренировки на другую дату
                            </Alert>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header onClick={() => getTomorrowTrainings()}>
                        {'Тренировки завтра - ' + formatDate5(tomorrowDate)}
                    </Accordion.Header>
                    <Accordion.Body>
                        {isTomorrowTrainingsLoading
                            ?
                            <div className={"position-absolute start-50"}><PulseLoader color="#0d6efd"/></div>
                            :
                            tomorrowTrainings.length
                                ?
                                <div className={matches ? "schedule-table" : "schedule-table-mobile"}>
                                    {
                                        tomorrowTrainings.map(
                                            (tomorrowTraining) => <TrainingComponent key={tomorrowTraining.id}
                                                                                     training={tomorrowTraining}/>
                                        )
                                    }
                                </div>
                                :
                                <Alert variant="info">
                                    Тренировок еще нет. Попробуйте поискать тренировки на другую дату
                                </Alert>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header
                        onClick={() => getOtherTrainings()}>Еще ближайшие тренировки</Accordion.Header>
                    <Accordion.Body>
                        {isOtherTrainingsLoading
                            ?
                            <div className={"position-absolute start-50"}><PulseLoader color="#0d6efd"/></div>
                            :
                            otherTrainingsByDate.length
                                ?
                                otherTrainingsByDate.map((otherTrainingByDate) =>
                                    <div key={otherTrainingByDate.id}>
                                        <div className="border-bottom mb-3 mx-5">
                                            {formatDate5(otherTrainingByDate.trainings[0].date)}
                                        </div>
                                        <div className={matches ? "schedule-table mb-3" : "schedule-table-mobile mb-3"}>
                                            {
                                                otherTrainingByDate.trainings.map((training) =>
                                                    <TrainingComponent key={training.id} training={training}/>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                                :
                                <Alert variant="info">
                                    Ближайшие тренировки еще не добавлены. Попробуйте посмотреть позже
                                </Alert>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default AccordionComponent;
