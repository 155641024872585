import React from 'react';
import TrainingService from "../../../services/TrainingService";
import DeleteModal from "../../DeleteModal";
import RepeatTrainingsModal from "./RepeatTrainingsModal";
import CreateOrEditTrainingModal from "./CreateOrEditTrainingModal";
import {globalProps} from "../../../config";
import {Card, CloseButton} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import PaginationComponent from "../../PaginationComponent";
import DownloadReport from "./DownloadReport";
import Table from '@mui/material/Table';
import {IconButton, TableBody, TableContainer} from "@mui/material";
import TrainingsTableHead from "./TrainingsTableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import MultipleTrainingsDeleteModal from "./MultipleTrainingsDeleteModal";
import {formatDate1} from "../../../util/dateFormatter";
import openTrainingCard from "../../trainingPage/util";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

/**
 * Обертка карточки фильтрации.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Заголовок карточки.
 * @param {React.ReactNode} props.children - Контрол фильтра.
 * @returns {JSX.Element} Элемент карточки фильтрации.
 */
function FilterCard({title, children}) {
    return (
        <Card>
            <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">{title}</Card.Subtitle>
                {children}
            </Card.Body>
        </Card>
    );
}

/**
 * Компонент таблицы с тренировками в админке.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.dictionariesData - Дополнительные данные для тренировки.
 * @param {Function} props.setDataChanged - Функция для обновления данных на странице.
 * @param {Object} props.filters - Фильтры.
 * @param {Function} props.filterByOption - Функция фильтрации.
 * @param {Function} props.clearFilters - Очистка фильтров.
 * @param {Array} props.trainings - Тренировки.
 * @param {Object} props.trainingsPage - Тренировки и конфигурация страницы.
 * @param {Object} props.trainingPageCfg - Конфигурация страницы с тренировками.
 * @param {Function} props.setTrainingPageCfg - Обновление конфигурации страницы.
 * @param {Array} props.selectedItems - Выбранные элементы (тренировки)
 * @param {Function} props.handleSelectAllClick - Выбор всех элементов
 * @param {Function} props.isSelected - Проверка выбрана ли тренировка
 * @param {Function} props.handleClick - Обработка нвыбора трнеировки
 * @param {Function} props.getSelectedTrainings - Получить выбранные элементы тренировки
 * @returns {JSX.Element} Компонент таблицы тренировок.
 */
const TrainingsComponent = ({
                                dictionariesData,
                                setDataChanged,
                                filters,
                                filterByOption,
                                clearFilters,
                                trainings,
                                trainingsPage,
                                trainingPageCfg,
                                setTrainingPageCfg,
                                selectedItems,
                                handleSelectAllClick,
                                isSelected,
                                handleClick,
                                getSelectedTrainings
                            }) => {
    return (
        <>
            <div className="mb-3">
                <CreateOrEditTrainingModal mode={globalProps.mode.create} dictionariesData={dictionariesData}/>
                <RepeatTrainingsModal selectedItems={selectedItems} getSelectedTrainings={getSelectedTrainings}/>
                <MultipleTrainingsDeleteModal selectedItems={selectedItems}
                                              getSelectedTrainings={getSelectedTrainings}
                                              setDataChanged={setDataChanged}
                />
                <DownloadReport/>
            </div>
            <div className="d-flex flex-row m-3 gap-1">
                <FilterCard title="Зал">
                    <Form.Select value={filters.gymId}
                                 onChange={(e) =>
                                     filterByOption(e.target.value, 'gymId')
                                 }
                    >
                        <option value=""></option>
                        {dictionariesData.gyms.map(gym =>
                            <option key={`administration-filter-gym-${gym.id}`} value={gym.id}>
                                {gym.name} ({gym.address})
                            </option>
                        )}
                    </Form.Select>
                </FilterCard>
                <FilterCard title="Вид спорта">
                    <Form.Select value={filters.sportTypeId}
                                 onChange={(e) =>
                                     filterByOption(e.target.value, 'sportTypeId')
                                 }
                    >
                        <option value=""></option>
                        {dictionariesData.sportTypes.map(sportType =>
                            <option key={`administration-filter-sport-type-${sportType.id}`} value={sportType.id}>
                                {sportType.name}
                            </option>
                        )}
                    </Form.Select>
                </FilterCard>
                <FilterCard title="Уровень игры">
                    <Form.Select value={filters.gameLevelId}
                                 onChange={(e) =>
                                     filterByOption(e.target.value, 'gameLevelId')
                                 }
                    >
                        <option value=""></option>
                        {dictionariesData.gameLevels.map(gameLevel =>
                            <option key={`administration-filter-game-level-${gameLevel.id}`} value={gameLevel.id}>
                                {gameLevel.name}
                            </option>
                        )}
                    </Form.Select>
                </FilterCard>
                <FilterCard title="Дата тренировки">
                    <Form.Control type="date"
                                  value={filters.date}
                                  onChange={(e) =>
                                      filterByOption(e.target.value, 'date')
                                  }
                    />
                </FilterCard>
                <FilterCard title="Статус">
                    <Form.Select value={filters.status}
                                 onChange={(e) =>
                                     filterByOption(e.target.value, 'status')
                                 }
                    >
                        <option value=""></option>
                        <option value="NEW">{globalProps.trainingStatus.NEW}</option>
                        <option value="COMPLETED">{globalProps.trainingStatus.COMPLETED}</option>
                        <option value="CANCELLED">{globalProps.trainingStatus.CANCELLED}</option>
                    </Form.Select>
                </FilterCard>
                <CloseButton title="Очистить фильтры" onClick={clearFilters}/>
            </div>

            <TableContainer>
                <Table aria-labelledby="tableTitle">
                    <TrainingsTableHead
                        numSelected={selectedItems.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={trainings.length}
                    />
                    <TableBody>
                        {trainings.map((training, index) => {
                            const isItemSelected = isSelected(training.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, training.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={training.id}
                                    selected={isItemSelected}
                                    sx={{cursor: 'pointer'}}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">{formatDate1(training.date)}</TableCell>
                                    <TableCell align="left">{training.startTime}</TableCell>
                                    <TableCell align="left">{training.endTime}</TableCell>
                                    <TableCell align="left">{training.price + '₽'}</TableCell>
                                    <TableCell align="left">{training.sportType.name}</TableCell>
                                    <TableCell align="left">{training.minGameLevel.name}</TableCell>
                                    <TableCell align="left">
                                        {training.gym.name + ' (' + training.gym.address + ')'}
                                    </TableCell>
                                    <TableCell align="left">{globalProps.trainingStatus[training.status]}</TableCell>
                                    <TableCell align="left">
                                        <div className="d-flex gap-1">
                                            <CreateOrEditTrainingModal training={training}
                                                                       mode={globalProps.mode.edit}
                                                                       setDataChanged={setDataChanged}
                                                                       dictionariesData={dictionariesData}
                                            />
                                            <DeleteModal id={training.id} fnDelete={TrainingService.delete}/>
                                            <IconButton onClick={() => openTrainingCard(training.id)}>
                                                <OpenInNewIcon/>
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent data={trainingsPage}
                                 pageCfg={trainingPageCfg}
                                 setPageCfg={setTrainingPageCfg}
                                 changeItemsCount={true}
            />
        </>
    );
};

export default TrainingsComponent;
