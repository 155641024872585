import {globalProps} from "../../../../config";
import TableEntityCard from "../../TableEntityCard";
import {Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CreateOrEditTrainingTypesModal from "../CreateOrEditTrainingTypesModal";
import AdministrationService from "../../../../services/AdministrationService";
import DeleteModal from "../../../DeleteModal";
import TrainingService from "../../../../services/TrainingService";

const TrainingTypesMobile = () => {
    const [trainingTypes, setTrainingTypes] = useState([]);
    const getTrainingTypes = () => {
        AdministrationService.getTrainingTypes().then((res) => {
            setTrainingTypes(res.data);
        });
    }

    useEffect(() => {
        getTrainingTypes();
    }, []);

    const generateCardPropsArray = (trainingType) => {
        return [
            trainingType.name,
            trainingType.active ? "Активный" : "Не активный"
        ]
    }

    const getUserActions = (trainingType) => {
        return [
            <CreateOrEditTrainingTypesModal trainingType={trainingType}
                                            mode={globalProps.mode.edit}/>,
            <DeleteModal id={trainingType.id} fnDelete={TrainingService.delete}/>
        ]
    }

    return (
        <>
            <div className="mb-3">
                <CreateOrEditTrainingTypesModal mode={globalProps.mode.create}/>
            </div>
            {trainingTypes.map(trainingType =>
                <TableEntityCard props={generateCardPropsArray(trainingType)}
                                 actions={getUserActions(trainingType)}
                                 borderColor={trainingType.active ? 'transparent' : 'danger'}
                                 key={trainingType.id}
                />
            )}

            {!trainingTypes.length && <Alert>Виды тренировок не найдены</Alert>}
        </>
    )
}

export default TrainingTypesMobile;