import background from "../images/aboutPage/bgsz.png";
import {
    CardContent,
    Chip,
    ImageList,
    ImageListItem,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, SvgIcon,
    Typography
} from "@mui/material";
import {useMemo} from "react";
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import SportsHandballRoundedIcon from '@mui/icons-material/SportsHandballRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import imgLst1 from "../images/aboutPage/imgLst1.jpg";
import imgLst2 from "../images/aboutPage/imgLst2.jpg";
import imgLst3 from "../images/aboutPage/imgLst3.jpg";
import imgLst4 from "../images/aboutPage/imgLst4.jpg";
import imgLst5 from "../images/aboutPage/imgLst5.jpg";
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import 'animate.css/animate.min.css';
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import TelegramIcon from '@mui/icons-material/Telegram';

/** Фотографии для альбома на странице ".о нас"*/
const imageList = [
    {
        img: imgLst1,
        rows: 2,
        cols: 2
    },
    {
        img: imgLst3,
        cols: 2
    },
    {
        img: imgLst5,
        cols: 2
    },
    {
        img: imgLst2,
        rows: 2,
        cols: 2
    },

    {
        img: imgLst4,
        rows: 2,
        cols: 2
    }
];

/** Формирование размеров картинок для отображении в альбоме фотографий страницы "О нас"*/
function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

/** Стили фоновой картинки страницы "О нас"*/
const fullWidthBackground = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '400px'
}

const contactsData = [
    {
        title: 'Telegram канал',
        link: 'https://t.me/blsrb',
        icon: <TelegramIcon color="primary"/>
    },
    {
        title: 'Группа Вконтакте',
        link: 'https://vk.com/bls13112017',
        icon: <SvgIcon viewBox="0 0 48 48" color="primary">
            <path
                d="M27.55,35.19V28.55c4.46.68,5.87,4.19,8.71,6.64H43.5a29.36,29.36,0,0,0-7.9-10.47c2.6-3.58,5.36-6.95,6.71-12.06H35.73c-2.58,3.91-3.94,8.49-8.18,11.51V12.66H18l2.28,2.82,0,10.05c-3.7-.43-6.2-7.2-8.91-12.87H4.5C7,20.32,12.26,37.13,27.55,35.19Z"/>
        </SvgIcon>
    },
    {
        title: 'Бикбаев Ильнур',
        link: 'https://t.me/big_bls',
        icon: <TelegramIcon color="primary"/>
    },
    {
        title: 'Юлмухаметов Артур',
        link: 'https://t.me/Art_bls',
        icon: <TelegramIcon color="primary"/>
    },
    {
        title: 'Сиражетдинов Фларид',
        link: 'https://t.me/fla_BLS',
        icon: <TelegramIcon color="primary"/>
    },
]

/** Страница с информацией "О нас"*/
const About = () => {
    const isMobile = useMemo(() => window.matchMedia("(max-width: 479px)").matches, []);

    const headerTitle = useMemo(() => 'Башкирская лига спорта', []);
    const aboutUsText = useMemo(() => 'Мы динамичная и прогрессивная организация, ' +
        'объединяющая людей всех возрастов и уровней подготовки вокруг общей страсти к спорту. ' +
        'На наших тренировках по волейболу, баскетболу и другим видам спорта каждый может найти свое место, ' +
        'независимо от того, является ли он начинающим или опытным спортсменом. Мы создаем атмосферу дружелюбия и ' +
        'соревновательности, где каждый может улучшить свои навыки и реализовать свой потенциал. Кроме того, ' +
        'мы проводим захватывающие турниры, где наши участники могут продемонстрировать свои умения и побороться ' +
        'за победу. В БЛС мы верим, что спорт - это не только физическая активность, но и способ объединить людей и ' +
        'создать сообщество единомышленников.', []);
    const listItem1Title = useMemo(() => 'Тренировки в залах по всему городу', []);
    const listItem2Title = useMemo(() => 'Разделение на уровни игры', []);
    const listItem3Title = useMemo(() => 'Тренировки с тренером', []);
    const listItem4Title = useMemo(() => 'Турниры с призами от партнеров', []);
    const listItem5Title = useMemo(() => 'Теплая и дружественная атмосфера', []);

    const contactsTitle = useMemo(() => 'Наши контакты', []);

    return (
        <>
            <div style={fullWidthBackground} className="d-flex align-items-center align-content-center">
                <Typography
                    variant="h2"
                    sx={{
                        color: 'white',
                        textAlign: 'center',
                        width: '100%',
                        fontWeight: 'bold'
                    }}
                >
                    {headerTitle}
                </Typography>
            </div>
            <div className="container pt-5">
                <div className="row mb-5 justify-content-center text-center">
                    <div className="col-lg-8 col-md-10">
                        <p>{aboutUsText}</p>
                    </div>
                </div>
            </div>

            <div className={`container d-flex ${isMobile ? 'flex-column' : ''}`}>
                <div className={isMobile ? '' : 'w-50'}>
                    <Chip label={headerTitle} color="primary"/>

                    <List className="pt-3">
                        <ListItem>
                            <ListItemIcon>
                                <PinDropRoundedIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary={listItem1Title}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BarChartRoundedIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary={listItem2Title}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SportsHandballRoundedIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary={listItem3Title}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <WorkspacePremiumRoundedIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary={listItem4Title}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeRoundedIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary={listItem5Title}/>
                        </ListItem>
                    </List>
                </div>
                <div className={isMobile ? '' : 'w-50'}>
                    <ImageList
                        variant="quilted"
                        cols={4}
                        rowHeight={121}
                    >
                        {imageList.map((item) => (
                            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                <img
                                    {...srcset(item.img, 121, item.rows, item.cols)}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>
            </div>

            <div className="container d-flex flex-column align-items-center pt-5">
                <ContactsRoundedIcon color="primary" fontSize="large"
                                     className="animate__animated animate__pulse animate__infinite"/>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        paddingTop: '20px',
                        paddingBottom: '20px'
                    }}
                >
                    {contactsTitle}
                </Typography>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    {contactsData.map(contact =>
                        <Card sx={{cursor: 'pointer', width: 230}} onClick={() => window.open(contact.link)}>
                            <CardContent sx={{textAlign: 'center'}}>
                                {contact.icon}
                                <p>{contact.title}</p>
                            </CardContent>
                        </Card>
                    )}
                </Stack>
            </div>
        </>
    )
}
export default About;