import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import TrainingService from "../../services/TrainingService";
import Message from "../Message";

const UnsubscribeFromTrainingDialog = ({training, open, handleClose}) => {
    //Отписаться от тренировки
    const unsubscribe = () => {
        if (open === 'unsubscribeAsAdmin') {
            TrainingService.unsubscribeAdmin(training.id).then(r => {
                handleClose();
                setIsMessageOpen(true);
                setMessageText("Вы успешно отписались от администрирования тренировки");
            });
        } else {
            TrainingService.unsubscribe(training.id).then(r => {
                handleClose();
                setIsMessageOpen(true);
                setMessageText("Вы успешно отписались от тренировки");
            });
        }
    }

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, setMessageType] = useState("success");
    const [showOKButtonInMessage, setShowOKButtonInMessage] = useState(false);

    return (
        <>
            <Dialog
                open={open === 'unsubscribe' || open === 'unsubscribeAsAdmin'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Подтверждение отписки</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {open === 'unsubscribeAsAdmin' ?
                            'Вы планируете отписаться от администрирования тренировки по адресу ' + training.gym.address
                            :
                            'Вы планируете отписаться от тренировки по адресу ' + training.gym.address
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
                    <Button onClick={unsubscribe} autoFocus>Отписаться</Button>
                </DialogActions>
            </Dialog>

            {/*Всплывающее сообщение*/}
            {isMessageOpen ?
                <Message text={messageText} type={messageType} showOKButton={showOKButtonInMessage}/> : null}
        </>

    );
}

export default UnsubscribeFromTrainingDialog;