import {Card} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React, {useId} from "react";

/**Компонент для отображения карточки объекта в админке под мобилку
 * @property props - отображаемые проперти (каждая с новой строки)
 * @property borderColor - цвет границы карточки (primary, secondary, success, danger, warning, info, dark)
 * @property actions - действия на карточке
 * @property checkboxClickHandle - действие при клике на чекбокс (чекбокс отображается при передаче этого параметра)
 * @property checkboxValue - значение чекбокса (обязательно для логики функционала с чекбоксами)
 * */
const TableEntityCard = ({props, borderColor, actions, checkboxClickHandle, checkboxSelected}) => {

    const id = useId();

    return (
        <Card border={borderColor ? borderColor : "transparent"} className="mb-2 border-3">
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <div>
                        {checkboxClickHandle &&
                            <Form.Check inline type="checkbox" checked={checkboxSelected} onChange={checkboxClickHandle}/>}
                    </div>
                    <div className="d-flex flex-row-reverse gap-3">
                        {actions && actions.length ? actions.map(
                            (item, i) => <div key={`${id}-action-${i}`}>{item}</div>
                        ) : null}
                    </div>
                </div>

                <div>
                    {props && props.length ? props.map(
                        (item, i) => <div key={`${id}-prop-${i}`}>{item}</div>
                    ) : null}
                </div>
            </Card.Body>
        </Card>
    )
}

export default TableEntityCard;