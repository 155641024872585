import React, {useEffect, useState} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CurrentUserTraining from "./CurrentUserTraining";
import TrainingService from "../../services/TrainingService";
import Nav from 'react-bootstrap/Nav';
import AccordionComponent from "./AccordionComponent";
import {PulseLoader} from "react-spinners";
import {useLocalState} from "../../util/useLocatStorage";
import UserService from "../../services/UserService";
import CurrentUserTrainingsHistory from "./CurrentUserTrainingsHistory";
import {Alert} from "react-bootstrap";

const ScheduleComponent = () => {
    const [activeSportTypeTab, setActiveSportTypeTab] = useLocalState("pill-0", "activeSportTypeTab");

    const [, setCurrentUserRole] = useLocalState({}, "currentUserRole");
    const [, setCurrentUserId] = useLocalState({}, "currentUserId");

    const [currentUserTrainings, setCurrentUserTrainings] = useState([]);
    const [sportTypesButtons, setSportTypesButtons] = useState([]);
    const [sportTypesContent, setSportTypesContent] = useState([]);

    const [todayTrainingsLoading, setTodayTrainingsLoading] = useState(true);

    const onNavItemClick = (i) => {
        setActiveSportTypeTab('pill-' + i);
    }

    const getTrainings = () => {
        TrainingService.getCurrentUserTrainings().then((res) => {
            setCurrentUserTrainings(res.data);
        });
        TrainingService.getTodayTrainings().then((res) => {
            let sportTypesButtons = [];
            let sportTypesContent = [];
            const trainingsBySportType = res.data;
            for (let i = 0; i < trainingsBySportType.length; i++) {
                const sportType = trainingsBySportType[i].sportType;
                const sportTypeTrainings = trainingsBySportType[i].trainings;
                sportTypesButtons.push(
                    <Nav.Item onClick={() => onNavItemClick(i)} key={sportType.id}>
                        <Nav.Link eventKey={'pill-' + i}>{sportType.name}</Nav.Link>
                    </Nav.Item>
                );
                sportTypesContent.push(
                    <Tab.Pane eventKey={'pill-' + i} key={"trainings-by-sport-type-" + sportType.id}>
                        <AccordionComponent initialTodayTrainings={sportTypeTrainings} sportType={sportType}/>
                    </Tab.Pane>
                )
            }
            setSportTypesButtons(sportTypesButtons);
            setSportTypesContent(sportTypesContent);
            setTodayTrainingsLoading(false);
        });
    }

    const getCurrentUserInfo = () => {
        UserService.getUserData().then((res) => {
            if (res.data) {
                setCurrentUserRole(res.data.role);
                setCurrentUserId(res.data.id);
            }
        });
    }

    useEffect(() => {
        getTrainings();
        getCurrentUserInfo();
    }, []);


    return (
        <Tabs defaultActiveKey="all-trainings" className="mb-3">
            <Tab eventKey="my-trainings" title="Мои занятия">
                {currentUserTrainings.length
                    ?
                    currentUserTrainings.map(
                        (training) => <CurrentUserTraining training={training} key={training.id}/>
                    )
                    :
                    <Alert variant="info">Вы пока не записаны на тренировки</Alert>
                }
                <CurrentUserTrainingsHistory/>
            </Tab>
            <Tab eventKey="all-trainings" title="Все занятия">
                <Tab.Container defaultActiveKey={activeSportTypeTab}>
                    {todayTrainingsLoading
                        ?
                        <div className={"position-absolute start-50"}><PulseLoader color="#0d6efd"/></div>
                        :
                        <>
                            <Nav variant="pills" className={"mb-2"}>
                                {sportTypesButtons.map((sportTypeButton) => sportTypeButton)}
                            </Nav>
                            <Tab.Content>
                                {sportTypesContent.map((sportTypesContent) => sportTypesContent)}
                            </Tab.Content>
                        </>
                    }
                </Tab.Container>
            </Tab>
        </Tabs>
    );
}

export default ScheduleComponent;