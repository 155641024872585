import Button from "react-bootstrap/Button";
import {Overlay} from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import React, {useEffect, useRef, useState, useCallback} from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

/**
 * Компонент фильтрации пользователей.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.initialFilters - Начальные значения фильтров.
 * @param {Function} props.filter - Функция для применения фильтров.
 */
const UsersFilter = ({initialFilters, filter}) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    /**
     * Закрывает поповер.
     */
    const closeHandle = useCallback(() => {
        setShow(false);
    }, []);

    useEffect(() => {
        setFilters({
            name: initialFilters.name,
            role: initialFilters.role,
            status: initialFilters.status
        });
    }, [initialFilters]);

    const [filters, setFilters] = useState({
        name: initialFilters.name,
        role: initialFilters.role,
        status: initialFilters.status
    });

    /**
     * Изменяет значение фильтра.
     *
     * @param {string} changeType - Тип изменяемого фильтра.
     * @param {string} value - Новое значение фильтра.
     */
    const changeItem = useCallback((value, changeType) => {
        const newFilters = {...filters, [changeType]: value};
        setFilters(newFilters);
    }, [filters]);

    /**
     * Применяет фильтры и закрывает поповер.
     */
    const filterCallback = useCallback(() => {
        filter(null, 'all', filters);
        closeHandle();
    }, [filters, filter, closeHandle]);

    return (
        <div>
            <Button variant="secondary" className="end-0" ref={target} onClick={() => setShow(!show)}>
                <FilterAltIcon fontSize="small"/>
            </Button>

            <Overlay show={show} placement="bottom" target={target.current} onHide={closeHandle} rootClose={true}>
                <Popover>
                    <Popover.Header as="h3">Фильтрация</Popover.Header>
                    <Popover.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>ФИО</Form.Label>
                            <Form.Control value={filters.name}
                                          onChange={(e) =>
                                              changeItem(e.target.value, 'name')
                                          }
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-sport-type">Роль</Form.Label>
                            <Form.Select value={filters.role}
                                         onChange={(e) =>
                                             changeItem(e.target.value, 'role')
                                         }
                            >
                                <option value=""></option>
                                <option value="NONE">Без роли (NONE)</option>
                                <option value="READER">Читатель (READER)</option>
                                <option value="USER">Пользователь (USER)</option>
                                <option value="RESPONSIBLE_USER">Админ тренировки (RESPONSIBLE_USER)</option>
                                <option value="ADMIN">Главный админ (ADMIN)</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-game-level">Статус</Form.Label>
                            <Form.Select value={filters.status}
                                         onChange={(e) =>
                                             changeItem(e.target.value, 'status')
                                         }
                            >
                                <option value=""></option>
                                <option value="NONE">Без статуса (NONE)</option>
                                <option value="ACTIVE">Активный (ACTIVE)</option>
                                <option value="BANNED">Заблокированный (BANNED)</option>
                                <option value="ARCHIVED">Архивированный (ARCHIVED)</option>
                            </Form.Select>
                        </Form.Group>

                        <Button className="w-100" onClick={filterCallback}>Применить</Button>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    )
}

export default UsersFilter;
