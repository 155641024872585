import React from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";


const SuccessfulRegistrationComponent = () => {

    return (
        <div className="px-4 pt-5 my-5 text-center border-bottom">
            <h1 className="display-4 fw-bold text-body-emphasis">Вы успешно зарегистрировались!</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">Теперь вы можете авторизоваться, но ваши возможности на сайте будут ограничены, пока администратор не подтвердит ваш аккаунт</p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                    <Link to="/login">
                        <Button>Войти</Button>
                    </Link>
                    <Link to="/">
                        <Button variant={"outline-secondary"}>На главную</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SuccessfulRegistrationComponent;
