import axios from "../axios";

class GameLevelService {
    save(gameLevel) {
        return axios.post("gameLevel/save", gameLevel);
    }

    delete(gameLevelId) {
        return axios.post("gameLevel/delete", {id: gameLevelId});
    }

    getGameLevels() {
        return axios.get('gameLevel/all');
    }

    getActiveGameLevels() {
        return axios.get('gameLevel/active');
    }
}

export default new GameLevelService();
