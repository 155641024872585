import axios from "../axios";

class SportTypesService {
    save(sportType) {
        return axios.post('sportType/save', sportType);
    }

    delete(sportTypeId) {
        return axios.post('sportType/delete', {id: sportTypeId});
    }

    getActiveSportTypes() {
        return axios.get('sportType/active');
    }
}

export default new SportTypesService();
