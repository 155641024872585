import {Box} from "@mui/material";
import {Button as MUIButton} from '@mui/material';
import Button from "react-bootstrap/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import {globalProps} from "../../config";
import Stack from "react-bootstrap/Stack";
import React, {useState} from "react";
import {useLocalState} from "../../util/useLocatStorage";
import SubscribeToTrainingDialog from "../trainings/SubscribeToTrainingDialog";
import UnsubscribeFromTrainingDialog from "../trainings/UnsubscribeFromTrainingDialog";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SubscribeUnregisteredDialog from "../trainings/SubscribeUnregisteredDialog";

/** Кнопки действия над тренировкой основные
 * @param training Тренировка
 * @param buttonLocation Ключ, указывающий где распологаются кнопки (globalProps.trainingButtonsLocation)
 * */
const TrainingButtons = ({training, buttonLocation}) => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    //Открытие/закрытие окон подтверждения действия
    const [open, setOpen] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const openDialog = (type) => {
        setOpen(type);
    }

    return (
        <>
            {buttonLocation === globalProps.trainingButtonsLocation.SCHEDULE &&
                <Stack gap={2}>
                    <Stack direction="horizontal" gap={2}>
                        {training.currentUserRegisteredForTraining
                            ?
                            <>
                                <Button variant="outline-danger" className="w-100"
                                        onClick={() => openDialog("unsubscribe")}>
                                    Отписаться
                                </Button>
                                {(!training.trainingAdmin && (currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER')) &&
                                    <Button variant="primary" className="ms-auto" title="Записаться админом"
                                            onClick={() => openDialog("subscribeAsAdmin")}>
                                        <ManageAccountsIcon fontSize="small"/>
                                    </Button>
                                }
                            </>
                            :
                            (training.currentParticipantsCount < training.maxParticipants ?
                                    <Button variant="primary" className="w-100"
                                            onClick={() => openDialog("subscribe")}>
                                        Записаться
                                    </Button>
                                    :
                                    <Button variant="primary" className="w-100"
                                            onClick={() => openDialog("subscribeForReserve")}>
                                        Записаться в резерв
                                    </Button>
                            )
                        }
                        {training.currentUserTrainingAdmin &&
                            <Button variant="outline-danger" className="ms-auto"
                                    title="Отписаться от администрирования"
                                    onClick={() => openDialog("unsubscribeAsAdmin")}>
                                <PersonRemoveIcon fontSize="small"/>
                            </Button>
                        }
                    </Stack>
                    {training.allowUnregisteredSignup &&
                        <Button variant="secondary" className="w-100"
                                onClick={() => openDialog("subscribeUnregistered")}>
                            Добавить участника
                        </Button>
                    }
                </Stack>
            }
            {buttonLocation === globalProps.trainingButtonsLocation.TRAINING_PAGE &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >
                    {training.status === 'NEW' &&
                        <ButtonGroup>
                            {training.currentUserRegisteredForTraining && (
                                <MUIButton color="error" onClick={() => openDialog("unsubscribe")}>
                                    Отписаться
                                </MUIButton>
                            )}
                            {training.currentUserRegisteredForTraining && !training.trainingAdmin &&
                                (currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER') && (
                                    <MUIButton title="Записаться админом" variant="contained"
                                               onClick={() => openDialog("subscribeAsAdmin")}>
                                        <ManageAccountsIcon fontSize="small"/>
                                    </MUIButton>
                                )}
                            {!training.currentUserRegisteredForTraining && (
                                training.currentParticipantsCount < training.maxParticipants ? (
                                    <MUIButton onClick={() => openDialog("subscribe")}
                                               variant="contained">Записаться</MUIButton>
                                ) : (
                                    <MUIButton onClick={() => openDialog("subscribeForReserve")} variant="contained">
                                        Записаться в резерв
                                    </MUIButton>
                                )
                            )}
                            {training.currentUserTrainingAdmin && (
                                <MUIButton color="error" title="Отписаться от администрирования"
                                           onClick={() => openDialog("unsubscribeAsAdmin")}>
                                    <PersonRemoveIcon fontSize="small"/>
                                </MUIButton>
                            )}
                            {training.allowUnregisteredSignup &&
                                <MUIButton variant="contained" color="inherit"
                                           onClick={() => openDialog("subscribeUnregistered")}>
                                    Добавить участника
                                </MUIButton>
                            }
                        </ButtonGroup>
                    }
                </Box>
            }

            {/*Окно подтвеждения записи*/}
            <SubscribeToTrainingDialog training={training} open={open} handleClose={handleClose}/>

            {/*Окно потверждения отписки от тренировки*/}
            <UnsubscribeFromTrainingDialog training={training} open={open} handleClose={handleClose}/>

            {/*Запись незарегистрированного пользователя*/}
            <SubscribeUnregisteredDialog training={training} open={open} handleClose={handleClose}/>
        </>
    )
}

export default TrainingButtons;