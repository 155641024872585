import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React, {useState} from "react";
import TrainingService from "../../services/TrainingService";
import Popover from "react-bootstrap/Popover";
import {ListGroup} from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from "@mui/material";
import {useLocalState} from "../../util/useLocatStorage";
import UnsubscribeParticipantDialog from "./UnsubscribeParticipantDialog";

/**
 * Компонент для отображения списка участников тренировки в виде всплывающего окна.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.training - Объект тренировки.
 * @returns {JSX.Element} Компонент всплывающего окна с участниками.
 */
const TrainingParticipantsPopover = ({training}) => {
    const [currentUserId] = useLocalState({}, "currentUserId");
    const [currentUserRole] = useLocalState({}, "currentUserRole");
    const [baseTrainingParticipants, setBaseTrainingParticipants] = useState([]);
    const [reserveTrainingParticipants, setReserveTrainingParticipants] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [openUnsubscribeDialog, setOpenUnsubscribeDialog] = useState(false);

    /**
     * Загружает участников тренировки и обновляет состояния.
     */
    const loadTrainingParticipants = () => {
        TrainingService.getTrainingParticipants(training.id).then((res) => {
            if (res.data.base.length) {
                setBaseTrainingParticipants(res.data.base);
            }
            if (res.data.reserve.length) {
                setReserveTrainingParticipants(res.data.reserve);
            }
        });
    };

    /**
     * Закрывает диалоговое окно отписки участника.
     */
    const handleUnsubscribeDialogClose = () => {
        setOpenUnsubscribeDialog(false);
    };

    /**
     * Открывает диалоговое окно отписки участника.
     *
     * @param {Object} participant - Объект участника для отписки.
     */
    const handleUnsubscribeDialogOpen = (participant) => {
        setSelectedParticipant(participant);
        setOpenUnsubscribeDialog(true);
    };

    const trainingParticipantsPopover = (
        <Popover>
            <Popover.Body>
                {training.trainingAdmin && (
                    <p>
                        <u>Администратор: {training.trainingAdmin.lastName} {training.trainingAdmin.firstName}</u>
                    </p>
                )}
                {baseTrainingParticipants.length ? (
                    <>
                        <div>
                            <ListGroup variant="flush" numbered>
                                {baseTrainingParticipants.map((participant) => (
                                    <ListGroup.Item key={participant.id}>
                                        {`${participant.name} ${participant.addedBy ?
                                            '(Записал ' + participant.addedBy.lastName + ' ' +
                                            participant.addedBy.firstName + ')' : ''}`}
                                        {(participant.addedBy && (participant.addedBy.id === currentUserId || currentUserRole === 'ADMIN')) && (
                                            <IconButton
                                                size="small"
                                                title="Отписать участника"
                                                onClick={() => handleUnsubscribeDialogOpen(participant)}
                                            >
                                                <CloseIcon fontSize="small" color="error"/>
                                            </IconButton>
                                        )}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                        {reserveTrainingParticipants.length ? (
                            <div>
                                Резерв:
                                <ListGroup variant="flush" numbered>
                                    {reserveTrainingParticipants.map((participant) => (
                                        <ListGroup.Item key={participant.id}>
                                            {`${participant.name} ${participant.addedBy ?
                                                '(Записал ' + participant.addedBy.lastName + ' ' +
                                                participant.addedBy.firstName + ')' : ''}`}
                                            {(participant.addedBy && (participant.addedBy.id === currentUserId || currentUserRole === 'ADMIN')) && (
                                                <IconButton
                                                    size="small"
                                                    title="Отписать участника"
                                                    onClick={() => handleUnsubscribeDialogOpen(participant)}
                                                >
                                                    <CloseIcon fontSize="small" color="error"/>
                                                </IconButton>
                                            )}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div>Участников еще нет</div>
                )}
                <div>Минимум - {training.minParticipants}</div>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger overlay={trainingParticipantsPopover} trigger="click" placement="bottom" rootClose>
                <Card.Link onClick={loadTrainingParticipants} className="mb-3">
                    {'Участников: ' + training.currentParticipantsCount + '/' + training.maxParticipants}
                </Card.Link>
            </OverlayTrigger>

            <UnsubscribeParticipantDialog
                open={openUnsubscribeDialog}
                handleClose={handleUnsubscribeDialogClose}
                training={training}
                participant={selectedParticipant}
            />
        </>
    );
};

export default TrainingParticipantsPopover;
