import Table from "@mui/material/Table";
import RequestsTableHead from "./RequestsTableHead";
import {IconButton, TableBody, TableContainer} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {formatDate1} from "../../../../util/dateFormatter";

/** Таблица с заявками
 * @param requests Массив заявок
 * @param acceptHandler Обработчие принятия заявки
 * @param rejectHandler Обработчие отклонения заявки
 * */
const RequestsTable = ({requests, acceptHandler, rejectHandler}) => {
    return (
        <TableContainer>
            <Table>
                <RequestsTableHead/>
                <TableBody>
                    {requests.map((request) => {
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={request.id}
                                sx={{cursor: 'pointer'}}
                            >
                                <TableCell align="left">{request.typeDescription}</TableCell>
                                <TableCell align="left">{request.statusDescription}</TableCell>
                                <TableCell align="left">{request.description}</TableCell>
                                <TableCell align="left">{formatDate1(request.createdDate)}</TableCell>
                                <TableCell align="left">{formatDate1(request.updatedDate)}</TableCell>
                                <TableCell align="left">
                                    {request.status === 'NEW' &&
                                        <div className={"d-flex gap-1"}>
                                            <IconButton
                                                onClick={() => acceptHandler(request.id)}
                                                title="Принять"
                                            >
                                                <CheckIcon/>
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() => rejectHandler(request.id)}
                                                title="Отклонить"
                                            >
                                                <CloseIcon/>
                                            </IconButton>
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default RequestsTable;