import axios from "../axios";

class NewsService {
    getNews() {
        return axios.get('news/');
    }

    saveNewsFromVK(newsItem) {
        return axios.post('news/save', newsItem);
    }

    save(formData) {
        return axios.post(`news/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
    }

    delete(newsId) {
        return axios.post('news/delete', {id: newsId});
    }
}

export default new NewsService();
