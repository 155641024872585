import axios from "../axios";

class GymService {
    save(gym) {
        return axios.post('gym/save', gym);
    }

    delete(gymId) {
        return axios.post('gym/delete', {id: gymId});
    }

    getActiveGyms() {
        return axios.get('gym/active');
    }
}

export default new GymService();
