import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import React from "react";

const AdminMenuComponent = () => {
    return (
        <Stack gap={3} className={"mt-3"}>
            <Button href={'/responsible-user-trainings'}>
                Мои администрирования
            </Button>
            <Button href={'/administration'}>
                Администрирование
            </Button>
        </Stack>
    )
}

export default AdminMenuComponent;