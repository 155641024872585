import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {useLocalState} from "../../util/useLocatStorage";
import TrainingService from "../../services/TrainingService";
import Message from "../Message";
import {formatDate3} from "../../util/dateFormatter";

const SubscribeToTrainingDialog = ({training, open, handleClose}) => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [subscribeAsParticipant, setSubscribeAsParticipant] = useState(true);
    const [subscribeAsAdmin, setSubscribeAsAdmin] = useState(!training.trainingAdmin);

    const changeSubscribeAsParticipant = (event) => {
        setSubscribeAsParticipant(event.target.checked);
    }
    const changeSubscribeAsAdmin = (event) => {
        setSubscribeAsAdmin(event.target.checked);
    }

    // Запись на тренировку
    const subscribe = () => {
        const subscribeOptions = {
            trainingId: training.id
        };
        if (currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER') {
            subscribeOptions.isParticipant = open === 'subscribeAsAdmin' ? false : subscribeAsParticipant;
            subscribeOptions.isAdmin = subscribeAsAdmin;
        }
        TrainingService.subscribe(subscribeOptions).then(r => {
            if (r.status === 200) {
                handleClose();
                setIsMessageOpen(true);
                setMessageText("Вы успешно записались на тренировку");
            } else {
                setIsMessageOpen(true);
                setMessageText("Произошла ошибка");
                setMessageType("error");
            }
        }).catch(message => {
            handleClose();
            setIsMessageOpen(true);
            if (message.response && message.response.data) {
                if (typeof message.response.data === "string") {
                    setMessageText(message.response.data);
                } else if (message.response.data.message && typeof message.response.data.message === "string") {
                    setMessageText(message.response.data.message);
                }
            } else {
                setMessageText("Произошла ошибка");
            }
            setMessageType("danger");
            setShowOKButtonInMessage(true);
        });
    }

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, setMessageType] = useState("success");
    const [showOKButtonInMessage, setShowOKButtonInMessage] = useState(false);

    return (
        <>
            <Dialog
                open={open === 'subscribe' || open === 'subscribeAsAdmin' || open === 'subscribeForReserve'}
                onClose={handleClose}
            >
                <DialogTitle>Подтверждение записи</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {'Запись на тренировку по адресу ' + training.gym.address}
                        <br/>
                        {"Дата: " + formatDate3(training.date)}
                        <br/>
                        {"Время: с " + training.startTime + " до " + training.endTime}
                        {(open === 'subscribeForReserve' && !(currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER') ||
                                (open === 'subscribeForReserve' && (currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER') && subscribeAsParticipant)) &&
                            <div>
                                <b>Внимание!</b> Вы будете записаны в <b>резерв</b>. Как только вы попадете в общий
                                список вам
                                придет уведомление (привяжите телеграм аккаунт в личном кабинете, чтобы получать
                                уведомления
                                в телеграм)
                            </div>
                        }
                    </DialogContentText>
                    {currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER' ?
                        <div className={"mt-2"}>
                            <Form.Check defaultChecked={true}
                                        type="switch"
                                        label="Записаться участником"
                                        disabled={open === 'subscribeAsAdmin'}
                                        onChange={changeSubscribeAsParticipant}
                            />
                            <Form.Check defaultChecked={!training.trainingAdmin}
                                        disabled={training.trainingAdmin || open === 'subscribeAsAdmin'}
                                        type="switch"
                                        label="Записаться администратором"
                                        onChange={changeSubscribeAsAdmin}
                            />
                        </div>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
                    <Button onClick={subscribe} autoFocus
                            disabled={!subscribeAsParticipant && !subscribeAsAdmin}>Записаться</Button>
                </DialogActions>
            </Dialog>

            {/*Всплывающее сообщение*/}
            {isMessageOpen ?
                <Message text={messageText} type={messageType} showOKButton={showOKButtonInMessage}/> : null}
        </>
    );
}

export default SubscribeToTrainingDialog;