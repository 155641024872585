import ScheduleComponent from "./components/trainings/ScheduleComponent";
import {Navigate, Route, Routes} from "react-router-dom";
import LoginComponent from "./components/authorization/LoginComponent";
import {useLocalState} from "./util/useLocatStorage";
import HeaderComponent from "./components/HeaderComponent";
import PrivateRoute from "./components/PrivateRoute";
import HomeComponent from "./components/home/HomeComponent";
import FooterComponent from "./components/FooterComponent";
import AdministrationMainComponent from "./components/administration/AdministrationMainComponent";
import UserPageComponent from "./components/userPage/UserPageComponent";
import NewsComponent from "./components/NewsComponent";
import RegistrationComponent from "./components/authorization/RegistrationComponent";
import React, {useEffect, useState} from "react";
import SuccessfulRegistrationComponent from "./components/authorization/SuccessfulRegistrationComponent";
import PasswordUpdateComponent from "./components/authorization/PasswordUpdateComponent";
import ResponsibleUserTrainings from "./components/responsibleUserTrainings/ResponsibleUserTrainings";
import MobileNavigationComponent from "./components/MobileNavigationComponent";
import AdminMenuComponent from "./components/AdminMenuComponent";
import TrainingPage from "./components/trainingPage/TrainingPage";
import About from "./components/About";

function App() {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [isPasswordRecoveryInProgress] = useLocalState(false, "isPasswordRecoveryInProgress");

    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 479px)").matches
    )

    const blocksStyle = {
        paddingBottom: '60px'
    }

    useEffect(() => {
        window.matchMedia("(max-width: 479px)").addEventListener('change', e => setMatches(e.matches));
    }, []);

    return (
        <>
            {!matches && <HeaderComponent/>}
            <Routes>
                <Route exact path="/" element={
                    <HomeComponent/>
                }/>
                <Route path="/trainings/schedule"
                       element={
                           <div className={"container"} style={blocksStyle}>
                               <PrivateRoute>
                                   <ScheduleComponent/>
                               </PrivateRoute>
                           </div>
                       }
                />
                <Route path="/page/user"
                       element={
                           <div className={"container"} style={blocksStyle}>
                               <PrivateRoute>
                                   <UserPageComponent/>
                               </PrivateRoute>
                           </div>
                       }
                />
                <Route path="/login" element={
                    <div className={"container"} style={blocksStyle}>
                        <LoginComponent/>
                    </div>
                }></Route>
                <Route path="/registration" element={
                    <div className={"container"} style={blocksStyle}>
                        <RegistrationComponent/>
                    </div>
                }></Route>
                <Route path="/registration/success" element={
                    <div className={"container"}>
                        <SuccessfulRegistrationComponent/>
                    </div>
                }></Route>
                <Route path="/administration"
                       element={
                           <div className={"container"} style={blocksStyle}>
                               <PrivateRoute>
                                   {currentUserRole === 'ADMIN' ? <AdministrationMainComponent/> : <Navigate to="/"/>}
                               </PrivateRoute>
                           </div>
                       }
                />
                <Route path="/news" element={
                    <div className={"container"} style={blocksStyle}>
                        <NewsComponent/>
                    </div>
                }></Route>
                <Route path="/password/update" element={
                    <div className={"container"} style={blocksStyle}>
                        {isPasswordRecoveryInProgress ? <PasswordUpdateComponent/> : <Navigate to="/login"/>}
                    </div>
                }></Route>
                <Route path="/responsible-user-trainings"
                       element={
                           <div className={"container"} style={blocksStyle}>
                               <PrivateRoute>
                                   {currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER' ?
                                       <ResponsibleUserTrainings/>
                                       :
                                       <Navigate to="/"/>
                                   }
                               </PrivateRoute>
                           </div>
                       }
                />
                {matches && <Route path="/admin-menu"
                       element={
                           <div className={"container"} style={blocksStyle}>
                               <PrivateRoute>
                                   {currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER' ?
                                       <AdminMenuComponent/>
                                       :
                                       <Navigate to="/"/>
                                   }
                               </PrivateRoute>
                           </div>
                       }
                />}
                <Route path="/training/:id" element={
                    <div className={"container"} style={blocksStyle}>
                        <PrivateRoute>
                            <TrainingPage/>
                        </PrivateRoute>
                    </div>
                }/>
                <Route exact path="/about" element={
                    <div style={blocksStyle}>
                        <About />
                    </div>
                }/>
            </Routes>
            {matches ? <MobileNavigationComponent/> : <FooterComponent/>}
        </>
    );
}

export default App;
