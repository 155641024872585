import Card from "@mui/material/Card";
import {Box, Divider, Grid, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import TrainingPrice from "./TrainingPrice";
import {Badge} from "react-bootstrap";
import React, {useMemo} from "react";
import {globalProps} from "../../config";
import TrainingPageParticipants from "./TrainingPageParticipants";
import {formatDate1} from "../../util/dateFormatter";

const HEADERS_STYLE = {lineHeight: 1.4};

/** Карточка тренировки с данными о ней и участниками
 * @param training Тренировка
 * */
const Training = ({training}) => {

    const trainingStatusBgColor = useMemo(() => {
        if (training.status === 'NEW') {
            return 'success';
        } else if (training.status === 'COMPLETED') {
            return 'secondary';
        } else if (training.status === 'CANCELLED') {
            return 'danger';
        }
        return 'secondary';
    }, [training]);

    return (
        <Card variant="outlined" className="w-100">
            <Box sx={{p: 2}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Badge bg={trainingStatusBgColor}>{globalProps.trainingStatus[training.status]}</Badge>
                    <TrainingPrice training={training}/>
                </Stack>
                <Typography gutterBottom variant="h5" component="div">
                    {training.sportType.name}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{p: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="overline" display="block" sx={HEADERS_STYLE}>
                            Дата тренировки
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className="border-bottom">
                        {formatDate1(training.date)}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="overline" display="block" sx={HEADERS_STYLE}>
                            Вид тренировки
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className="border-bottom">
                        {training.trainingType?.name}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="overline" display="block" sx={HEADERS_STYLE}>
                            Уровень игры
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className="border-bottom">
                        {training.minGameLevel.name}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="overline" display="block" sx={HEADERS_STYLE}>
                            Время тренировки
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className="border-bottom">
                        {"C " + training.startTime + " до " + training.endTime}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="overline" display="block" sx={HEADERS_STYLE}>
                            Адрес проведения
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className="border-bottom">
                        {training.gym.name} ({training.gym.address})
                    </Grid>
                </Grid>
            </Box>
            <Divider/>
            <Box sx={{p: 2}}>
                <Typography gutterBottom variant="h6" component="div">
                    {`Участники тренировки ${training.currentBaseParticipantsCount}/${training.maxParticipants}`}
                </Typography>
                <TrainingPageParticipants training={training}/>
            </Box>
        </Card>
    )
}

export default Training;