import React, {useMemo} from 'react';
import TrainingService from "../../../../services/TrainingService";
import DeleteModal from "../../../DeleteModal";
import RepeatTrainingsModal from "../RepeatTrainingsModal";
import CreateOrEditTrainingModal from "../CreateOrEditTrainingModal";
import {globalProps} from "../../../../config";
import {Alert} from "react-bootstrap";
import PaginationComponent from "../../../PaginationComponent";
import DownloadReport from "../DownloadReport";
import TableEntityCard from "../../TableEntityCard";
import TrainingsFilter from "./TrainingsFilter";
import Button from "react-bootstrap/Button";
import MultipleTrainingsDeleteModal from "../MultipleTrainingsDeleteModal";
import {formatDate1} from "../../../../util/dateFormatter";

const TrainingsComponent = ({
                                dictionariesData,
                                setDataChanged,
                                filters,
                                filterByOption,
                                clearFilters,
                                trainings,
                                trainingsPage,
                                trainingPageCfg,
                                setTrainingPageCfg,
                                selectedItems,
                                isSelected,
                                handleClick,
                                getSelectedTrainings
                            }) => {
    /** Формирование массива с атрибутами тренировки для отображения в карточке
     * Один строковый элемент массива отображается на одной строке в карточке
     * */
    const generateCardPropsArray = (training) => {
        return [
            `${training.sportType.name} (${training.minGameLevel.name})`,
            `${formatDate1(training.date)} (${training.startTime} - ${training.endTime})`,
            training.price + '₽',
            training.gym.name + ' (' + training.gym.address + ')',
            globalProps.trainingStatus[training.status]
        ]
    }

    /** Получение цвета границы карточки тренировки в зависимости от статуса
     * Завершенная - зеленый
     * Отмененная - красный
     * Остальные - без цвета
     * */
    const getBorderColor = (status) => {
        return status === 'COMPLETED' ? 'success' : (status === 'CANCELLED' ? 'danger' : 'transparent');
    }

    /**Массив действий с тренировкой в карточке*/
    const getTrainingActions = (training) => {
        return [
            <DeleteModal id={training.id} fnDelete={TrainingService.delete}/>,
            <CreateOrEditTrainingModal training={training}
                                       mode={globalProps.mode.edit}
                                       dictionariesData={dictionariesData}
                                       setDataChanged={setDataChanged}
            />
        ]
    }

    const showClearFiltersBtn = useMemo(
        () => Object.values(filters).some(value => value !== null && value !== undefined && value !== ''),
        [filters]
    );

    return (
        <>
            <div className="mb-3 d-flex justify-content-between">
                <div className="d-flex gap-4">
                    <CreateOrEditTrainingModal mode={globalProps.mode.create}
                                               createButtonType="icon"
                                               dictionariesData={dictionariesData}
                    />
                    <RepeatTrainingsModal selectedItems={selectedItems}
                                          getSelectedTrainings={getSelectedTrainings}
                                          buttonType="icon"
                    />
                    <MultipleTrainingsDeleteModal selectedItems={selectedItems}
                                                  getSelectedTrainings={getSelectedTrainings}
                                                  buttonType="icon"/>
                    <DownloadReport buttonType="icon"/>
                </div>
                <div className="d-flex gap-0">
                    {showClearFiltersBtn &&
                        <Button variant="link" size="sm" onClick={clearFilters}>
                            <small>Очистить фильтры</small>
                        </Button>
                    }
                    <TrainingsFilter initialFilters={filters}
                                     filter={filterByOption}
                                     dictionariesData={dictionariesData}
                                     setTrainingPageCfg={setTrainingPageCfg}
                                     trainingPageCfg={trainingPageCfg}
                    />
                </div>
            </div>


            {trainings.map(training =>
                <TableEntityCard props={generateCardPropsArray(training)}
                                 borderColor={getBorderColor(training.status)}
                                 actions={getTrainingActions(training)}
                                 checkboxClickHandle={(event) => handleClick(event, training.id)}
                                 checkboxSelected={isSelected(training.id)}
                                 key={training.id}
                />
            )}

            {!trainings.length && <Alert>Тренировки не найдены</Alert>}

            <PaginationComponent data={trainingsPage}
                                 pageCfg={trainingPageCfg}
                                 setPageCfg={setTrainingPageCfg}
                                 changeItemsCount={false}
                                 hideTotalCount={true}
            />
        </>
    );
};

export default TrainingsComponent;
