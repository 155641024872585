import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import {DropdownButton} from "react-bootstrap";
import React, {useState} from "react";
import UserService from "../../../services/UserService";
import Message from "../../Message";
import SetAdminDialog from "./SetAdminDialog";
import DeleteAdminDialog from "./DeleteAdminDialog";
import TrainingEventsDialog from "./TrainingEventsDialog";
import EditTrainingDialog from "./EditTrainingDialog";
import CloseTrainingDialog from "./CloseTrainingDialog";

const ActionWithTrainingForAdmin = ({training}) => {
    const [setAdminDialogOpen, setSetAdminDialogOpen] = useState(false);
    const [deleteAdminDialogOpen, setDeleteAdminDialogOpen] = useState(false);
    const [editTrainingDialogOpen, setEditTrainingDialogOpen] = useState(false);
    const [closeTrainingDialogOpen, setCloseTrainingDialogOpen] = useState(false);
    const [trainingEventsDialogOpen, setTrainingEventsDialogOpen] = useState(false);

    const [allAdmins, setAllAdmins] = useState([]);
    const handleAdminDialogOpen = () => {
        UserService.getTrainingAdmins().then(res => {
            setAllAdmins(res.data);
        });
        setSetAdminDialogOpen(true);
    }

    const customizeMessage = (isOpen, messageType, messageText, showOKButtonInMessage) => {
        setIsMessageOpen(isOpen);
        if (messageType) {
            setMessageType(messageType);
        }
        if (messageText) {
            setMessageText(messageText);
        }
        setShowOKButtonInMessage(showOKButtonInMessage ? showOKButtonInMessage : false);
    }

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, setMessageType] = useState("success");
    const [showOKButtonInMessage, setShowOKButtonInMessage] = useState(false);

    return (
        <>
            <DropdownButton title="Действия" as={ButtonGroup} variant={"outline-secondary"} className={"w-100 p-3"}>
                {training.trainingAdmin
                    ?
                    <Dropdown.Item onClick={() => setDeleteAdminDialogOpen(true)}>Убрать администратора</Dropdown.Item>
                    :
                    <Dropdown.Item onClick={() => handleAdminDialogOpen({})}>Назначить администратора</Dropdown.Item>
                }

                <Dropdown.Item onClick={() => setEditTrainingDialogOpen(true)}>Редактировать</Dropdown.Item>
                <Dropdown.Item onClick={() => setCloseTrainingDialogOpen(true)}>Отменить</Dropdown.Item>
                <Dropdown.Item onClick={() => setTrainingEventsDialogOpen(true)}>События</Dropdown.Item>
            </DropdownButton>

            <SetAdminDialog training={training}
                            allAdmins={allAdmins}
                            open={setAdminDialogOpen}
                            handleClose={() => setSetAdminDialogOpen(false)}
                            customizeMessage={customizeMessage}
            />

            <DeleteAdminDialog training={training}
                               open={deleteAdminDialogOpen}
                               handleClose={() => setDeleteAdminDialogOpen(false)}
                               customizeMessage={customizeMessage}
            />

            <EditTrainingDialog training={training}
                                open={editTrainingDialogOpen}
                                handleClose={() => setEditTrainingDialogOpen(false)}
                                customizeMessage={customizeMessage}
            />

            <CloseTrainingDialog training={training}
                                 open={closeTrainingDialogOpen}
                                 handleClose={() => setCloseTrainingDialogOpen(false)}
                                 customizeMessage={customizeMessage}
            />

            <TrainingEventsDialog training={training}
                                  open={trainingEventsDialogOpen}
                                  handleClose={() => setTrainingEventsDialogOpen(false)}
                                  customizeMessage={customizeMessage}
            />

            {/*Всплывающее сообщение*/}
            {isMessageOpen ?
                <Message text={messageText} type={messageType} showOKButton={showOKButtonInMessage}/> : null}
        </>
    );
}

export default ActionWithTrainingForAdmin;