import React, {useCallback} from 'react';
import EditUserModal from "./EditUserModal";
import UserService from "../../../services/UserService";
import DeleteModal from "../../DeleteModal";
import {Card, CloseButton, Table} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import BanUserModal from "./BanUserModal";
import PaginationComponent from "../../PaginationComponent";
import {formatDate1} from "../../../util/dateFormatter";

/**
 * Компонент для отображения всех пользователей.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.filters - Текущие фильтры.
 * @param {Function} props.filterByOption - Функция для фильтрации пользователей.
 * @param {Function} props.clearFilters - Функция для очистки фильтров.
 * @param {Array} props.users - Список пользователей.
 * @param {Object} props.usersPage - Конфигурация пагинации пользователей.
 * @param {Object} props.allUsersPageCfg - Конфигурация страницы всех пользователей.
 * @param {Function} props.setAllUsersPageCfg - Функция для установки конфигурации страницы.
 * @param {Function} props.setDataChanged - Функция для обновления данных страницы
 */
const AllUsersComponent = ({
                               filters,
                               filterByOption,
                               clearFilters,
                               users,
                               usersPage,
                               allUsersPageCfg,
                               setAllUsersPageCfg,
                               setDataChanged
                           }) => {

    /**
     * Создает строку таблицы для пользователя.
     *
     * @param {Object} user - Объект пользователя.
     * @param {number} index - Индекс пользователя в списке.
     * @returns {JSX.Element} Строка таблицы.
     */
    const userTableRow = useCallback((user, index) => {
        return (
            <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.lastName}</td>
                <td>{user.firstName}</td>
                <td>{user.middleName}</td>
                <td>{formatDate1(user.birthday)}</td>
                <td>{user.role}</td>
                <td>{user.status}</td>
                <td>
                    <div className="d-flex gap-1">
                        <EditUserModal user={user} key={`edit-${user.id}`} setDataChanged={setDataChanged}/>
                        <DeleteModal
                            id={user.id}
                            fnDelete={UserService.delete}
                            title="Архивирование пользователя"
                            text="Вы хотите архивировать пользователя, вы уверены?"
                            key={`delete-${user.id}`}
                        />
                        {user.status !== 'BANNED' && <BanUserModal user={user}
                                                                   key={`ban-${user.id}`}
                                                                   setDataChanged={setDataChanged}
                        />
                        }
                    </div>
                </td>
            </tr>
        );
    }, []);

    return (
        <>
            <div className="d-flex flex-row m-3 gap-1">
                <Card className="w-50">
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">Поиск по ФИО</Card.Subtitle>
                        <Form.Control
                            value={filters.name}
                            onChange={(e) => filterByOption(e.target.value, 'name')}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">Роль</Card.Subtitle>
                        <Form.Select
                            value={filters.role}
                            onChange={(e) => filterByOption(e.target.value, 'role')}
                        >
                            <option value=""></option>
                            <option value="NONE">Без роли (NONE)</option>
                            <option value="READER">Читатель (READER)</option>
                            <option value="USER">Пользователь (USER)</option>
                            <option value="RESPONSIBLE_USER">Админ тренировки (RESPONSIBLE_USER)</option>
                            <option value="ADMIN">Главный админ (ADMIN)</option>
                        </Form.Select>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">Статус</Card.Subtitle>
                        <Form.Select
                            value={filters.status}
                            onChange={(e) => filterByOption(e.target.value, 'status')}
                        >
                            <option value=""></option>
                            <option value="NONE">Без статуса (NONE)</option>
                            <option value="ACTIVE">Активный (ACTIVE)</option>
                            <option value="BANNED">Заблокированный (BANNED)</option>
                            <option value="ARCHIVED">Архивированный (ARCHIVED)</option>
                        </Form.Select>
                    </Card.Body>
                </Card>
                <CloseButton title="Очистить фильтры" onClick={clearFilters}/>
            </div>
            <Table responsive>
                <thead>
                <tr className="table-active">
                    <th>#</th>
                    <th>E-mail</th>
                    <th>Номер телефона</th>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Отчество</th>
                    <th>Дата рождения</th>
                    <th>Роль</th>
                    <th>Статус</th>
                    <th></th>
                </tr>
                </thead>
                {users.length ? (
                    <tbody>{users.map((user, index) => userTableRow(user, index))}</tbody>
                ) : (
                    <caption className="text-center">Пользователи не найдены</caption>
                )}
            </Table>

            <PaginationComponent data={usersPage}
                                 pageCfg={allUsersPageCfg}
                                 setPageCfg={setAllUsersPageCfg}
                                 changeItemsCount={true}
            />
        </>
    );
};

export default AllUsersComponent;
