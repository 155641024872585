import axios from "../axios";

class TrainingService {
    getTraining(trainingId) {
        return axios.get('trainings/' + trainingId);
    }

    getTodayTrainings(sportTypeId, gameLevelsId) {
        return axios.get('trainings/today' +
            (sportTypeId ? '?sportTypeId=' + sportTypeId + (gameLevelsId && gameLevelsId.length ? '&gameLevelsId=' + gameLevelsId : '') : ''));
    }

    getTomorrowTrainings(sportTypeId, gameLevelsId) {
        return axios.get('trainings/tomorrow?sportTypeId=' + sportTypeId + (gameLevelsId && gameLevelsId.length ? '&gameLevelsId=' + gameLevelsId : ''));
    }

    getOtherTrainings(sportTypeId, gameLevelsId) {
        return axios.get('trainings/other?sportTypeId=' + sportTypeId + (gameLevelsId && gameLevelsId.length ? '&gameLevelsId=' + gameLevelsId : ''));
    }

    getCurrentUserTrainings() {
        return axios.get('trainings/currentUserTrainings');
    }

    getTrainingParticipants(trainingId) {
        return axios.get('trainings/trainingParticipants?trainingId=' + trainingId);
    }

    subscribe(options) {
        return axios.post('trainings/subscribe', options);
    }

    subscribeUnregistered(options) {
        return axios.post('trainings/addUnregistered', options);
    }

    unsubscribe(trainingId) {
        return axios.post('trainings/unsubscribe', {trainingId: trainingId});
    }

    removeUnregistered(trainingId, participantId) {
        return axios.post('trainings/removeUnregistered', {trainingId: trainingId, userId: participantId});
    }

    unsubscribeAdmin(trainingId) {
        return axios.post('trainings/admin/delete?trainingId=' + trainingId);
    }

    addAdmin(trainingId, userId) {
        return axios.post('trainings/admin/add?trainingId=' + trainingId + '&userId=' + userId);
    }

    save(training) {
        return axios.post('trainings/save', training);
    }

    delete(trainingId) {
        return axios.post('trainings/delete', {trainingId: trainingId});
    }

    multipleDelete(trainings) {
        return axios.post('trainings/delete', {trainings: trainings});
    }

    repeat(trainingsId, weekCount) {
        return axios.post('trainings/repeat', {
            trainings: trainingsId, weekCount: weekCount
        });
    }

    checkUniqueForRepeat(trainingsId, weekCount) {
        return axios.post('trainings/checkUniqueForRepeat', {
            trainings: trainingsId, weekCount: weekCount
        });
    }

    getResponsibleUserTrainings() {
        return axios.get('trainings/whereCurrentUserIsAdmin');
    }

    getTrainingsToMarkPayment() {
        return axios.get('trainings/forMarkPayers');
    }

    finishTraining(trainingId, usersId) {
        return axios.post('trainings/complete?trainingId=' + trainingId + '&usersId=' + usersId);
    }

    cancelTraining(trainingId) {
        return axios.post('trainings/cancel?trainingId=' + trainingId);
    }

    getTrainingsHistory() {
        return axios.get('trainings/currentUserTrainingsHistory');
    }

    markPayers(trainingId, usersId) {
        return axios.post('trainings/markPayers?trainingId=' + trainingId + '&usersId=' + usersId);
    }

    markTheDebtor(participantId) {
        return axios.post('trainings/markPayers?participantId=' + participantId);
    }

    downloadReport(startDate, endDate) {
        return axios.get('report/excel?startDate=' + startDate + '&endDate=' + endDate, {
            responseType: 'blob'
        });
    }
}

export default new TrainingService();
