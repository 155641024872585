import React, {useState} from 'react';
import {useLocalState} from "../util/useLocatStorage";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/bls-logo.png';
import Button from "react-bootstrap/Button";

const Header = () => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [jwt, setJwt] = useLocalState("", "jwt");

    // Для стилей под мобилки
    const [matches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    const svgClass = "bi bi-person-circle";
    const svgViewBox = "0 0 16 16";
    const svgFill = "currentColor";

    return (
        <>
            <svg style={{display: "none"}}>
                <symbol id="icon-user">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fillRule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </symbol>
                <symbol id="icon-settings">
                    <path
                        d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                </symbol>
                <symbol id="icon-logout">
                    <path fillRule="evenodd"
                          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                    <path fillRule="evenodd"
                          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                </symbol>
                <symbol id="icon-notifications">
                    <path
                        d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                </symbol>
            </svg>
            <Navbar bg={"light"} className={"mb-2"}>
                <Container className={matches ? null : "d-flex flex-column"}>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="60"
                            height="60"
                            className="d-inline-block align-top"
                            alt="BLS Logo"
                        />
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link href="/">Главная</Nav.Link>
                        <Nav.Link href="/trainings/schedule">Расписание</Nav.Link>
                        <Nav.Link href="/news">Новости</Nav.Link>
                        <Nav.Link href="/about">О нас</Nav.Link>
                    </Nav>
                    <div>
                        <Nav>
                            {(currentUserRole === 'ADMIN' || currentUserRole === 'RESPONSIBLE_USER') &&
                                <Button variant="warning" className={"me-2"} href="/responsible-user-trainings">Мои
                                    администрирования</Button>}
                            {currentUserRole === 'ADMIN' && <Button variant="primary" className={"me-2"}
                                                                    href="/administration">Администрирование</Button>}
                            {jwt &&
                                <Nav.Item>
                                    <Nav.Link href="/page/user" className={"p-0"}>
                                        <svg width="32" height="32" fill={svgFill} className={svgClass}
                                             viewBox={svgViewBox}>
                                            <use href="#icon-user"/>
                                        </svg>
                                    </Nav.Link>
                                </Nav.Item>
                            }
                            {!jwt && <Button href="/login">Вход/Регистрация</Button>}
                        </Nav>
                    </div>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
