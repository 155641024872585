import React, {useEffect, useMemo, useState} from 'react';
import DeleteModal from "../../DeleteModal";
import VK, {Post} from 'react-vk';
import CreateNewsFromVKInstructionModal from "./CreateNewsFromVKInstructionModal";
import NewsService from "../../../services/NewsService";
import AdministrationService from "../../../services/AdministrationService";
import {Table} from "react-bootstrap";
import CreateNews from "./CreateNews";
import {Image} from "react-bootstrap";
import EditNews from "./EditNews";

const NewsComponent = () => {
    const [news, setNews] = useState([]);
    const getNews = () => {
        AdministrationService.getNews().then((res) => {
            setNews(res.data);
        });
    }

    const newsItemTextStyles = useMemo(() => ({
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        maxWidth: '100%'
    }), []);

    useEffect(() => {
        getNews();
    }, []);
    return (
        <>
            <div className="mb-3 d-flex gap-4">
                <CreateNews/>
                <CreateNewsFromVKInstructionModal allNews={news}/>
            </div>
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                <thead>
                <tr>
                    <th style={{width: '90%'}}></th>
                    <th style={{width: '10%'}}></th>
                </tr>
                </thead>
                {news.length ?
                    <tbody>
                    {news.map((newsItem) =>
                        <tr key={`administration-news-item-${newsItem.id}`}>
                            <td>
                                {newsItem.description ?
                                    <>
                                        <div style={newsItemTextStyles} dangerouslySetInnerHTML={{__html: newsItem.description}}/>
                                        {newsItem.imageName &&
                                            <Image src={`${process.env.REACT_APP_HOST}/images/${newsItem.imageName}`}
                                                   style={{ width: '200px', height: '200px' , objectFit: 'contain'}}
                                            />
                                        }
                                    </>
                                    :
                                    <VK>
                                        <div>
                                            <Post
                                                elementId={newsItem.element_id}
                                                ownerId={newsItem.owner_id}
                                                postId={newsItem.post_id}
                                                hash={newsItem.hash}
                                            />
                                        </div>
                                    </VK>
                                }
                            </td>
                            <td>
                                <div className="d-flex">
                                    {newsItem.description && <EditNews newsItem={newsItem}/>}
                                    <DeleteModal id={newsItem.id} fnDelete={NewsService.delete}/>
                                </div>
                            </td>
                        </tr>
                    )
                    }
                    </tbody>
                    :
                    <caption className={"text-center"}>Новости не добавлены</caption>
                }
            </Table>
        </>
    );
};

export default NewsComponent;
