import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {Badge, ListGroup} from "react-bootstrap";
import AdministrationService from "../../../services/AdministrationService";
import PaginationComponent from "../../PaginationComponent";
import {formatDate7} from "../../../util/dateFormatter";

const TrainingEventsDialog = ({open, handleClose, training}) => {

    const [trainingEventsPageCfg, setTrainingEventsPageCfg] = useState({
        size: 5,
        page: 0
    })

    const [trainingEvents, setTrainingEvents] = useState([]);
    const getTrainingEvents = () => {
        const options = {
            ...trainingEventsPageCfg,
            parameterType: "Training",
            parameterId: training.id,
            liteDescription: true
        }
        AdministrationService.getTrainingEventLogs(options).then(res => {
            setTrainingEvents(res.data);
        });
    }

    useEffect(() => {
        if (open) {
            getTrainingEvents();
        }
    }, [open, trainingEventsPageCfg]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>События тренировки</DialogTitle>
            <DialogContent>
                {trainingEvents && trainingEvents.items && trainingEvents.items.length &&
                    <ListGroup as="ol">
                        {trainingEvents.items.map(event =>
                            <ListGroup.Item as="li">
                                <div className="ms-2 me-auto">
                                    <Badge bg="primary" pill>{formatDate7(event.dateTime)}</Badge>
                                    &nbsp; {event.liteDescription ? event.liteDescription : event.description}
                                </div>


                            </ListGroup.Item>
                        )}
                    </ListGroup>
                }

                <PaginationComponent data={trainingEvents}
                                     pageCfg={trainingEventsPageCfg}
                                     setPageCfg={setTrainingEventsPageCfg}
                                     changeItemsCount={false}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="secondary">Закрыть</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TrainingEventsDialog;