import React, {useState, useReducer} from "react";
import UserService from "../../services/UserService";
import {Card, FloatingLabel, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Link from '@mui/material/Link';
import {useMessage} from "../message/MessageProvider";

const initialState = {
    email: '',
    phoneNumber: '',
    lastName: '',
    firstName: '',
    middleName: '',
    birthday: '',
    password: '',
    repeatPassword: '',
    errors: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_FIELD':
            return {...state, [action.field]: action.value};
        case 'SET_ERRORS':
            return {...state, errors: action.errors};
        default:
            return state;
    }
};

const validateForm = (state) => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^9\d{9}$/;

    if (!state.email || !emailRegex.test(state.email)) errors.email = 'Введите корректный e-mail';
    if (!state.phoneNumber || !phoneRegex.test(state.phoneNumber)) errors.phoneNumber = 'Номер телефона должен начинаться с 9 и содержать 10 цифр';
    if (!state.lastName) errors.lastName = 'Фамилия - обязательное поле';
    if (!state.firstName) errors.firstName = 'Имя - обязательное поле';
    if (!state.birthday) errors.birthday = 'Дата рождения - обязательное поле';
    if (!state.password) errors.password = 'Введите пароль!';
    if (state.password !== state.repeatPassword) errors.repeatPassword = 'Пароли не совпадают!';
    if (state.password.length < 8 || !/[A-Z]/.test(state.password) || !/[a-z]/.test(state.password) || !/\d/.test(state.password)) {
        errors.password = 'Пароль должен содержать не менее 8 символов, хотя бы одну заглавную и строчную буквы и хотя бы одну цифру!';
    }
    return errors;
};

const formatPhoneNumber = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.startsWith('9')) return onlyNums;
    if (onlyNums.startsWith('7') || onlyNums.startsWith('8')) {
        return '9' + onlyNums.slice(1);
    }
    return '9' + onlyNums;
};

const RegistrationComponent = () => {
    const {processError} = useMessage();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [matches] = useState(window.matchMedia("(min-width: 768px)").matches);

    const handleChange = (e) => {
        const {name, value} = e.target;
        let formattedValue = value;
        if (name === 'phoneNumber') {
            formattedValue = formatPhoneNumber(value);
        }
        dispatch({type: 'UPDATE_FIELD', field: name, value: formattedValue});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateForm(state);
        if (Object.keys(errors).length > 0) {
            dispatch({type: 'SET_ERRORS', errors});
        } else {
            const user = {
                email: state.email,
                phoneNumber: state.phoneNumber,
                lastName: state.lastName,
                firstName: state.firstName,
                middleName: state.middleName,
                birthday: state.birthday,
                password: state.password
            };
            sendRegistrationRequest(event, user);
        }
    };

    const sendRegistrationRequest = (event, user) => {
        UserService.registration(user).then(() => {
            window.location.href = "/registration/success";
        }).catch((error) => {
            processError(error.response?.data.message ? error.response.data.message : 'Произошла ошибка. Попробуйте позже');
        });
    };

    return (
        <div className={"d-flex justify-content-center align-items-center"}>
            <Card className={"d-flex justify-content-center align-items-center " + (matches ? "w-50" : "w-100")}>
                <Card.Body className={"w-100"}>
                    <Form noValidate onSubmit={handleSubmit}
                          className={"d-flex flex-column justify-content-center align-items-center"}>
                        <h3 className="m-3 p-3">Регистрация</h3>
                        <FloatingLabel label="E-mail" className="mb-3 w-100">
                            <Form.Control required type="email" name="email" value={state.email} onChange={handleChange}
                                          isInvalid={state.errors.email}/>
                            <Form.Control.Feedback type="invalid">{state.errors.email}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel label="Номер телефона" className="mb-3 w-100">
                            <Form.Control required type="text" name="phoneNumber" value={state.phoneNumber}
                                          onChange={handleChange} isInvalid={state.errors.phoneNumber}/>
                            <Form.Control.Feedback type="invalid">{state.errors.phoneNumber}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel label="Фамилия" className="mb-3 w-100">
                            <Form.Control required type="text" name="lastName" value={state.lastName}
                                          onChange={handleChange} isInvalid={state.errors.lastName}/>
                            <Form.Control.Feedback type="invalid">{state.errors.lastName}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel label="Имя" className="mb-3 w-100">
                            <Form.Control required type="text" name="firstName" value={state.firstName}
                                          onChange={handleChange} isInvalid={state.errors.firstName}/>
                            <Form.Control.Feedback type="invalid">{state.errors.firstName}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel label="Отчество" className="mb-3 w-100">
                            <Form.Control type="text" name="middleName" value={state.middleName}
                                          onChange={handleChange}/>
                        </FloatingLabel>
                        <FloatingLabel label="Дата рождения" className="mb-3 w-100">
                            <Form.Control required type="date" name="birthday" value={state.birthday}
                                          onChange={handleChange} isInvalid={state.errors.birthday}/>
                            <Form.Control.Feedback type="invalid">{state.errors.birthday}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel label="Пароль" className="mb-3 w-100">
                            <Form.Control required type="password" name="password" value={state.password}
                                          onChange={handleChange} isInvalid={state.errors.password}
                                          autoComplete="new-password"/>
                            <Form.Control.Feedback type="invalid">{state.errors.password}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel label="Подтверждение пароля" className="mb-3 w-100">
                            <Form.Control required type="password" name="repeatPassword" value={state.repeatPassword}
                                          onChange={handleChange} isInvalid={state.errors.repeatPassword}
                                          autoComplete="new-password"/>
                            <Form.Control.Feedback type="invalid">{state.errors.repeatPassword}</Form.Control.Feedback>
                        </FloatingLabel>

                        <Button type="submit" className={"w-100 my-3"}>Зарегистрироваться</Button>

                        <small>Продолжая регистрацию, я соглашаюсь с&nbsp;
                            <Link href={`${process.env.REACT_APP_HOST}/files/rules.pdf`} target="_blank"
                                  color="inherit">
                                Правилами пользования сайтом и обработкой персональных данных</Link>
                            &nbsp;и&nbsp;
                            <Link href={`${process.env.REACT_APP_HOST}/files/offer.pdf`} target="_blank"
                                  color="inherit">
                                Публичной офертой
                            </Link>
                        </small>
                        <div>
                            <p className="mb-0 mt-2">
                                Уже есть аккаунт? <a href="/login" className="fw-bold">Войти</a>
                            </p>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default RegistrationComponent;
