import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import UserService from "../../../services/UserService";
import {Col, FloatingLabel, Form, Row} from "react-bootstrap";
import AdministrationService from "../../../services/AdministrationService";
import GameLevelService from '../../../services/GameLevelService';
import {formatDate6} from "../../../util/dateFormatter";
import {useMessage} from "../../message/MessageProvider";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";

export default function EditUserModal({user, setDataChanged}) {
    const {processError, processSuccess} = useMessage();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        UserService.getUserGameLevels(user.id).then((res) => {
            let userGameLevels = {};
            for (let gameLevel of res.data) {
                userGameLevels[gameLevel.sportType.id] = gameLevel.gameLevel.id;
            }
            setGameLevel(userGameLevels);
            setShow(true);
        });
        AdministrationService.getSportTypes().then((res) => {
            setSportTypes(res.data);
        });
        GameLevelService.getGameLevels().then((res) => {
            setGameLevels(res.data);
        });
    };

    const [sportTypes, setSportTypes] = useState([]);
    const [gameLevels, setGameLevels] = useState([]);

    const [lastName, setLastName] = useState(user ? user.lastName : null);
    const [firstName, setFirstName] = useState(user ? user.firstName : null);
    const [middleName, setMiddleName] = useState(user ? user.middleName : null);
    const [birthday, setBirthday] = useState(user ? formatDate6(user.birthday) : null);
    const [gameLevel, setGameLevel] = useState({});
    const [userRole, setUserRole] = useState(user ? user.role : "");

    const changeLastName = (event) => {
        setLastName(event.target.value);
    }

    const changeFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const changeMiddleName = (event) => {
        setMiddleName(event.target.value);
    }

    const changeBirthday = (event) => {
        setBirthday(event.target.value);
    }

    const changeGameLevel = (event) => {
        const sportType = event.target.getAttribute("sporttype");
        let newGameLevel = gameLevel;
        newGameLevel[sportType] = Number(event.target.value);
        setGameLevel(newGameLevel);
    }

    const changeRole = (event) => {
        setUserRole(event.target.value);
    }

    const saveUser = () => {
        for (let sportType of sportTypes) {
            if (!gameLevel[sportType.id] && gameLevels.length) {
                gameLevel[sportType.id] = gameLevels[0].id;
            }
        }
        const userData = {
            userId: user.id,
            lastName: lastName,
            firstName: firstName,
            middleName: middleName,
            birthday: birthday,
            gameLevels: gameLevel,
            role: userRole,
            mode: "edit"
        }

        UserService.save(userData).then(_res => {
            setDataChanged(prev => !prev);
            setShow(false);
            processSuccess('Успешно!', false);
        }).catch((error) => {
            processError(error.response.data.message, false);
        });
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (lastName && firstName && birthday && gameLevel) {
            event.preventDefault();
            saveUser();
        }
        setValidated(true);
    };

    return (
        <>
            <IconButton onClick={handleShow} title="Редактировать">
                <EditIcon/>
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Изменение пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <FloatingLabel label="Логин" className="mb-3">
                            <Form.Control type="text" defaultValue={user.login} disabled={true}/>
                        </FloatingLabel>

                        <FloatingLabel label="Email" className="mb-3">
                            <Form.Control type="text" defaultValue={user.email} disabled={true}/>
                        </FloatingLabel>

                        <FloatingLabel label="Номер телефона" className="mb-3">
                            <Form.Control type="text" defaultValue={user.phoneNumber} disabled={true}/>
                        </FloatingLabel>

                        <FloatingLabel label="Фамилия" className="mb-3">
                            <Form.Control required type="text" defaultValue={user.lastName} onChange={changeLastName}/>
                        </FloatingLabel>

                        <FloatingLabel label="Имя" className="mb-3">
                            <Form.Control required type="text" defaultValue={user.firstName}
                                          onChange={changeFirstName}/>
                        </FloatingLabel>

                        <FloatingLabel label="Отчество" className="mb-3">
                            <Form.Control type="text" defaultValue={user.middleName}
                                          onChange={changeMiddleName}/>
                        </FloatingLabel>

                        <FloatingLabel label="Дата рождения" className="mb-3">
                            <Form.Control required type="date"
                                          defaultValue={formatDate6(user.birthday)}
                                          onChange={changeBirthday}
                            />
                        </FloatingLabel>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Уровень игры:</Form.Label>
                            <Col sm={8}>
                                {sportTypes.map((sportType) =>
                                    <Row className={"mb-3"} key={sportType.id}>
                                        <Form.Label column sm={4}>{sportType.name}</Form.Label>
                                        <Col sm={8}>
                                            <Form.Select id={"gameLevel"} onChange={changeGameLevel}
                                                         sporttype={sportType.id}
                                                         defaultValue={gameLevel[sportType.id]}>
                                                {gameLevels ? gameLevels.map(level =>
                                                        <option key={level.id} value={level.id}>
                                                            {level.name}
                                                        </option>
                                                    )
                                                    :
                                                    null
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Роль: </Form.Label>
                            <Col sm={8}>
                                <Row className={"mb-3"}>
                                    <Col>
                                        <Form.Select id={"role"} onChange={changeRole} value={userRole}>
                                            <option value={"READER"}>{"Читатель (READER)"}</option>
                                            <option value={"USER"}>{"Пользователь (USER)"}</option>
                                            <option
                                                value={"RESPONSIBLE_USER"}>{"Админ тренировки (RESPONSIBLE_USER)"}</option>
                                            <option value={"ADMIN"}>{"Главный админ (ADMIN)"}</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Закрыть</Button>
                    <Button variant="primary" onClick={handleSubmit}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
