import {Alert, FloatingLabel, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import UserService from "../../services/UserService";
import Message from "../Message";
import {useLocalState} from "../../util/useLocatStorage";
import Container from "react-bootstrap/Container";

const PasswordUpdateComponent = () => {
    const [, setIsPasswordRecoveryInProgress] = useLocalState(false, "isPasswordRecoveryInProgress");

    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");

    const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);

    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    const updatePassword = () => {
        if (password !== passwordRepeat) {
            setIsMessageOpen(true);
            setMessageText("Пароли не совпадают!");
        } else if (password.length < 8 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password)) {
            setIsMessageOpen(true);
            setMessageText("Пароль должен содержать не менее 8 символов, хотя бы одну заглавную и строчную буквы и хотя бы одну цифру!");
        } else {
            UserService.saveNewPassword(code, password).then(res => {
                if (res.status === 200) {
                    if (res.data.isError) {
                        setIsMessageOpen(true);
                        setMessageText(res.data.message);
                    } else {
                        setIsMessageOpen(false);
                        setIsPasswordRecoveryInProgress(false);
                        if (res.data && res.data.message) {
                            setMessageText(res.data.message);
                        } else {
                            setMessageText("Пароль успешно изменен. Теперь вы можете войти в систему");
                        }
                        setIsSuccessMessageOpen(true);
                    }
                } else {
                    setIsMessageOpen(true);
                    if (res.data && res.data.message) {
                        setMessageText(res.data.message);
                    } else {
                        setMessageText("Произошла непредвиденная ошибка. Обратитесь к администратору");
                    }

                }
            }).catch(e => {
                setIsMessageOpen(true);
                if (e.response && e.response.data && e.response.data.message) {
                    setMessageText(e.response.data.message);
                } else if (e.message) {
                    setMessageText(e.message);
                } else {
                    setMessageText("Произошла непредвиденная ошибка. Обратитесь к администратору");
                }
            });
        }
    }

    return (
        <Container fluid={"sm"}>
            <div className={"mb-3 mt-3"}>Вам на почту был отправлен код подтверждения для восстановления пароля</div>
            <Form className={"d-flex flex-column justify-content-center align-items-center"} >
                <FloatingLabel label="Код восстановления пароля" className="w-100 mb-3">
                    <Form.Control
                        placeholder={"123456"}
                        type="text"
                        onChange={(event) => setCode(event.target.value)}/>
                </FloatingLabel>
                <FloatingLabel label="Новый пароль" className="mb-3 w-100">
                    <Form.Control required type={"password"} placeholder="*******"
                                  onChange={(event) => setPassword(event.target.value)}/>
                </FloatingLabel>
                <FloatingLabel label="Подтверждение пароля" className="mb-3 w-100">
                    <Form.Control required type={"password"} placeholder="*******"
                                  onChange={(event) => setPasswordRepeat(event.target.value)}/>
                </FloatingLabel>

                {isMessageOpen ? <Alert className={"w-100"} variant={"danger"}>{messageText}</Alert> : null}

                <Button onClick={updatePassword} className={"w-100"} disabled={!code || !password || !passwordRepeat}>Обновить
                    пароль</Button>
            </Form>

            {/*Всплывающее сообщение*/}
            {isSuccessMessageOpen ?
                <Message text={messageText} type={"success"} showOKButton={true}/> : null}
        </Container>
    );
}

export default PasswordUpdateComponent;