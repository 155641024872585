import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useCallback, useMemo, useState} from "react";
import TrainingService from "../../../services/TrainingService";
import ConfirmRepeatTrainingsDialog from "./ConfirmRepeatTrainingsDialog";
import {formatDate2} from "../../../util/dateFormatter";

export default function RepeatTrainingsModal(props) {
    const [show, setShow] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const handleClose = () => {
        setShow(false);
        setShowWarning(false);
    }
    const handleShow = useCallback(() => {
        setShow(true);
        setSelectedTrainings(props.getSelectedTrainings());
    }, [props]);

    const [weekCount, setWeekCount] = useState(1);

    const changeWeekCount = (event) => {
        setWeekCount(event.target.value);
    }

    const [selectedTrainings, setSelectedTrainings] = useState([]);

    const trainingsId = useMemo(() => {
        return Array.from(selectedTrainings, item => item.id);
    }, [selectedTrainings]);

    const [checkResultMessage, setCheckResultMessage] = useState("");

    const styleGrid = {
        display: "grid",
        gridTemplateColumns: '1fr 1fr 1fr',
        justifyItems: 'center'
    }

    /** Запрос на проверку есть ли дубликаты создаваемых тренировок */
    const checkUniqueForRepeat = useCallback(() => {
        TrainingService.checkUniqueForRepeat(trainingsId, weekCount).then(() => {
            repeatTrainings();
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                setShowWarning(true);
                setCheckResultMessage(error.response.data.message)
            }
        });
    }, [trainingsId, weekCount])

    /** Запрос на создание повторов тренировок. Отправляется массив тренировок и количество недель для повтора */
    const repeatTrainings = useCallback(() => {
        TrainingService.repeat(trainingsId, weekCount).then(() => {
            setShow(false);
            window.location.reload();
        });
    }, [trainingsId, weekCount])

    return (
        <>
            {props.buttonType === "icon" ?
                <Button onClick={handleShow} disabled={!props.selectedItems.length} variant={"secondary"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-repeat" viewBox="0 0 16 16">
                        <path
                            d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
                    </svg>
                </Button>
                :
                <button className="btn btn-primary btn-block bg-secondary border-0 mx-3" onClick={handleShow}
                        disabled={!props.selectedItems.length}>
                    Повторить тренировки
                </button>
            }

            <ConfirmRepeatTrainingsDialog open={showWarning}
                                          onClose={handleClose}
                                          repeatTrainings={repeatTrainings}
                                          message={checkResultMessage}
                                          handleClose={handleClose}
            />

            <Modal show={show} onHide={handleClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Повторение тренировок</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={styleGrid}>
                        {selectedTrainings.length
                            ?
                            selectedTrainings.map((training, index) =>
                                <div className="card text-bg-light mb-3" style={{maxWidth: "18rem"}} key={index}>
                                    <div
                                        className="card-header">{formatDate2(training.date)}</div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            <div>{"С " + training.startTime + " до " + training.endTime}</div>
                                            <div>{training.price + '₽'}</div>
                                            <div>{training.sportType.name}</div>
                                            <div>{training.minGameLevel.name}</div>
                                            <div>{training.gym.name + ' (' + training.gym.address + ')'}</div>
                                        </p>
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="weekCount" className="col-sm-3 col-form-label">Повторить на (недель): </label>
                        <div className="col-sm-2">
                            <input type="number" className="form-control" id="weekCount" defaultValue={1}
                                   min={1} onChange={changeWeekCount}/>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={checkUniqueForRepeat}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}