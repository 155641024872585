import React from "react";
import TableEntityCard from "../../TableEntityCard";
import {Alert} from "react-bootstrap";
import PaginationComponent from "../../../PaginationComponent";
import MarkTheDebtorModal from "../MarkTheDebtorModal";

/**
 * Компонент для отображения списка должников в мобильной версии с карточками.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.debtors - Массив объектов должников.
 * @param {Object} props.debtorsPage - Объект данных для текущей страницы должников.
 * @param {Object} props.debtorsPageCfg - Конфигурация пагинации.
 * @param {Function} props.setDebtorsPageCfg - Функция для обновления конфигурации пагинации.
 * @param {Function} props.setDataChanged - Функция для обновления состояния данных.
 * @returns {JSX.Element} Карточки должников с возможностью отметки оплаты и пагинацией.
 */
const DebtorsComponentMobile = ({
                                    debtors,
                                    debtorsPage,
                                    debtorsPageCfg,
                                    setDebtorsPageCfg,
                                    setDataChanged
                                }) => {
    /**
     * Формирование массива с атрибутами должников для отображения в карточке.
     * Один строковый элемент массива отображается на одной строке в карточке.
     *
     * @param {Object} user - Объект должника.
     * @returns {Array<string>} Массив строковых атрибутов для отображения.
     */
    const generateCardPropsArray = (user) => {
        return [
            user.fio,
            user.trainingStr,
            user.price
        ];
    };

    /**
     * Получение массива действий над должником в карточке.
     *
     * @param {Object} user - Объект должника.
     * @returns {Array<JSX.Element>} Массив действий для карточки.
     */
    const getUserActions = (user) => {
        return [
            <MarkTheDebtorModal debtor={user} setDataChanged={setDataChanged}/>
        ];
    };

    return (
        <div>
            {debtors.map(user =>
                <TableEntityCard
                    props={generateCardPropsArray(user)}
                    actions={getUserActions(user)}
                    key={user.id}
                />
            )}

            {!debtors.length && <Alert>Должников нет</Alert>}

            <PaginationComponent
                data={debtorsPage}
                pageCfg={debtorsPageCfg}
                setPageCfg={setDebtorsPageCfg}
                changeItemsCount={false}
                hideTotalCount={true}
            />
        </div>
    );
};

export default DebtorsComponentMobile;
