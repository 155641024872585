import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import Button from "react-bootstrap/Button";
import React, {useCallback, useState} from "react";
import Alert from "@mui/material/Alert";
import TrainingService from "../../services/TrainingService";
import {useMessage} from "../message/MessageProvider";

/**
 * Диалоговое окно для записи незарегистрированного пользователя на тренировку.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.training - Объект тренировки.
 * @param {String} props.open - Флаг, указывающий, открыто ли окно.
 * @param {Function} props.handleClose - Функция для закрытия диалогового окна.
 * @returns {JSX.Element} Компонент диалогового окна.
 */
const SubscribeUnregisteredDialog = ({training, open, handleClose}) => {
    const {processError, processSuccess} = useMessage();
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");

    /**
     * Обработчик для записи незарегистрированного пользователя на тренировку.
     */
    const subscribe = useCallback(() => {
        const options = {
            trainingId: training.id,
            firstName: firstName,
            lastName: lastName
        };
        TrainingService.subscribeUnregistered(options)
            .then((_r) => {
                processSuccess(`Вы успешно записали участника ${lastName} ${firstName}`, false);
                window.location.reload(); // TODO: задача на рефакторинг компонента расписания. не перезагружать страницу, а обновлять запрос
            })
            .catch((_e) => {
                processError(
                    `Произошла ошибка при записи. Попробуйте выполнить действие позже или обратитесь к администратору`,
                    false
                );
            });
    }, [training, firstName, lastName, processError, processSuccess]);

    return (
        <Dialog
            open={open === "subscribeUnregistered"}
            onClose={handleClose}
        >
            <DialogTitle>Подтверждение записи незарегистрированного участника</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Введите фамилию и имя человека, которого хотите записать
                </DialogContentText>
                <div className="d-flex gap-2 mt-3 mb-3 flex-column">
                    <TextField
                        label="Фамилия"
                        variant="standard"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-100"
                    />
                    <TextField
                        label="Имя"
                        variant="standard"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-100"
                    />
                </div>

                <Alert severity="info">
                    Нажимая кнопку Записать, Вы подтверждаете, что несете полную
                    финансовую ответственность за этого человека (текст поменять если нужно или вообще убрать)
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="secondary">Отмена</Button>
                <Button autoFocus disabled={!lastName || !firstName} onClick={subscribe}>Записать</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubscribeUnregisteredDialog;
