import {Accordion, Alert, Card} from "react-bootstrap";
import TrainingService from "../../services/TrainingService";
import React, {useState} from "react";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import openTrainingCard from "../trainingPage/util";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {IconButton} from "@mui/material";

const CurrentUserTrainingsHistory = () => {
    const [trainings, setTrainings] = useState([]);

    const getTrainingsHistory = () => {
        TrainingService.getTrainingsHistory().then(res => {
            setTrainings(res.data);
        });
    }

    return (
        <Accordion className={"mt-3"} defaultActiveKey={null}>
            <Accordion.Item>
                <Accordion.Header onClick={getTrainingsHistory}>История</Accordion.Header>
                <Accordion.Body>
                    {trainings && trainings.length ? trainings.map(training =>
                            <Card className="mb-2">
                                <Card.Body>
                                    <div>
                                        <IconButton onClick={() => openTrainingCard(training.id)}>
                                            <OpenInNewIcon/>
                                        </IconButton>
                                        {training.sportType.name} ({training.minGameLevel.name}){training.trainingType ? ' - ' + training.trainingType.name : ''}: {format(Date.parse(training.date), 'dd MMMM', {locale: ru})} ({training.startTime + ' - ' + training.endTime})
                                        - {training.gym.name}
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                        :
                        <Alert variant="info">Данных по прошедшим тренировкам не найдено</Alert>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default CurrentUserTrainingsHistory;