import React, {useEffect, useState} from 'react';
import UserService from "../../../services/UserService";
import {Table} from "react-bootstrap";
import AdministrationService from "../../../services/AdministrationService";
import {formatDate1} from "../../../util/dateFormatter";

const InactiveUsersComponent = () => {
    const [inactiveUsers, setInactiveUsers] = useState([]);

    const banUser = (userId) => {
        UserService.ban(userId).then(() => {
            window.location.reload();
        });
    }

    const activateUser = (userId) => {
        UserService.activate(userId).then(() => {
            window.location.reload();
        });
    }

    const getInactiveUsers = () => {
        AdministrationService.getInactiveUsers().then((res) => {
            setInactiveUsers(res.data);
        });
    }

    useEffect(() => {
        getInactiveUsers();
    }, []);

    return (
        <>
            {inactiveUsers && inactiveUsers.length
                ?
                <>
                    <h4>Новые пользователи:</h4>
                    <Table variant={"warning"} responsive>
                        <thead>
                        <tr className="table-active">
                            <th>#</th>
                            <th>E-mail</th>
                            <th>Номер телефона</th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Отчество</th>
                            <th>Дата рождения</th>
                            <th>Роль</th>
                            <th>Статус</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {inactiveUsers.map((user, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.lastName}</td>
                                <td>{user.firstName}</td>
                                <td>{user.middleName}</td>
                                <td>{formatDate1(user.birthday)}</td>
                                <td>{user.role}</td>
                                <td>{user.status}</td>
                                <td>
                                    <div className={"d-flex gap-1"}>
                                        <div role={"button"} onClick={() => activateUser(user.id)}
                                             style={{color: "green"}} title="Активировать пользователя">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-check-lg"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                            </svg>
                                        </div>
                                        <div role={"button"} onClick={() => banUser(user.id)}
                                             style={{color: "red"}} title="Забанить пользователя">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-x-lg"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </>
                :
                null
            }
        </>
    );
};

export default InactiveUsersComponent;
