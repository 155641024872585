import TableEntityCard from "../../TableEntityCard";
import {Alert} from "react-bootstrap";
import React, {useCallback} from "react";
import {formatDate1} from "../../../../util/dateFormatter";
import {IconButton} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

/** Заявки в карточном представлении (используется для отображении в мобильном интерфейсе)
 * @param requests Список заявок
 * @param acceptHandler Обработчик принятия заявки
 * @param rejectHandler Обработчик отклонения
 * */
const RequestsCards = ({requests, acceptHandler, rejectHandler}) => {

    /** Сформировать массив с отображаемыми в карточке полями
     * @param request Заявка
     * @return Список полей для отображения в карточке: Тип заявки, Статус, Текст заявки, Дата создания и обновления
     * */
    const generateCardPropsArray = (request) => {
        return [
            request.typeDescription,
            request.statusDescription,
            request.description,
            `Создана ${formatDate1(request.createdDate)}. Обновлена ${formatDate1(request.updatedDate)}`
        ]
    }

    /** Получить действия в карточке заявки
     * @param request Заявка
     * @return Действия в карточке: отменить и принять (если статус Новый)
     * */
    const getUserActions = (request) => {
        return request.status === 'NEW' ? [
            <IconButton onClick={() => acceptHandler(request.id)} title="Принять">
                <CheckIcon/>
            </IconButton>,
            <IconButton color="error" onClick={() => rejectHandler(request.id)} title="Отклонить">
                <CloseIcon/>
            </IconButton>
        ] : [];
    }

    /** Получить цвета границы карточек
     * @param status Статус заявки
     * @return Цвет границы (NEW - прозрачный, ACCEPTED - зеленый, REJECTED - красный)
     * */
    const getBorderColor = useCallback((status) => {
        let borderColor = 'transparent';
        switch (status) {
            case 'ACCEPTED':
                borderColor = 'success';
                break;
            case 'REJECTED':
                borderColor = 'danger';
                break;
        }
        return borderColor;
    }, []);

    return (
        <>
            {requests.map(request =>
                <TableEntityCard props={generateCardPropsArray(request)}
                                 actions={getUserActions(request)}
                                 borderColor={getBorderColor(request.status)}
                                 key={request.id}
                />
            )}

            {!requests.length && <Alert>Заявки не найдены</Alert>}
        </>
    )
}

export default RequestsCards;