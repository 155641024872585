import React, {useState, useContext} from 'react';
import Message from './Message';

// Контекст для управления ошибками
const ErrorContext = React.createContext({
    error: {isError: true, message: '', show: false, showOkButton: true},
    processError: () => {
    },
    processSuccess: () => {
    },
    handleErrorClose: () => {
    }
});

/** Компонент-провайдер для контекста информационных сообщений (ошибка, успешно)*/
export const MessageProvider = ({children}) => {
    const [error, setError] = useState({isError: true, message: '', show: false, showOkButton: true});

    const processError = (message, showOkButton) => {
        setError({isError: true, message, show: true, showOkButton});
    };

    const processSuccess = (message, showOkButton) => {
        setError({isError: false, message, show: true, showOkButton});
    };

    const handleErrorClose = () => {
        setError({isError: true, message: '', show: false, showOkButton: true});
    };

    return (
        <ErrorContext.Provider value={{error, processError, processSuccess, handleErrorClose}}>
            {children}
            {error.show && (
                <Message
                    isError={error.isError}
                    message={error.message}
                    showOkButton={error.showOkButton}
                    onClose={handleErrorClose}
                />
            )}
        </ErrorContext.Provider>
    );
};

// Создаем хук для использования контекста сообщений
export const useMessage = () => useContext(ErrorContext);