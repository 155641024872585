import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";
import TrainingService from "../../../services/TrainingService";
import {formatDate3} from "../../../util/dateFormatter";

const CloseTrainingDialog = ({training, open, handleClose, customizeMessage}) => {

    const closeTraining = () => {
        handleClose();
        TrainingService.cancelTraining(training.id).then((res) => {
            customizeMessage(true, res.data.message ? res.data.message : "Тренировка отменена");
        }).catch(e => {
            let messageText = "Произошла непредвиденная ошибка. Обратитесь к администратору";
            if (e.response && e.response.data && e.response.data.message) {
                messageText = e.response.data.message;
            } else if (e.message) {
                messageText = e.message;
            }
            customizeMessage(true, messageText, "danger");
        });
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Отмена тренировки</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {training.sportType.name} - <mark>отменить тренировку</mark>
                    <br/>
                    {'Адрес: ' + training.gym.name}
                    <br/>
                    {"Дата: " + formatDate3(training.date)}
                    <br/>
                    {"Время: с " + training.startTime + " до " + training.endTime}
                    <br/>
                    <br/>
                    <strong>Вы уверены?</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"secondary"}>Нет</Button>
                <Button onClick={closeTraining} autoFocus>Да. Отменить</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CloseTrainingDialog;