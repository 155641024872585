import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import Message from "./Message";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {IconButton} from "@mui/material";

export default function DeleteModal({id, fnDelete, title, text}) {
    const [show, setShow] = useState(false);

    const deleteRecord = function () {
        setShow(false);
        setIsMessageOpen(true);

        fnDelete(id).then(res => {
            setMessageText(res.data.message ? res.data.message : "Запись успешно удалена!");
        }).catch(e => {
            let messageText = "Произошла непредвиденная ошибка. Обратитесь к администратору";
            if (e.response && e.response.data && e.response.data.message) {
                messageText = e.response.data.message;
            } else if (e.message) {
                messageText = e.message;
            }

            setMessageType("danger");
            setMessageText(messageText);
            setShowOKButtonInMessage(true);
        });
    }

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, setMessageType] = useState("success");
    const [showOKButtonInMessage, setShowOKButtonInMessage] = useState(false);

    return (
        <>
            <IconButton
                color="error"
                size="small"
                onClick={() => setShow(true)}

                title={title ? title : "Удаление записи"}
            >
                <DeleteOutlineIcon/>
            </IconButton>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title ? title : "Удаление записи"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{text ? text : "Вы хотите удалить запись, уверены?"}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Отмена</Button>
                    <Button variant="primary" onClick={deleteRecord}>OK</Button>
                </Modal.Footer>
            </Modal>

            {/*Всплывающее сообщение*/}
            {isMessageOpen ?
                <Message text={messageText} type={messageType} showOKButton={showOKButtonInMessage}/> : null}
        </>
    );
}