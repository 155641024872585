import React from "react";
import {Table} from "react-bootstrap";
import MarkTheDebtorModal from "./MarkTheDebtorModal";

/**
 * Компонент для отображения списка должников с возможностью отметки оплаты.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.debtors - Массив объектов должников.
 * @param {Function} props.setDataChanged - Функция для обновления состояния данных.
 * @returns {JSX.Element} Таблица с должниками и возможностью отметить оплату.
 */
const DebtorsComponent = ({debtors, setDataChanged}) => {
    return (
        <Table responsive>
            <thead>
            <tr className="table-active">
                <th>ФИО должника</th>
                <th>Тренировка</th>
                <th>Долг</th>
                <th></th>
            </tr>
            </thead>
            {debtors.length ? (
                <tbody>
                {debtors.map(debtor => (
                    <tr key={debtor.id}>
                        <td>{debtor.fio}</td>
                        <td>{debtor.trainingStr}</td>
                        <td>{debtor.price}</td>
                        <td>
                            <MarkTheDebtorModal debtor={debtor} setDataChanged={setDataChanged}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            ) : (
                <caption className="text-center">Должников нет</caption>
            )}
        </Table>
    );
}

export default DebtorsComponent;
