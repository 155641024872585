import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";
import TrainingService from "../../services/TrainingService";
import {formatDate3} from "../../util/dateFormatter";

const TrainingCancelComponent = ({training, open, onClose, showMessage}) => {
    const finishTraining = () => {
        TrainingService.cancelTraining(training.id).then((res) => {
            onClose();
            showMessage(true, res.data.message ? res.data.message : "Тренировка отменена");
        }).catch(e => {
            let messageText = "Произошла непредвиденная ошибка. Обратитесь к администратору";
            if (e.response && e.response.data && e.response.data.message) {
                messageText = e.response.data.message;
            } else if (e.message) {
                 messageText = e.message;
            }
            showMessage(true, messageText, "danger");
        });
    }

    return (<>
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>Отмена тренировки</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {training.sportTypeStr} - <mark>тренировка не состоялась</mark>
                    <br/>
                    {training.trainingTypeStr ? training.trainingTypeStr : ''}
                    <br/>
                    {'Адрес: ' + training.gymStr}
                    <br/>
                    {"Дата: " + formatDate3(training.date)}
                    <br/>
                    {"Время: с " + training.startTime + " до " + training.endTime}
                    <br/>
                    <br/>
                    <strong>Вы уверены?</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={"secondary"}>Нет</Button>
                <Button autoFocus onClick={finishTraining}>Да. Отменить</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default TrainingCancelComponent;