import React, {useEffect, useState} from 'react';
import {Alert} from "react-bootstrap";
import AdministrationService from "../../../../services/AdministrationService";
import CreateOrEditGymModal from "../CreateOrEditGymModal";
import {globalProps} from "../../../../config";
import DeleteModal from "../../../DeleteModal";
import TableEntityCard from "../../TableEntityCard";
import GymService from "../../../../services/GymService";

const GymsComponentMobile = () => {
    const [gyms, setGyms] = useState([]);
    const getGyms = () => {
        AdministrationService.getGyms().then((res) => {
            setGyms(res.data);
        });
    }

    useEffect(() => {
        getGyms();
    }, []);


    const generateCardPropsArray = (gym) => {
        return [
            gym.name,
            gym.address,
            gym.active ? "Активный" : "Не активный"
        ]
    }

    const getUserActions = (gym) => {
        return [
            <CreateOrEditGymModal gym={gym} mode={globalProps.mode.edit}/>,
            <DeleteModal id={gym.id} fnDelete={GymService.delete}/>
        ]
    }

    return (
        <>
            <div className="mb-3">
                <CreateOrEditGymModal mode={globalProps.mode.create}/>
            </div>
            {gyms.map(gym =>
                <TableEntityCard props={generateCardPropsArray(gym)}
                                 actions={getUserActions(gym)}
                                 borderColor={gym.active ? 'transparent' : 'danger'}
                                 key={gym.id}
                />
            )}

            {!gyms.length && <Alert>Залы не найдены</Alert>}
        </>
    );
};

export default GymsComponentMobile;
