import {Card} from "react-bootstrap";
import UserGameLevelComponent from "../UserGameLevel";
import React, {useEffect, useMemo, useState} from "react";
import UserService from "../../../services/UserService";
import ChangeGameLevelRequestModal from "./ChangeGameLevelRequestModal";

/** Карточка уровней игры текущего пользователя*/
const UserGameLevelCard = () => {
    const [userGameLevels, setUserGameLevels] = useState([]);

    const emptyGameLevelsStr = useMemo(() =>
            'Ваши уровни игры еще не заданы. Отправьте заявку администраторам для определения уровня игры'
        , []);
    const cardTitle = useMemo(() => 'Ваши уровни игры', []);

    useEffect(() => {
        UserService.getCurrentUserGameLevels().then((res) => {
            setUserGameLevels(res.data);
        });
    }, []);

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>{cardTitle}</Card.Title>
                    <Card.Text>
                        {
                            userGameLevels.length
                                ?
                                userGameLevels.map(
                                    gameLevel => <UserGameLevelComponent userGameLevel={gameLevel} key={gameLevel.id}/>
                                )
                                :
                                emptyGameLevelsStr
                        }
                    </Card.Text>

                    <ChangeGameLevelRequestModal/>
                </Card.Body>
            </Card>
        </>
    )
}

export default UserGameLevelCard;