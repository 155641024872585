import React, {useCallback, useEffect, useState} from 'react';
import AllUsersComponent from "./AllUsersComponent";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DebtorsComponent from "./DebtorsComponent";
import AllUsersComponentMobile from "./mobileComponents/AllUsersComponentMobile";
import DebtorsComponentMobile from "./mobileComponents/DebtorsComponentMobile";
import AdministrationService from "../../../services/AdministrationService";

/** Значения по умолчанию для фильтров */
const initialFilters = {
    name: "",
    role: "",
    status: ""
};

/**
 * Главный компонент для управления пользователями.
 *
 * @returns {JSX.Element} Компонент для отображения пользователей в разных вкладках.
 */
const UsersMain = () => {
    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 479px)").matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 479px)");
        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addEventListener("change", handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => mediaQuery.removeEventListener("change", handleResize);
    }, []);

    const [dataChanged, setDataChanged] = useState(false);
    const [usersPage, setUsersPage] = useState([]);
    const [allUsersPageCfg, setAllUsersPageCfg] = useState({
        size: 10,
        page: 0
    });

    const [filters, setFilters] = useState(initialFilters);

    /**
     * Фильтрация пользователей по заданной опции.
     *
     * @param {string} value - Значение фильтра.
     * @param {string} filterType - Тип фильтра (name, role, status).
     * @param {Object} [allFilters] - Все фильтры, если фильтруем по всем параметрам.
     */
    const filterByOption = (value, filterType, allFilters) => {
        const newCfg = {
            ...allUsersPageCfg,
            page: 0
        };
        setAllUsersPageCfg(newCfg);

        let newFilters;
        if (filterType === 'all') {
            newFilters = allFilters;
        } else {
            newFilters = {...filters, [filterType]: value};
        }
        setFilters(newFilters);
    };

    /**
     * Очистка всех фильтров.
     */
    const clearFilters = () => {
        setFilters(initialFilters);
    };

    /**
     * Получение списка пользователей с сервера.
     */
    const getUsers = useCallback(() => {
        const options = {
            ...allUsersPageCfg,
            ...Object.fromEntries(
                Object.entries(filters).filter(([_, value]) => value !== "")
            )
        };
        AdministrationService.getUsers(options).then((res) => {
            setUsersPage(res.data);
        });
    }, [allUsersPageCfg, filters]);

    useEffect(() => {
        getUsers();
    }, [allUsersPageCfg, filters, dataChanged]);

    const [debtorsDataChanged, setDebtorsDataChanged] = useState(false);
    const [debtorsPage, setDebtorsPage] = useState({});

    const [debtorsPageCfg, setDebtorsPageCfg] = useState({
        size: 10,
        page: 0
    });

    /**
     * Получение списка должников
     */
    const getDebtors = useCallback(() => {
        AdministrationService.getDebtors(debtorsPageCfg).then((res) => {
            setDebtorsPage(res.data);
        });
    }, [debtorsPageCfg]);

    useEffect(() => {
        getDebtors();
    }, [debtorsPageCfg, debtorsDataChanged]);

    return (
        <>
            <Tabs
                defaultActiveKey="all"
                className="mb-3"
                justify
            >
                <Tab eventKey="all" title="Все пользователи">
                    {isMobile
                        ?
                        <AllUsersComponentMobile
                            allUsersPageCfg={allUsersPageCfg}
                            setAllUsersPageCfg={setAllUsersPageCfg}
                            usersPage={usersPage}
                            users={usersPage?.items ? usersPage.items : []}
                            filters={filters}
                            clearFilters={clearFilters}
                            filterByOption={filterByOption}
                            setDataChanged={setDataChanged}
                        />
                        : <AllUsersComponent
                            allUsersPageCfg={allUsersPageCfg}
                            setAllUsersPageCfg={setAllUsersPageCfg}
                            usersPage={usersPage}
                            users={usersPage?.items ? usersPage.items : []}
                            filters={filters}
                            clearFilters={clearFilters}
                            filterByOption={filterByOption}
                            setDataChanged={setDataChanged}
                        />
                    }
                </Tab>
                <Tab eventKey="debtors" title="Должники">
                    {isMobile
                        ?
                        <DebtorsComponentMobile debtors={debtorsPage?.items ? debtorsPage.items : []}
                                                debtorsPage={debtorsPage}
                                                debtorsPageCfg={debtorsPageCfg}
                                                setDebtorsPageCfg={setDebtorsPageCfg}
                                                setDataChanged={setDebtorsDataChanged}
                        />
                        :
                        <DebtorsComponent debtors={debtorsPage?.items ? debtorsPage.items : []}
                                          setDataChanged={setDebtorsDataChanged}
                        />
                    }
                </Tab>
            </Tabs>
        </>
    );
};

export default UsersMain;
