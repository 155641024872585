import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import React, {useRef, useState} from "react";
import {Overlay} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {globalProps} from "../../../../config";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

/**
 * Компонент фильтрации тренировок.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.initialFilters - Начальные значения фильтров.
 * @param {Function} props.filter - Функция для применения фильтров.
 * @param {Object} props.dictionariesData - Данные справочников для заполнения фильтров.
 * @returns {JSX.Element} Компонент фильтрации тренировок.
 */
const TrainingsFilter = ({initialFilters, filter, dictionariesData}) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    /**
     * Закрывает поповер.
     */
    const closeHandle = () => {
        setShow(false);
    }

    const [filters, setFilters] = useState({
        gymId: initialFilters.gymId,
        sportTypeId: initialFilters.sportTypeId,
        gameLevelId: initialFilters.gameLevelId,
        date: initialFilters.date,
        status: initialFilters.status
    });

    /**
     * Изменяет значение фильтра.
     *
     * @param {string} changeType - Тип изменяемого фильтра.
     * @param {string} value - Новое значение фильтра.
     */
    const changeItem = (changeType, value) => {
        const newFilters = {...filters, [changeType]: value};
        setFilters(newFilters);
    };

    /**
     * Применяет фильтры и закрывает поповер.
     */
    const filterCallback = () => {
        filter(null, 'all', filters);
        closeHandle();
    };

    return (
        <div>
            <Button variant="secondary" className="end-0" ref={target} onClick={() => setShow(!show)}>
                <FilterAltIcon fontSize="small"/>
            </Button>

            <Overlay show={show} placement="bottom" target={target.current} onHide={closeHandle} rootClose={true}>
                <Popover>
                    <Popover.Header as="h3">Фильтрация</Popover.Header>
                    <Popover.Body>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-gym">Зал</Form.Label>
                            <Form.Select id="filter-gym"
                                         value={filters.gymId}
                                         onChange={(e) => changeItem("gymId", e.target.value)}
                            >
                                <option value=""></option>
                                {dictionariesData.gyms ? dictionariesData.gyms.map((gym, index) =>
                                    <option key={index} value={gym.id}>{gym.name} ({gym.address})</option>
                                ) : null}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-sport-type">Вид спорта</Form.Label>
                            <Form.Select id="filter-sport-type"
                                         value={filters.sportTypeId}
                                         onChange={(e) => changeItem("sportTypeId", e.target.value)}
                            >
                                <option value=""></option>
                                {dictionariesData.sportTypes && dictionariesData.sportTypes.map((sportType, index) =>
                                    <option key={index} value={sportType.id}>{sportType.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-game-level">Уровень игры</Form.Label>
                            <Form.Select id="filter-game-level"
                                         value={filters.gameLevelId}
                                         onChange={(e) => changeItem("gameLevelId", e.target.value)}
                            >
                                <option value=""></option>
                                {dictionariesData.gameLevels && dictionariesData.gameLevels.map((gameLevel, index) =>
                                    <option key={index} value={gameLevel.id}>{gameLevel.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-date">Дата тренировки</Form.Label>
                            <Form.Control id="filter-date"
                                          type="date"
                                          value={filters.date}
                                          onChange={(e) => changeItem("date", e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="filter-status">Статус</Form.Label>
                            <Form.Select id="filter-status"
                                         value={filters.status}
                                         onChange={(e) => changeItem("status", e.target.value)}
                            >
                                <option value=""></option>
                                <option value="NEW">{globalProps.trainingStatus.NEW}</option>
                                <option value="COMPLETED">{globalProps.trainingStatus.COMPLETED}</option>
                                <option value="CANCELLED">{globalProps.trainingStatus.CANCELLED}</option>
                            </Form.Select>
                        </Form.Group>

                        <Button className="w-100" onClick={filterCallback}>Применить</Button>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
}

export default TrainingsFilter;
