import axios from "../axios";

class TrainingTypesService {
    save(trainingType) {
        return axios.post('trainingType/save', trainingType);
    }

    delete(trainingTypeId) {
        return axios.post('trainingType/delete', {id: trainingTypeId});
    }

    getActiveTrainingTypes() {
        return axios.get('trainingType/active');
    }
}

export default new TrainingTypesService();
