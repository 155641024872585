import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";
import TrainingService from "../../../services/TrainingService";

const DeleteAdminDialog = ({training, open, handleClose, customizeMessage}) => {

    const deleteAdmin = () => {
        handleClose();
        TrainingService.unsubscribeAdmin(training.id).then(res => {
            customizeMessage(true, "success", "Администратор удален");
        });
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Удаление администратора тренировки</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите убрать админа с этой тренировки?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
                <Button onClick={deleteAdmin} autoFocus>Убрать</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteAdminDialog;