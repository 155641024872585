import React, {useEffect, useMemo, useState} from 'react';
import NewsService from '../services/NewsService';
import VK, {Post} from 'react-vk';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import {Image} from "react-bootstrap";

/** Компонент страницы с новостями*/
const NewsComponent = () => {
    const isMobile = useMemo(() => window.matchMedia("(max-width: 479px)").matches, []);

    const [news, setNews] = useState([]);

    useEffect(() => {
        NewsService.getNews().then((res) => {
            setNews(res.data);
        });
    }, []);

    const newsImageStyle = useMemo(() => {
        return {
            width: 'auto',
            height: '400px',
            objectFit: 'contain',
            objectPosition: isMobile ? 'center' : 'left center',
            flexShrink: 0
        }
    }, [isMobile]);

    return (
        <Stack alignItems="center" gap={4}>
            {
                news.map((oneNew) =>
                    <div className={isMobile ? "w-100" : "w-75"}>
                        {
                            oneNew.description ?
                                <Card className="overflow-hidden">
                                    {oneNew.imageName ?
                                        <div className={`d-flex flex-nowrap ${isMobile ? "flex-column" : ""}`}>
                                            <Image src={`${process.env.REACT_APP_HOST}/images/${oneNew.imageName}`}
                                                   style={newsImageStyle}
                                            />
                                            <div style={{marginLeft: '1rem', flex: 1, overflow: 'hidden'}}>
                                                <NewsText text={oneNew.description}/>
                                            </div>
                                        </div>
                                        :
                                        <div className="p-3">
                                            <NewsText text={oneNew.description}/>
                                        </div>
                                    }
                                </Card>
                                :
                                <VK>
                                    <Post
                                        elementId={oneNew.element_id}
                                        ownerId={oneNew.owner_id}
                                        postId={oneNew.post_id}
                                        hash={oneNew.hash}
                                    />
                                </VK>
                        }
                    </div>
                )
            }
        </Stack>
    );
}

export default NewsComponent;

function NewsText({text}) {
    const newsItemTextStyles = useMemo(() => ({
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        maxWidth: '100%'
    }), []);

    return (
        <div className="p-2">
            <div style={newsItemTextStyles} dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    )
}
