import React, {useCallback, useEffect, useState} from "react";
import AdministrationService from "../../services/AdministrationService";
import {Card, CloseButton, Table} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PaginationComponent from "../PaginationComponent";
import {formatDate7} from "../../util/dateFormatter";

/** Значения по умолчанию для фильтров*/
const initialFilters = {
    description: "",
    dateTime: ""
}

/** Компонент журнала событий в админке*/
const EventLogComponent = () => {
    const [isMobile] = useState(
        window.matchMedia("(max-width: 479px)").matches
    )

    const [events, setEvents] = useState([]);
    const [eventsPage, setEventsPage] = useState([]);

    const [filters, setFilters] = useState(initialFilters);

    const filterByOption = (value, filterType) => {
        const newCfg = {
            ...eventPageCfg,
            page: 0
        };
        setEventPageCfg(newCfg);

        const newFilters = {...filters, [filterType]: value};
        setFilters(newFilters);
    };

    const clearFilters = () => {
        setFilters(initialFilters);
    }

    const [eventPageCfg, setEventPageCfg] = useState({
        size: 10,
        page: 0
    });

    const getEventsWithOptions = useCallback(() => {
        const options = {
            ...eventPageCfg,
            ...Object.fromEntries(
                Object.entries(filters).filter(([_, value]) => value !== "")
            )
        }
        AdministrationService.getEventLog(options).then((res) => {
            setEventsPage(res.data);
            setEvents(res.data.items);
        });
    }, [eventPageCfg, filters]);

    useEffect(() => {
        getEventsWithOptions();
    }, [eventPageCfg, filters]);

    return (
        <>
            <div className="d-flex flex-row m-3 gap-1">
                <Card className="w-75">
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">Поиск по описанию</Card.Subtitle>
                        <Form.Control value={filters.description}
                                      onChange={(e) =>
                                          filterByOption(e.target.value, 'description')
                                      }
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">Дата</Card.Subtitle>
                        <Form.Control type="date"
                                      value={filters.dateTime}
                                      onChange={(e) =>
                                          filterByOption(e.target.value, 'dateTime')
                                      }
                        />
                    </Card.Body>
                </Card>
                <CloseButton title="Очистить фильтры" onClick={clearFilters}/>
            </div>
            <Table responsive>
                <thead>
                <tr className="table-active">
                    <th>Дата</th>
                    <th>Описание</th>
                </tr>
                </thead>
                {events.length ?
                    <tbody>
                    {events.map((event, index) =>
                        <tr key={index}>
                            <td>{formatDate7(event.dateTime)}</td>
                            <td>{event.description}</td>
                        </tr>)
                    }
                    </tbody>
                    :
                    <caption className={"text-center"}>События не найдены</caption>
                }

            </Table>
            {isMobile ?
                <PaginationComponent data={eventsPage}
                                     pageCfg={eventPageCfg}
                                     setPageCfg={setEventPageCfg}
                                     changeItemsCount={false}
                                     hideTotalCount={true}
                                     maxVisiblePages={5}
                />
                :
                <PaginationComponent data={eventsPage}
                                     pageCfg={eventPageCfg}
                                     setPageCfg={setEventPageCfg}
                                     changeItemsCount={true}
                />
            }

        </>
    )
}
export default EventLogComponent;