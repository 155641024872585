import React from 'react';
import {formatDate4} from "../util/dateFormatter";

const FooterComponent = () => {
    return (
        <footer>
            <div>
                <div className="container">
                    <footer className="py-3 my-4">
                        <p className="text-center text-muted">© {formatDate4(new Date())} Башкирская лига спорта</p>
                    </footer>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;
