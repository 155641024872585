import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import CreateNewsModal from "./CreateNewsModal";
import {ListGroup} from "react-bootstrap";

export default function CreateNewsFromVKInstructionModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    return (
        <>
            <button className="btn btn-primary btn-block bg-secondary border-0" onClick={handleShow}>
                Экспорт новости из ВК
            </button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление новости из VK</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup as="ol" numbered>
                        <ListGroup.Item as="li">
                            Найдите новость, которую необходимо добавить и нажмите кнопку <b>"Поделиться"</b>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            Откроется окно состоящее из двух вкладок: "Поделиться" и "Экспортировать".
                            Нужно перейти на вкладку <b>"Экспортировать"</b>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            Найдите поле <b>"Код для вставки"</b> и скопируйте всё содержимое
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            Нажмите "Понятно" и вставьте скопированный текст в поле <b>"Код новости"</b>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отмена
                    </Button>
                    <CreateNewsModal allNews={props.allNews}/>
                </Modal.Footer>
            </Modal>
        </>
    );
}