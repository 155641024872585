import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Container from "react-bootstrap/Container";
import {ListGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import TrainingService from "../../../services/TrainingService";

const SetAdminDialog = ({training, open, handleClose, customizeMessage, allAdmins}) => {
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    const [addToParticipants, setAddToParticipants] = useState(true);

    const changeAddToParticipants = (event) => {
        setAddToParticipants(event.target.checked);
    }

    const selectUserAsAdmin = (userId) => {
        if (userId === selectedAdmin) {
            setSelectedAdmin(null);
        } else {
            setSelectedAdmin(userId);
        }
    }

    const setAdmin = () => {
        if (selectedAdmin) {
            const subscribeOptions = {
                trainingId: training.id,
                isParticipant: addToParticipants,
                isAdmin: true,
                userId: selectedAdmin
            };

            TrainingService.subscribe(subscribeOptions).then(r => {
                if (r.status === 200) {
                    customizeMessage(true, "success", "Администратор назначен");
                    handleClose();
                } else {
                    customizeMessage(true, "danger", "Произошла ошибка");
                }
            }).catch(message => {
                customizeMessage(
                    true,
                    "danger",
                    typeof message.response.data === "string" ? message.response.data : "Произошла ошибка",
                    true
                );
                handleClose();
            });
        }
    }

    return (<>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Назначение администратора тренировки</DialogTitle>
            <DialogContent>
                <Container fluid>
                    {allAdmins.length ?
                        <ListGroup as="ol" numbered className={""}>
                            {allAdmins.map(admin =>
                                <ListGroup.Item as="li" onClick={() => selectUserAsAdmin(admin.id)}
                                                variant={selectedAdmin === admin.id ? "success" : null}>
                                    {admin.lastName + " " + admin.firstName}
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                        :
                        <DialogContentText>
                            Пользователей для назначения админом не найдено
                        </DialogContentText>
                    }
                    <Form.Check defaultChecked={true}
                                type="switch"
                                label="Записать в участники"
                                onChange={changeAddToParticipants}
                                className={"mt-2"}
                    />
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
                <Button onClick={setAdmin} autoFocus disabled={!selectedAdmin}>Назначить</Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default SetAdminDialog