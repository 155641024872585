import React, {useEffect, useMemo} from 'react';
import Alert from "@mui/material/Alert";
import {AlertTitle} from "@mui/material";
import Button from '@mui/material/Button';

/** Компонент сообщения (ошибка, успешно)*/
const Message = ({isError, message, showOkButton, onClose}) => {
    useEffect(() => {
        if (!showOkButton) {
            const timer = setTimeout(onClose, 3000); // Скрыть через 3 секунды
            return () => clearTimeout(timer);
        }
    }, [showOkButton, onClose]);

    const title = useMemo(() => {
        return isError ? 'Ошибка' : 'Успешно';
    }, [isError]);

    return (
        <Alert severity={isError ? 'error' : 'success'} className="alertMessage">
            <AlertTitle>{title}</AlertTitle>
            <p>{message}</p>
            {showOkButton && <Button size="small" variant="contained" color="error" onClick={onClose}>OK</Button>}
        </Alert>
    );
};

export default Message;