import React, {useState, useEffect, useCallback} from "react";
import TrainingsComponentMobile from "./mobileComponents/TrainingsComponentMobile";
import TrainingsComponent from "./TrainingsComponent";
import AdministrationService from "../../../services/AdministrationService";
import GymService from "../../../services/GymService";
import SportTypesService from "../../../services/SportTypesService";
import GameLevelService from "../../../services/GameLevelService";
import TrainingTypesService from "../../../services/TrainingTypesService";

/**
 * Значения по умолчанию для дополнительных данных в карточке тренировки.
 * @type {Object}
 */
const initialDictionariesData = {
    gyms: [],
    activeGyms: [],
    sportTypes: [],
    activeSportTypes: [],
    gameLevels: [],
    activeGameLevels: [],
    trainingTypes: []
};

/**
 * Значения по умолчанию для фильтров.
 * @type {Object}
 */
const initialFilters = {
    gymId: "",
    sportTypeId: "",
    gameLevelId: "",
    date: "",
    status: ""
};

/**
 * Асинхронная функция для получения данных словарей.
 * Загружает и возвращает данные о залах, видах спорта, уровнях игры и видах тренировок.
 *
 * @async
 * @function
 * @returns {Promise<Object>} Объект, содержащий данные о залах, активных залах, видах спорта, активных видах спорта,
 * уровнях игры, активных уровнях игры и видах тренировок.
 */
async function getDictionariesData() {
    const result = {};
    await AdministrationService.getGyms().then((res) => {
        if (res.data && res.data.length) {
            result.gyms = res.data;
        }
    });
    await GymService.getActiveGyms().then((res) => {
        if (res.data && res.data.length) {
            result.activeGyms = res.data;
        }
    });
    await AdministrationService.getSportTypes().then((res) => {
        if (res.data && res.data.length) {
            result.sportTypes = res.data;
        }
    });
    await SportTypesService.getActiveSportTypes().then((res) => {
        if (res.data && res.data.length) {
            result.activeSportTypes = res.data;
        }
    });
    await GameLevelService.getGameLevels().then((res) => {
        if (res.data && res.data.length) {
            result.gameLevels = res.data;
        }
    });
    await GameLevelService.getActiveGameLevels().then((res) => {
        if (res.data && res.data.length) {
            result.activeGameLevels = res.data;
        }
    });
    await TrainingTypesService.getActiveTrainingTypes().then((res) => {
        if (res.data && res.data.length) {
            result.trainingTypes = res.data;
        }
    });
    return result;
}

/**
 * Компонент TrainingsMain.
 * Отображает соответствующую версию компонента тренировок в зависимости от ширины экрана.
 *
 * - Если ширина экрана меньше или равна 479px, отображается мобильная версия компонента.
 * - В противном случае отображается стандартная версия компонента.
 *
 * @returns {JSX.Element} Адаптивный компонент тренировок.
 */
const TrainingsMain = () => {
    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 479px)").matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 479px)");
        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addEventListener("change", handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => mediaQuery.removeEventListener("change", handleResize);
    }, []);

    const [dictionariesData, setDictionariesData] = useState(initialDictionariesData);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getDictionariesData();
            setDictionariesData(data);
        };

        fetchData();
    }, []);

    const [dataChanged, setDataChanged] = useState(false);
    const [trainingsPage, setTrainingsPage] = useState({});
    const [trainingPageCfg, setTrainingPageCfg] = useState({
        size: 10,
        page: 0
    });

    const [filters, setFilters] = useState(initialFilters);

    /**
     * Обновляет фильтры и сбрасывает страницу на первую.
     *
     * @param {string} value - Значение для фильтрации.
     * @param {string} filterType - Тип фильтра для обновления.
     * @param allFilters если filterType = 'all' то обновляет весь объект фильтрации
     */
    const filterByOption = (value, filterType, allFilters) => {
        const newCfg = {
            ...trainingPageCfg,
            page: 0
        };
        setTrainingPageCfg(newCfg);

        let newFilters;
        if (filterType === 'all') {
            newFilters = allFilters;
        } else {
            newFilters = {...filters, [filterType]: value};
        }
        setFilters(newFilters);
    };

    /**
     * Сбрасывает все фильтры к значениям по умолчанию.
     */
    const clearFilters = () => {
        setFilters(initialFilters);
    };

    /**
     * Получает тренировки с учётом текущих фильтров и настроек страницы.
     */
    const getTrainingsWithOptions = useCallback(() => {
        const options = {
            ...trainingPageCfg,
            ...Object.fromEntries(
                Object.entries(filters).filter(([_, value]) => value !== "")
            )
        };
        AdministrationService.getTrainings(options).then((res) => {
            setTrainingsPage(res.data);
        });
    }, [trainingPageCfg, filters]);

    useEffect(() => {
        getTrainingsWithOptions();
    }, [dataChanged, filters, trainingPageCfg]);

    useEffect(() => {
        setSelectedItems([]);
    }, [trainingPageCfg, filters]);

    /** @type {Array} Список выбранных тренировок. */
    const [selectedItems, setSelectedItems] = useState([]);

    /**
     * Возвращает выбранные тренировки.
     *
     * @returns {Array} Список выбранных тренировок.
     */
    const getSelectedTrainings = useCallback(() => {
        return trainingsPage?.items ? trainingsPage.items.filter((training) => selectedItems.indexOf(training.id) >= 0) : []
    }, [selectedItems, trainingsPage]);

    /**
     * Обрабатывает выбор всех тренировок.
     *
     * @param {Object} event - Событие выбора.
     */
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = trainingsPage?.items.map((n) => n.id);
            setSelectedItems(newSelected);
            return;
        }
        setSelectedItems([]);
    };

    /**
     * Проверяет, выбрана ли тренировка.
     *
     * @param {string} id - Идентификатор тренировки.
     * @returns {boolean} True, если тренировка выбрана.
     */
    const isSelected = (id) => selectedItems.indexOf(id) !== -1;

    /**
     * Обрабатывает клик по тренировке.
     *
     * @param {Object} event - Событие клика.
     * @param {string} id - Идентификатор тренировки.
     */
    const handleClick = (event, id) => {
        const selectedIndex = selectedItems.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1),
            );
        }
        setSelectedItems(newSelected);
    };

    return (
        <>
            {isMobile
                ? <TrainingsComponentMobile
                    dictionariesData={dictionariesData}
                    setDataChanged={setDataChanged}
                    filters={filters}
                    filterByOption={filterByOption}
                    clearFilters={clearFilters}
                    trainings={trainingsPage?.items ? trainingsPage.items : []}
                    trainingsPage={trainingsPage}
                    trainingPageCfg={trainingPageCfg}
                    setTrainingPageCfg={setTrainingPageCfg}
                    selectedItems={selectedItems}
                    handleSelectAllClick={handleSelectAllClick}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    getSelectedTrainings={getSelectedTrainings}
                />
                : <TrainingsComponent
                    dictionariesData={dictionariesData}
                    setDataChanged={setDataChanged}
                    filters={filters}
                    filterByOption={filterByOption}
                    clearFilters={clearFilters}
                    trainings={trainingsPage?.items ? trainingsPage.items : []}
                    trainingsPage={trainingsPage}
                    trainingPageCfg={trainingPageCfg}
                    setTrainingPageCfg={setTrainingPageCfg}
                    selectedItems={selectedItems}
                    handleSelectAllClick={handleSelectAllClick}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    getSelectedTrainings={getSelectedTrainings}
                />
            }
        </>
    );
};

export default TrainingsMain;
