import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import React, {useCallback, useMemo} from "react";
import {Tooltip} from "react-bootstrap";
import AdministrationService from "../../services/AdministrationService";

/** Взнос за тренировку: фиксированная цена или ранжирование взноса*/
const TrainingPrice = ({training}) => {

    const paymentRanking = useMemo(() => {
        return AdministrationService.parsePaymentRankingStr(training.paymentRanking);
    }, [training]);

    const priceTooltip = useMemo((props) => {
        let priceTooltipStr = training.paymentRanking ? 'от ' + training.minParticipants + ' до ' : '';
        if (training.paymentRanking) {
            for (let i = 0; i < paymentRanking.length; i++) {
                priceTooltipStr += paymentRanking[i].toCount + ' - ' + paymentRanking[i].price + '₽\n';
                if (i !== paymentRanking.length - 1) {
                    const nextRankingCount = Number(paymentRanking[i].toCount) + 1;
                    priceTooltipStr += 'от ' + nextRankingCount + ' до ';
                }
            }
        }
        return <Tooltip id={training.id + '-price-tooltip'} {...props} style={{whiteSpace: 'pre'}}>
            {priceTooltipStr}
        </Tooltip>;
    }, [training]);

    const getMinPriceInRanking = useCallback(() => {
        return paymentRanking[paymentRanking.length - 1] ? paymentRanking[paymentRanking.length - 1].price : 0;
    }, [training]);

    return (
        <div className="ms-auto mb-2 text-muted d-flex gap-1 align-items-baseline">
            {training.hasPaymentRanking && <span>от </span>}
            <Card.Subtitle
                className="price">{training.hasPaymentRanking ? getMinPriceInRanking() : training.price}</Card.Subtitle>
            {training.hasPaymentRanking &&
                <OverlayTrigger
                    placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={priceTooltip}
                >
                    <Button variant="link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-info-circle" viewBox="0 0 16 16">
                            <path
                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </Button>
                </OverlayTrigger>
            }
        </div>
    )
}

export default TrainingPrice;