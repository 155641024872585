import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";

/** Диалог с уведомлением о наличии повторов тренировок
 * @param open показать ли диалог
 * @param handleClose обработчик отмены действия - закрытие окон
 * @param message сообщение с уведомлением о дубликатах
 * @param repeatTrainings фнкция создания повторов тренировок
 * */
const ConfirmRepeatTrainingsDialog = ({open, handleClose, message, repeatTrainings}) => {
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Уведомление о повторах</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
            <Button onClick={repeatTrainings} autoFocus>Ок</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmRepeatTrainingsDialog;