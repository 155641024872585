import React, {useState} from "react";
import TrainingService from "../../services/TrainingService";
import {Accordion, ListGroup, Spinner, useAccordionButton} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useLocalState} from "../../util/useLocatStorage";
import UnsubscribeParticipantDialog from "./UnsubscribeParticipantDialog";

/**
 * Компонент для отображения списка участников тренировки в виде аккордеона.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.training - Объект тренировки.
 * @returns {JSX.Element} Компонент аккордеона с участниками.
 */
const TrainingParticipantsAccordion = ({training}) => {
    const [isParticipantsLoaded, setIsParticipantsLoaded] = useState(false);
    const [currentUserId] = useLocalState({}, "currentUserId");
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const [baseTrainingParticipants, setBaseTrainingParticipants] = useState([]);
    const [reserveTrainingParticipants, setReserveTrainingParticipants] = useState([]);

    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [openUnsubscribeDialog, setOpenUnsubscribeDialog] = useState(false);

    /**
     * Загружает участников тренировки и обновляет состояния.
     */
    function getTrainingParticipants() {
        TrainingService.getTrainingParticipants(training.id).then((res) => {
            if (res.data.base.length) {
                setBaseTrainingParticipants(res.data.base);
            }
            if (res.data.reserve.length) {
                setReserveTrainingParticipants(res.data.reserve);
            }
            setIsParticipantsLoaded(true);
        });
    }

    /**
     * Закрывает диалоговое окно отписки участника.
     */
    const handleUnsubscribeDialogClose = () => {
        setOpenUnsubscribeDialog(false);
    };

    /**
     * Открывает диалоговое окно отписки участника.
     *
     * @param {Object} participant - Объект участника для отписки.
     */
    const handleUnsubscribeDialogOpen = (participant) => {
        setSelectedParticipant(participant);
        setOpenUnsubscribeDialog(true);
    };

    /**
     * Кастомная кнопка для аккордеона, которая загружает участников при нажатии.
     *
     * @param {Object} props - Свойства компонента.
     * @param {React.ReactNode} props.children - Дочерние элементы.
     * @param {string} props.eventKey - Ключ события для аккордеона.
     * @returns {JSX.Element} Кнопка для аккордеона.
     */
    function CustomToggle({children, eventKey}) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            getTrainingParticipants();
        });

        return (
            <Button variant="link" onClick={decoratedOnClick}>{children}</Button>
        );
    }

    return (
        <>
            <Accordion>
                <CustomToggle eventKey={'myTraining' + training.id}>
                    {'Участников: ' + training.currentParticipantsCount + '/' + training.maxParticipants}
                </CustomToggle>
                <Accordion.Collapse eventKey={'myTraining' + training.id}>
                    <>
                        {!isParticipantsLoaded && <Spinner animation="grow" variant="primary"/>}
                        {training.trainingAdmin && (
                            <p>
                                <u>Администратор: {training.trainingAdmin.lastName} {training.trainingAdmin.firstName}</u>
                            </p>
                        )}
                        <div>
                            <ListGroup numbered>
                                {baseTrainingParticipants.length ? (
                                    baseTrainingParticipants.map((participant) => (
                                        <ListGroup.Item key={participant.id}>
                                            {`${participant.name} ${participant.addedBy ?
                                                '(Записал ' + participant.addedBy.lastName + ' ' +
                                                participant.addedBy.firstName + ')' : ''}`}
                                            {(participant.addedBy && (participant.addedBy.id === currentUserId || currentUserRole === 'ADMIN')) && (
                                                <IconButton
                                                    title="Отписать участника"
                                                    onClick={() => handleUnsubscribeDialogOpen(participant)}
                                                >
                                                    <CloseIcon color="error"/>
                                                </IconButton>
                                            )}
                                        </ListGroup.Item>
                                    ))
                                ) : (
                                    <div>Участников еще нет</div>
                                )}
                            </ListGroup>
                        </div>
                        {reserveTrainingParticipants.length ? (
                            <div>
                                Резерв:
                                <ListGroup numbered>
                                    {reserveTrainingParticipants.map((participant) => (
                                        <ListGroup.Item key={participant.id}>
                                            {`${participant.name} ${participant.addedBy ?
                                                '(Записал ' + participant.addedBy.lastName + ' ' +
                                                participant.addedBy.firstName + ')' : ''}`}
                                            {(participant.addedBy && (participant.addedBy.id === currentUserId || currentUserRole === 'ADMIN')) && (
                                                <IconButton
                                                    title="Отписать участника"
                                                    onClick={() => handleUnsubscribeDialogOpen(participant)}
                                                >
                                                    <CloseIcon color="error"/>
                                                </IconButton>
                                            )}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        ) : null}
                        <div className="mt-2">Минимум - {training.minParticipants}</div>
                    </>
                </Accordion.Collapse>
            </Accordion>

            <UnsubscribeParticipantDialog
                open={openUnsubscribeDialog}
                handleClose={handleUnsubscribeDialogClose}
                training={training}
                participant={selectedParticipant}
            />
        </>
    );
}

export default TrainingParticipantsAccordion;
