import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useCallback, useEffect, useState} from "react";
import TrainingService from "../../../services/TrainingService";
import {globalProps} from "../../../config";
import {Col, FloatingLabel, Form, Row} from "react-bootstrap";
import AdministrationService from "../../../services/AdministrationService";
import PaymentRanking from "./PaymentRanking";
import {useMessage} from "../../message/MessageProvider";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {formatDate6} from "../../../util/dateFormatter";

/* Значения по умолчанию для тренировки**/
const initialTrainingProps = {
    sportType: '',
    gym: '',
    date: formatDate6(new Date()),
    startTime: '20:00',
    endTime: '22:00',
    price: '200',
    maxParticipants: '15',
    minParticipants: '10',
    gameLevel: '',
    trainingType: '',
    hasPaymentRanking: false,
    paymentRanking: '',
    allowUnregisteredSignup: false,
};

/** Форма редактирования / создания тренировки
 * @param training объект тренировки (пустой если это форма создания)
 * @param mode режим открытия формы (редактирование или создание)
 * @param createButtonType Тип кнопки создания (если icon - то иконка)
 * @param setDataChanged Функция для обновления данных в таблице тренировок
 * @param dictionariesData Дополнительные данные для формы
 *
 * */
export default function CreateOrEditTrainingModal({
                                                      training,
                                                      mode,
                                                      createButtonType,
                                                      setDataChanged,
                                                      dictionariesData
                                                  }) {
    const {processError, processSuccess} = useMessage();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const [trainingProps, setTrainingProps] = useState(initialTrainingProps);

    const changeTrainingData = (field, value) => {
        setTrainingProps((prevData) => ({...prevData, [field]: value}));
    };

    useEffect(() => {
        if (training && show) {
            setTrainingProps({
                sportType: training.sportType?.id,
                gym: training.gym?.id,
                date: formatDate6(new Date(training.date)),
                startTime: training.startTime,
                endTime: training.endTime,
                price: training.price,
                maxParticipants: training.maxParticipants,
                minParticipants: training.minParticipants,
                gameLevel: training.minGameLevel?.id,
                trainingType: training.trainingType?.id,
                hasPaymentRanking: !!training.hasPaymentRanking,
                paymentRanking: training.paymentRanking,
                allowUnregisteredSignup: training.allowUnregisteredSignup
            })
        }
    }, [show]);

    useEffect(() => {
        const defaultData = {};
        if (dictionariesData.activeGyms && dictionariesData.activeGyms.length) {
            defaultData.gym = dictionariesData.activeGyms[0].id;
        }
        if (dictionariesData.activeSportTypes && dictionariesData.activeSportTypes.length) {
            defaultData.sportType = dictionariesData.activeSportTypes[0]?.id;
        }
        if (dictionariesData.activeGameLevels && dictionariesData.activeGameLevels.length) {
            defaultData.gameLevel = dictionariesData.activeGameLevels[0].id;
        }
        if (dictionariesData.trainingTypes && dictionariesData.trainingTypes.length) {
            defaultData.trainingType = dictionariesData.trainingTypes[0].id;
        }

        const defaultTrainingProps = {
            ...trainingProps,
            ...defaultData
        }
        setTrainingProps(defaultTrainingProps);
    }, [dictionariesData]);

    const handleShow = useCallback(() => {
        setShow(true)
    }, []);

    const saveTrainingChanges = () => {
        const paymentRankingObj = AdministrationService.parsePaymentRankingStr(trainingProps.paymentRanking);
        paymentRankingObj.sort((a, b) => a.toCount > b.toCount ? 1 : -1);
        const paymentRankingStr = AdministrationService.createPaymentRanking(paymentRankingObj);
        const currentTraining = {
            gymId: trainingProps.gym,
            date: trainingProps.date,
            startTime: trainingProps.startTime,
            endTime: trainingProps.endTime,
            price: trainingProps.price,
            maxParticipants: trainingProps.maxParticipants,
            minParticipants: trainingProps.minParticipants,
            sportTypeId: trainingProps.sportType,
            gameLevelId: trainingProps.gameLevel,
            trainingTypeId: trainingProps.trainingType,
            hasPaymentRanking: trainingProps.hasPaymentRanking,
            paymentRanking: paymentRankingStr,
            allowUnregisteredSignup: trainingProps.allowUnregisteredSignup
        }

        if (mode === globalProps.mode.edit) {
            currentTraining.trainingId = training.id;
        }

        TrainingService.save(currentTraining).then(() => {
            setShow(false);
            processSuccess("Тренировка сохранена", false);
            setDataChanged(prev => !prev);
        }).catch(error => {
            let errorText = "Произошла ошибка";
            if (error.response && error.response.data) {
                if (typeof error.response.data === "string") {
                    errorText = error.response.data;
                } else if (error.response.data.message && typeof error.response.data.message === "string") {
                    errorText = error.response.data.message;
                }
            }
            processError(errorText, true);
        });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add('was-validated');
        } else {
            event.preventDefault();
            saveTrainingChanges();
        }
    };

    return (
        <>
            {mode === globalProps.mode.create ?
                createButtonType === "icon" ?
                    <Button onClick={handleShow}>
                        <ControlPointIcon/>
                    </Button>
                    :
                    <Button variant={"secondary"} onClick={handleShow}>Добавить тренировку</Button>
                :
                <IconButton onClick={handleShow} title="Редактировать">
                    <EditIcon/>
                </IconButton>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === globalProps.mode.create ? "Создание" : "Изменение"} тренировки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>
                                Вид спорта
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Select value={trainingProps.sportType}
                                             onChange={(event) =>
                                                 changeTrainingData('sportType', event.target.value)
                                             }
                                             required
                                >
                                    {dictionariesData?.activeSportTypes ? dictionariesData.activeSportTypes.map((sportType, index) =>
                                        <option key={index} value={sportType.id}>{sportType.name}</option>
                                    ) : null}
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>
                                Вид тренировки
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Select value={trainingProps.trainingType}
                                             onChange={(event) =>
                                                 changeTrainingData('trainingType', event.target.value)
                                             }
                                             required
                                >
                                    {dictionariesData?.trainingTypes ? dictionariesData.trainingTypes.map(trainingType =>
                                        <option key={`training-type-${trainingType.id}`}
                                                value={trainingType.id}>{trainingType.name}</option>
                                    ) : null}
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={1}>
                                Зал
                            </Form.Label>
                            <Col sm={11}>
                                <Form.Select value={trainingProps.gym}
                                             onChange={(event) =>
                                                 changeTrainingData('gym', event.target.value)
                                             }
                                             required
                                >
                                    {dictionariesData?.activeGyms ? dictionariesData.activeGyms.map((gym, index) =>
                                        <option key={index} value={gym.id}>{gym.name} ({gym.address})</option>
                                    ) : null}
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <FloatingLabel label="Дата" className="mb-3">
                            <Form.Control required
                                          type="date"
                                          value={trainingProps.date}
                                          onChange={(event) =>
                                              changeTrainingData('date', event.target.value)
                                          }
                            />
                        </FloatingLabel>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <FloatingLabel label="Время начала">
                                    <Form.Control required
                                                  type="time"
                                                  value={trainingProps.startTime}
                                                  onChange={(event) =>
                                                      changeTrainingData('startTime', event.target.value)
                                                  }
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <FloatingLabel label="Время окончания">
                                    <Form.Control required
                                                  type="time"
                                                  value={trainingProps.endTime}
                                                  onChange={(event) =>
                                                      changeTrainingData('endTime', event.target.value)
                                                  }
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>

                        <div className="d-flex gap-4 align-items-center">
                            <FloatingLabel label="Взнос" className="mb-3 w-25">
                                <Form.Control required
                                              type="text"
                                              value={trainingProps.hasPaymentRanking ? '' : trainingProps.price}
                                              disabled={trainingProps.hasPaymentRanking}
                                              onChange={(event) =>
                                                  changeTrainingData('price', event.target.value)
                                              }
                                />
                            </FloatingLabel>
                            <Form.Check
                                label="Использовать ранжирование"
                                type="switch"
                                className="w-75"
                                checked={trainingProps.hasPaymentRanking}
                                onChange={(event) =>
                                    changeTrainingData('hasPaymentRanking', event.target.checked)
                                }
                            />
                        </div>

                        {trainingProps.hasPaymentRanking &&
                            <PaymentRanking maxParticipants={trainingProps.maxParticipants}
                                            minParticipants={trainingProps.minParticipants}
                                            paymentRanking={trainingProps.paymentRanking}
                                            changePaymentRanking={(value) =>
                                                changeTrainingData('paymentRanking', value)
                                            }
                            />
                        }

                        <FloatingLabel label="Максимальное количество участников" className="mb-3">
                            <Form.Control required
                                          type="number"
                                          value={trainingProps.maxParticipants}
                                          onChange={(event) =>
                                              changeTrainingData('maxParticipants', event.target.value)
                                          }
                            />
                        </FloatingLabel>

                        <FloatingLabel label="Минимальное количество участников" className="mb-3">
                            <Form.Control required
                                          type="number"
                                          value={trainingProps.minParticipants}
                                          onChange={(event) =>
                                              changeTrainingData('minParticipants', event.target.value)
                                          }
                            />
                        </FloatingLabel>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>
                                Уровень игры
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Select value={trainingProps.gameLevel}
                                             onChange={(event) =>
                                                 changeTrainingData('gameLevel', event.target.value)
                                             }
                                             required
                                >
                                    {dictionariesData?.activeGameLevels ? dictionariesData.activeGameLevels.map((gameLevel, index) =>
                                        <option key={index} value={gameLevel.id}>{gameLevel.name}</option>
                                    ) : null}
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Check
                            label="Разрешать записывать незарегистрированных пользователей"
                            type="switch"
                            checked={trainingProps.allowUnregisteredSignup}
                            onChange={(event) =>
                                changeTrainingData('allowUnregisteredSignup', event.target.checked)
                            }
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Закрыть</Button>
                    <Button variant="primary" onClick={handleSubmit}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
