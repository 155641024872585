import React, {useEffect, useState} from "react";
import {globalProps} from "../../../../config";
import DeleteModal from "../../../DeleteModal";
import TableEntityCard from "../../TableEntityCard";
import {Alert} from "react-bootstrap";
import GameLevelService from "../../../../services/GameLevelService";
import CreateOrEditGameLevelModal from "../CreateOrEditGameLevelModal";

const GameLevelsComponentMobile = () => {
    const [gameLevels, setGameLevels] = useState([]);
    const getGameLevels = () => {
        GameLevelService.getGameLevels().then((res) => {
            setGameLevels(res.data);
        });
    }

    useEffect(() => {
        getGameLevels();
    }, []);

    const generateCardPropsArray = (gameLevel) => {
        return [
            gameLevel.name,
            "Приоритет: " + gameLevel.levelOrder,
            gameLevel.description,
            gameLevel.active ? "Активный" : "Не активный"
        ]
    }

    const getUserActions = (gameLevel) => {
        return [
            <CreateOrEditGameLevelModal gameLevel={gameLevel}
                                        mode={globalProps.mode.edit}/>,
            <DeleteModal id={gameLevel.id} fnDelete={GameLevelService.delete}/>
        ]
    }

    return (
        <>
            <div className="mb-3">
                <CreateOrEditGameLevelModal mode={globalProps.mode.create}/>
            </div>
            {gameLevels.map(gameLevel =>
                <TableEntityCard props={generateCardPropsArray(gameLevel)}
                                 actions={getUserActions(gameLevel)}
                                 borderColor={gameLevel.active ? 'transparent' : 'danger'}
                                 key={gameLevel.id}
                />
            )}

            {!gameLevels.length && <Alert>Уровни игры не найдены</Alert>}
        </>
    );
}

export default GameLevelsComponentMobile;