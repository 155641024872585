import {useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import TrainingService from "../../services/TrainingService";
import Alert from '@mui/material/Alert';
import Training from "./Training";
import {Box} from "@mui/material";
import TrainingButtons from "./TrainingButtons";
import {globalProps} from "../../config";
import ActionWithTrainingForAdmin from "../trainings/actions/ActionWithTrainingForAdmin";
import {useLocalState} from "../../util/useLocatStorage";

/** Страница с тренировкой. Открывается по адресу /training/[id], где id - идетификатор тренировки */
const TrainingPage = () => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");
    const {id} = useParams();

    const [training, setTraining] = useState(null);
    const [showEmptyMsg, setShowEmptyMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const trainingNotFoundMsg = useMemo(() => 'Тренировка по идентификатору не найдена', []);
    const errorMsg = useMemo(() => 'Произошла ошибка при получении тренировки', []);

    /** Получить тренировку по id тренировки*/
    const getTrainingById = useCallback(() => {
        TrainingService.getTraining(id).then((res) => {
            setShowErrorMsg(false);
            if (res.data) {
                setTraining(res.data);
                setShowEmptyMsg(false);
            } else {
                setShowEmptyMsg(true);
            }
        }).catch((_error) => {
            setShowErrorMsg(true);
        });
    }, [id]);

    useEffect(() => {
        getTrainingById();
    }, [id]);

    return (
        <>
            {training &&
                <Box className="d-flex flex-column align-items-end">
                    <TrainingButtons training={training}
                                     buttonLocation={globalProps.trainingButtonsLocation.TRAINING_PAGE}
                    />
                    <Training training={training}/>
                    {currentUserRole === 'ADMIN' && <ActionWithTrainingForAdmin training={training}/>}
                </Box>
            }
                {showEmptyMsg && <Alert severity="warning">{trainingNotFoundMsg}</Alert>}
                {showErrorMsg && <Alert severity="error">{errorMsg}</Alert>}
        </>
    )
}

export default TrainingPage;