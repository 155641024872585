import React, {useEffect, useState} from "react";
import UserService from "../../services/UserService";
import CurrentUserTraining from "../trainings/CurrentUserTraining";
import TrainingService from "../../services/TrainingService";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import UnlinkTelegramDialog from "./UnlinkTelegramDialog";
import CurrentUserTrainingsHistory from "../trainings/CurrentUserTrainingsHistory";
import {useLocalState} from "../../util/useLocatStorage";
import PasswordUpdateComponent from "./PasswordUpdateComponent";
import UserGameLevelCard from "./userGameLevel/UserGameLevelCard";
import {formatDate1} from "../../util/dateFormatter";

const UserPageComponent = () => {
    const [, setJwt] = useLocalState("", "jwt");
    // Для стилей под мобилки
    const [matches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    const [user, setUser] = useState({});
    const [currentUserTrainings, setCurrentUserTrainings] = useState([]);
    const [telegramBotLink, setTelegramBotLink] = useState('#');
    const [unlinkTelegramDialogOpen, setUnlinkTelegramDialogOpen] = useState(false);

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        setJwt(null);
        window.location.href = "/";
    }
    const [isPasswordUpdateDialogOpen, setIsPasswordUpdateDialogOpen] = useState(false);

    const changePasswordClickHandle = () => {
        setIsPasswordUpdateDialogOpen(true);
    }

    const getUserData = () => {
        UserService.getUserData().then((res) => {
            setUser(res.data);
        });
        TrainingService.getCurrentUserTrainings().then((res) => {
            setCurrentUserTrainings(res.data);
        });
        UserService.getTelegramBotUrlForLink().then(res => {
            setTelegramBotLink(res.data);
        });
    }

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <>
            <Stack direction={matches ? "horizontal" : ""} gap={3}>
                <Stack gap={3}>
                    <Card>
                        <Card.Body className={"m-2"}>
                            <Card.Title className={"d-flex align-items-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                     className="bi bi-person" viewBox="0 0 16 16">
                                    <path
                                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                </svg>
                                {user.lastName} {user.firstName}
                            </Card.Title>
                            <div className={"mx-2"}>
                                <div className={"d-flex align-items-center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-envelope" viewBox="0 0 16 16">
                                        <path
                                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                    </svg>
                                    <div className={"text-muted mx-2"}>{user.email}</div>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-phone" viewBox="0 0 16 16">
                                        <path
                                            d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                                    </svg>
                                    <div className={"text-muted mx-2"}>{user.phoneNumber}</div>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-calendar" viewBox="0 0 16 16">
                                        <path
                                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                                    </svg>
                                    <div className={"text-muted mx-2"}>{formatDate1(user.birthday)}</div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Ваш баланс</Card.Title>
                            <Card.Text>{user.numberOfPoints} бонусов</Card.Text>
                            <Button variant="link">Пополнить</Button>
                        </Card.Body>
                    </Card>

                    <UserGameLevelCard/>

                    <Card>
                        <Card.Body>
                            <Card.Title>Количество посещений</Card.Title>
                            <Card.Text>
                                В текущем месяце: {user.numberOfVisitsInCurrentMonth}
                                <br/>
                                За все время: {user.numberOfVisits}
                            </Card.Text>
                            <Button variant="link">Рейтинг посещений</Button>
                        </Card.Body>
                    </Card>
                </Stack>

                <Stack gap={3}>
                    <Stack direction={matches ? "horizontal" : ""} gap={3}>
                        <Button>Редактировать личные данные</Button>
                        <Button>Связаться с руководством</Button>
                        {!user.telegramChatId ?
                            <Button variant={"outline-success"} href={telegramBotLink} target={"_blank"}>
                                Привязать телеграм
                            </Button>
                            :
                            <Button variant={"warning"} onClick={() => setUnlinkTelegramDialogOpen(true)}>
                                Отвязать телеграм
                            </Button>
                        }
                    </Stack>

                    <Card>
                        <Card.Body>
                            <Card.Title>Ближайшие тренировки</Card.Title>
                            {currentUserTrainings.length ? currentUserTrainings.map(training => <CurrentUserTraining
                                training={training}
                                key={training.id}/>) : "Здесь будут отображаться ваши ближайшие тренировки"}

                            <CurrentUserTrainingsHistory/>
                        </Card.Body>
                    </Card>

                    <Button variant={"outline-secondary"} onClick={changePasswordClickHandle} className={"mt-2"}>
                        Сменить пароль
                    </Button>
                    <Button variant={"warning"} onClick={logout} className={"mt-2"}>
                        Выйти из аккаунта
                    </Button>
                </Stack>
            </Stack>

            <UnlinkTelegramDialog open={unlinkTelegramDialogOpen}
                                  handleClose={() => setUnlinkTelegramDialogOpen(false)}
            />

            <PasswordUpdateComponent open={isPasswordUpdateDialogOpen}
                                    handleClose={() => setIsPasswordUpdateDialogOpen(false)}/>
        </>
    )
}

export default UserPageComponent;
