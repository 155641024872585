import {FloatingLabel, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import AdministrationService from "../../../services/AdministrationService";

/** Контрол ранжирования взноса
 * @param minParticipants минимальное количество участников тренировки для ограничения ввода диапазонов участников
 * @param maxParticipants максимальное количество участников тренировки для ограничения ввода диапазонов участников
 * @param paymentRanking значения ранжирования взноса
 * @param changePaymentRanking коллбек изменения значений ранжирования
 * */
const PaymentRanking = ({minParticipants, maxParticipants, paymentRanking, changePaymentRanking}) => {

    const [rankingElements, setRankingElements] = useState([]);

    useEffect(() => {
        const newRankingElements = AdministrationService.parsePaymentRankingStr(paymentRanking);
        setRankingElements(newRankingElements);
    }, []);

    /** Добавление нового элемента ранжирования */
    const addRanking = useCallback(() => {
        const el = {
            id: (Math.random() + 1).toString(36).substring(7)
        }
        setRankingElements([...rankingElements, el])
    }, [rankingElements]);

    /** Удаление элемента ранжирования */
    const deleteElement = useCallback((id) => {
        const newRankingElements = rankingElements.filter((el) => id !== el.id);
        setRankingElements(newRankingElements);
        changeRanking(newRankingElements);
    }, [rankingElements]);

    /** Изменение цены в элменте ранжирования */
    const changeElementPrice = useCallback((e) => {
        const currentId = e.target.id;
        const newRankingElements = rankingElements.map((element) => {
            if (currentId.indexOf(element.id) >= 0) {
                return {
                    id: element.id,
                    toCount: element.toCount ? element.toCount : '',
                    price: e.target.value
                };
            } else {
                return element;
            }
        })
        setRankingElements(newRankingElements);
        changeRanking(newRankingElements);
    }, [rankingElements]);

    /** Изменение конечного количества в элменте ранжирования */
    const changeElementToCount = useCallback((e) => {
        const currentId = e.target.id;
        const newRankingElements = rankingElements.map((element) => {
            if (currentId.indexOf(element.id) >= 0) {
                return {
                    id: element.id,
                    toCount: e.target.value,
                    price: element.price ? element.price : ''
                };
            } else {
                return element;
            }
        });
        setRankingElements(newRankingElements);
        changeRanking(newRankingElements);
    }, [rankingElements]);

    /** Формирование строки общего элемента ранжирования, вызов функции сохранения в родительском контроле */
    const changeRanking = useCallback((newRankingElements) => {
        changePaymentRanking(AdministrationService.createPaymentRanking(newRankingElements));
    }, []);

    return (
        <>
            {rankingElements && rankingElements.map((element) =>
                <div key={element.id} className="d-flex gap-4 align-items-center">
                    <FloatingLabel label="Взнос" className="mb-3 w-50">
                        <Form.Control required
                                      type="text"
                                      onChange={changeElementPrice}
                                      value={element.price}
                                      id={element.id}
                        />
                    </FloatingLabel>

                    <FloatingLabel label="До (кол-во)" className="mb-3 w-50">
                        <Form.Control required
                                      type="number"
                                      min={minParticipants}
                                      max={maxParticipants}
                                      onChange={changeElementToCount}
                                      value={element.toCount}
                                      id={element.id}
                        />
                    </FloatingLabel>

                    <Button variant="link" className="mb-3 h-50" title="Удалить"
                            onClick={() => deleteElement(element.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red"
                             className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                    </Button>
                </div>
            )}

            <Button variant="outline-secondary" className="mb-3 w-25 h-50" onClick={addRanking}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg>
                условие
            </Button>
        </>

    )
}

export default PaymentRanking;