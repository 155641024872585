import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React from "react";
import UserService from "../../services/UserService";

const UnlinkTelegramDialog = ({open, handleClose}) => {

    const unLinkTelegram = () => {
        UserService.unLinkTelegram().then(() => {
            window.location.reload();
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>Отвязать телеграм</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы хотите отвязать телеграм от вашего аккаунта. После выполнения этого действия
                    Вы перестанете получать уведомления
                    <br/>
                    <strong>Вы уверены?</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"secondary"}>Нет</Button>
                <Button autoFocus onClick={unLinkTelegram}>Да</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UnlinkTelegramDialog;