import Button from "react-bootstrap/Button";
import React, {useCallback, useMemo, useState} from "react";
import Modal from "react-bootstrap/Modal";
import TrainingService from "../../../services/TrainingService";
import {useMessage} from "../../message/MessageProvider";

/** Окно множественного удаления тренировок
 * @param selectedItems массив с id выбранных элементов
 * @param buttonType тип кнопки. если иконка - преедается icon
 * @param getSelectedTrainings метод получения выбранных тренировок по id
 * @param setDataChanged метод обновления данных тренировки
 * */
const MultipleTrainingsDeleteModal = ({selectedItems, buttonType, getSelectedTrainings, setDataChanged}) => {
    const {processError, processSuccess} = useMessage();
    const [show, setShow] = useState(false);

    const handleClose = useCallback(() => {
        setShow(false);
    }, []);

    const handleShow = useCallback(() => {
        setShow(true);
    }, []);

    const trainingsId = useMemo(() => {
        return Array.from(getSelectedTrainings(), item => item.id);
    }, [selectedItems]);

    const deleteTrainings = useCallback(() => {
        TrainingService.multipleDelete(trainingsId).then(_res => {
            setShow(false);
            processSuccess('Тренировки удалены!', false);
            setDataChanged(prev => !prev);
        }).catch(error => {
            setShow(false);
            processError(error.response.data.message, true);
        });
    }, [trainingsId]);

    return (
        <>
            {buttonType === "icon" ?
                <Button onClick={handleShow} disabled={!selectedItems.length} variant={"secondary"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-trash3" viewBox="0 0 16 16">
                        <path
                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                    </svg>
                </Button>
                :
                <button className="btn btn-primary btn-block bg-secondary border-0 mx-3" onClick={handleShow}
                        disabled={!selectedItems.length}>
                    Удалить
                </button>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление тренировок</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Удалить выбранные тренировки?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={deleteTrainings}>
                        Удалить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MultipleTrainingsDeleteModal;