import Button from "react-bootstrap/Button";
import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import {Alert, InputGroup} from "react-bootstrap";
import TrainingService from "../../../services/TrainingService";
import {formatDate1} from "../../../util/dateFormatter";

/** Модальное окно для выгрузки отчета по тренировкам
 * Выбирается начальная и конечная даты для построения отчета по тренировкам в этом периоде
 * @result excel файл с построенным отчетом
 * */
const DownloadReport = ({buttonType}) => {
    const [show, setShow] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /**Функция с запросом на получение отчета по тренировкам. В запросе проставляется responseType: 'blob'*/
    const downloadReport = () => {
        const formattedStartDate = formatDate1(startDate);
        const formattedEndDate = formatDate1(endDate);
        TrainingService.downloadReport(formattedStartDate, formattedEndDate).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Отчет с ${formattedStartDate} по ${formattedEndDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setStartDate("");
            setEndDate("");
            setShow(false);
        }).catch(() => {
            setErrorMessage("Отчет не составлен. Возможно, на указанный период не было тренировок, по которым можно составить отчет. Попробуйте выбрать другие даты");
            setShowErrorMessage(true);
        });
    }

    /**Начальная дата для построения отчета*/
    const [startDate, setStartDate] = useState("");
    /**Конечная дата для построения отчета*/
    const [endDate, setEndDate] = useState("");

    return (
        <>
            {/**Кнопка открытия модального окна*/}
            {buttonType === "icon" ?
                <Button onClick={() => setShow(true)} variant={"secondary"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                        <path
                            d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
                        <path
                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    </svg>
                </Button>
                :
                <Button onClick={() => setShow(true)}>Выгрузить отчет</Button>
            }


            <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Отчет по тренировкам</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="success">
                        Выберите период для построения отчета
                    </Alert>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Начальная дата</InputGroup.Text>
                        <Form.Control type="date"
                                      onChange={(e) => setStartDate(e.target.value)}
                                      max={endDate ? endDate : new Date().toJSON().slice(0, 10)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Конечная дата&nbsp;&nbsp;</InputGroup.Text>
                        <Form.Control type="date"
                                      onChange={(e) => setEndDate(e.target.value)}
                                      min={startDate ? startDate : ""}
                                      max={new Date().toJSON().slice(0, 10)}/>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={downloadReport} disabled={!startDate || !endDate}>
                        Выгрузить
                    </Button>
                </Modal.Footer>
            </Modal>

            {showErrorMessage && <Alert variant={"danger"} className={"alertMessage"}>
                <div className={"p-3"}>{errorMessage}</div>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => {
                        setShowErrorMessage(false);
                    }} variant={"outline-danger"}>
                        OK
                    </Button>
                </div>
            </Alert>
            }
        </>
    )
};

export default DownloadReport;