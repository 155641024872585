import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useMessage} from "../../message/MessageProvider";
import Stack from "@mui/material/Stack";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import GameLevelService from "../../../services/GameLevelService";
import RequestsService from "../../../services/RequestsService";
import SportTypesService from "../../../services/SportTypesService";

/** Модальное окно для изменения уровня игры текущего пользователя*/
const ChangeGameLevelRequestModal = () => {
    const {processError, processSuccess} = useMessage();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setGameLevel('');
        setSportType('');
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const [requiredMessage, setRequiredMessage] = useState('');

    const sendRequestBtnTitle = useMemo(() => 'Отправить заявку на повышение уровня', []);
    const modalTitle = useMemo(() => 'Заявка на изменение уровня игры', []);
    const closeBtnTitle = useMemo(() => 'Закрыть', []);
    const okBtnTitle = useMemo(() => 'Отправить', []);

    const [sportType, setSportType] = React.useState('');
    const handleChangeSportType = (event) => {
        setSportType(event.target.value);
    };

    const [gameLevel, setGameLevel] = React.useState('');
    const handleChangeGameLevel = (event) => {
        setGameLevel(event.target.value);
    };

    const [allSportTypes, setAllSportTypes] = React.useState([]);
    const [allGameLevels, setAllGameLevels] = React.useState([]);

    useEffect(() => {
        SportTypesService.getActiveSportTypes().then((res) => {
            if (res.data && res.data.length) {
                setAllSportTypes(res.data);
            } else {
                handleClose();
                processError('Произошла ошибка при получении видов спорта', false);
            }
        }).catch((message) => {
            handleClose();
            processError('Произошла ошибка при получении видов спорта', false);
        });
        GameLevelService.getActiveGameLevels().then((res) => {
            if (res.data && res.data.length) {
                setAllGameLevels(res.data);
            } else {
                handleClose();
                processError('Произошла ошибка при получении уровеней игры', false);
            }
        }).catch((message) => {
            handleClose();
            processError('Произошла ошибка при получении уровеней игры', false);
        });
    }, []);

    const submit = useCallback(() => {
        if (!sportType || !gameLevel) {
            setRequiredMessage('Поля обязательны для заполнения!');
        } else {
            setRequiredMessage('');
            RequestsService.createChangeGameLevelRequest(sportType, gameLevel).then(_res => {
                processSuccess('Заявка на изменение уровня игры отправлена', false);
                handleClose();
            }).catch(message => {
                processError(message.response.data.message, true);
            });
        }
    }, [sportType, gameLevel]);

    return (
        <>
            <Button variant="link" onClick={handleShow}>{sendRequestBtnTitle}</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack direction="row" spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel id="change-game-leve-select-sport-type">Вид спорта</InputLabel>
                            <Select
                                labelId="change-game-leve-select-sport-type"
                                value={sportType}
                                label="Вид спорта"
                                onChange={handleChangeSportType}
                            >
                                {allSportTypes.map((item) =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="change-game-leve-select-game-level">Уровень игры</InputLabel>
                            <Select
                                labelId="change-game-leve-select-game-level"
                                value={gameLevel}
                                label="Уровень игры"
                                onChange={handleChangeGameLevel}
                            >
                                {allGameLevels.map((item) =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Stack>
                    {requiredMessage && <div style={{color: 'red', marginTop: '0.5rem'}}>{requiredMessage}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{closeBtnTitle}</Button>
                    <Button variant="primary" onClick={submit}>{okBtnTitle}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ChangeGameLevelRequestModal;