import {Card} from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import TrainingFinishingComponent from "./TrainingFinishingComponent";
import TrainingCancelComponent from "./TrainingCancelComponent";
import Message from "../Message";
import {globalProps} from "../../config";
import TrainingMarkPaymentComponent from "./TrainingMarkPaymentComponent";

const TrainingCard = ({training, action}) => {

    const [finishTrainingOpen, setFinishTrainingOpen] = useState(false);
    const [cancelTrainingOpen, setCancelTrainingOpen] = useState(false);
    const [markPaymentOpen, setMarkPaymentOpen] = useState(false);

    const showMessage = (isMessageOpen, messageText, messageType) => {
        setIsMessageOpen(isMessageOpen);
        setMessageText(messageText);
        if (messageType) {
            setMessageType(messageType);
        }
    }

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, setMessageType] = useState("success");

    return (<>
            <Card className={"mb-2"}>
                <Card.Body>
                    <Stack direction="horizontal">
                        <div>
                            <Stack>
                                <div>{training.sportTypeStr}</div>
                                {training.trainingTypeStr && <div>{training.trainingTypeStr}</div>}
                                <div
                                    className={"fw-bold"}>{format(Date.parse(training.date), 'dd MMMM', {locale: ru}) + ' - ' + format(Date.parse(training.date), "EEEE", {locale: ru})}
                                </div>
                                <div>{training.gymStr}
                                </div>
                                <div>{training.startTime + ' - ' + training.endTime}</div>
                            </Stack>
                        </div>
                        <div className={"ms-auto"}>
                            {action === globalProps.actionsWithTraining.FINISH &&
                                <Stack gap={2}>
                                    <Button variant="outline-danger" onClick={() => setCancelTrainingOpen(true)}>
                                        Тренировка не состоялась
                                    </Button>
                                    <Button variant="success" onClick={() => setFinishTrainingOpen(true)}>
                                        Тренировка состоялась. Завершить
                                    </Button>
                                </Stack>
                            }
                            {action === globalProps.actionsWithTraining.MARK_PAYMENT &&
                                <Button variant="success" onClick={() => setMarkPaymentOpen(true)}>
                                    Отметить оплату
                                </Button>
                            }
                        </div>
                    </Stack>
                </Card.Body>
            </Card>

            {finishTrainingOpen && <TrainingFinishingComponent training={training}
                                                               open={finishTrainingOpen}
                                                               onClose={() => setFinishTrainingOpen(false)}
                                                               showMessage={showMessage}
            />}

            {cancelTrainingOpen && <TrainingCancelComponent training={training}
                                                            open={cancelTrainingOpen}
                                                            onClose={() => setCancelTrainingOpen(false)}
                                                            showMessage={showMessage}
            />}

            {markPaymentOpen && <TrainingMarkPaymentComponent training={training}
                                                            open={markPaymentOpen}
                                                            onClose={() => setMarkPaymentOpen(false)}
                                                            showMessage={showMessage}
            />}

            {/*Всплывающее сообщение*/}
            {isMessageOpen && <Message text={messageText} type={messageType} showOKButton={false}/>}
        </>
    )
}

export default TrainingCard;