import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {Alert, Form} from "react-bootstrap";
import UserService from "../../services/UserService";
import {useLocalState} from "../../util/useLocatStorage";

const PasswordRecoveryDialog = ({open, handleClose}) => {
    const [,setIsPasswordRecoveryInProgress] = useLocalState(false, "isPasswordRecoveryInProgress");

    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");

    const [email, setEmail] = useState("");
    const changeEmail = (event) => {
        setEmail(event.target.value);
    }

    const sendPasswordRecoveryRequest = () => {
        setIsMessageOpen(false);
        UserService.resetPassword(email).then(res => {
            if (res.status === 200) {
                setIsPasswordRecoveryInProgress(true);
                window.location.href = "/password/update";
            } else {
                setIsMessageOpen(true);
                if (res.data && res.data.message) {
                    setMessageText(res.data.message);
                } else {
                    setMessageText("Произошла непредвиденная ошибка. Обратитесь к администратору");
                }

            }
        }).catch(e => {
            setIsMessageOpen(true);
            if (e.response && e.response.data && e.response.data.message) {
                setMessageText(e.response.data.message);
            } else if (e.message) {
                setMessageText(e.message);
            } else {
                setMessageText("Произошла непредвиденная ошибка. Обратитесь к администратору");
            }
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>Восстановление пароля</DialogTitle>
            <DialogContent>
                <DialogContentText className={"mb-2"}>
                    На введенный e-mail будет отправлен код подтверждения для смены пароля
                </DialogContentText>
                <Form.Control type="email" onChange={changeEmail}/>
                {isMessageOpen ? <Alert className={"w-100 mt-3"} variant={"danger"}>{messageText}</Alert> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"secondary"}>Отмена</Button>
                <Button onClick={sendPasswordRecoveryRequest} disabled={!email}>Отправить</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PasswordRecoveryDialog;