import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

/* Заголовки таблицы заявок*/
const headCells = [
    {
        id: 'type-description',
        label: 'Тип заявки',
    },
    {
        id: 'status-description',
        label: 'Статус заявки',
    },
    {
        id: 'description',
        label: 'Заявка',
    },
    {
        id: 'created-date',
        label: 'Дата создания',
    },
    {
        id: 'updated-date',
        label: 'Дата обновления',
    }
];

/** Комонент шапки таблицы с заявками*/
const RequestsTableHead = () => {
    return (
        <TableHead sx={{backgroundColor: '#2a528e3d'}}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sx={{fontWeight: 'bold'}}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default RequestsTableHead;