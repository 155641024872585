import React, {useEffect, useState} from "react";
import AdministrationService from "../../../services/AdministrationService";
import {Table} from "react-bootstrap";
import DeleteModal from "../../DeleteModal";
import CreateOrEditTrainingTypesModal from "./CreateOrEditTrainingTypesModal";
import Form from "react-bootstrap/Form";
import {globalProps} from "../../../config";
import TrainingTypesService from "../../../services/TrainingTypesService";

const TrainingTypesMain = () => {
    const [trainingTypes, setTrainingTypes] = useState([]);
    const getTrainingTypes = () => {
        AdministrationService.getTrainingTypes().then((res) => {
            setTrainingTypes(res.data);
        });
    }

    useEffect(() => {
        getTrainingTypes();
    }, []);

    return (
        <>
            <div className="mb-3">
                <CreateOrEditTrainingTypesModal mode={globalProps.mode.create}/>
            </div>
            <Table responsive>
                <thead>
                <tr className="table-active">
                    <th>#</th>
                    <th>Название</th>
                    <th></th>
                </tr>
                </thead>
                {trainingTypes.length ?
                    <tbody>
                    {trainingTypes.map((trainingType, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{trainingType.name}</td>
                            <td>
                                <Form.Check
                                    disabled
                                    type="switch"
                                    checked={trainingType.active}
                                />
                            </td>
                            <td>
                                <div className={"d-flex gap-1"}>
                                    <CreateOrEditTrainingTypesModal trainingType={trainingType}
                                                                    mode={globalProps.mode.edit}/>
                                    <DeleteModal id={trainingType.id} fnDelete={TrainingTypesService.delete}/>
                                </div>
                            </td>
                        </tr>)
                    }
                    </tbody>
                    :
                    <caption className={"text-center"}>Виды тренировок не найдены</caption>
                }
            </Table>
        </>
    )
}

export default TrainingTypesMain;