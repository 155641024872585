import React, {useEffect, useState} from "react";
import DeleteModal from "../../DeleteModal";
import GameLevelService from "../../../services/GameLevelService";
import CreateOrEditGameLevelModal from "./CreateOrEditGameLevelModal";
import {globalProps} from "../../../config";
import {Table} from "react-bootstrap";
import Form from 'react-bootstrap/Form';

const GameLevelsComponent = () => {
    const [gameLevels, setGameLevels] = useState([]);
    const getGameLevels = () => {
        GameLevelService.getGameLevels().then((res) => {
            setGameLevels(res.data);
        });
    }

    useEffect(() => {
        getGameLevels();
    }, []);

    return (
        <>
            <div className="mb-3">
                <CreateOrEditGameLevelModal mode={globalProps.mode.create}/>
            </div>
            <Table responsive>
                <thead>
                <tr className="table-active">
                    <th>#</th>
                    <th>Название</th>
                    <th>Приоритет</th>
                    <th>Описание</th>
                    <th>Активный</th>
                    <th></th>
                </tr>
                </thead>
                {gameLevels.length ?
                    <tbody>
                    {gameLevels.map((gameLevel, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{gameLevel.name}</td>
                            <td>{gameLevel.levelOrder}</td>
                            <td>{gameLevel.description}</td>
                            <td>
                                <Form.Check
                                    disabled
                                    type="switch"
                                    checked={gameLevel.active}
                                />
                            </td>
                            <td>
                                <div className={"d-flex gap-1"}>
                                    <CreateOrEditGameLevelModal gameLevel={gameLevel}
                                                                mode={globalProps.mode.edit}/>
                                    <DeleteModal id={gameLevel.id} fnDelete={GameLevelService.delete}/>
                                </div>
                            </td>
                        </tr>)
                    }
                    </tbody>
                    :
                    <caption className={"text-center"}>Уровни игры не найдены</caption>
                }
            </Table>
        </>
    )
}
export default GameLevelsComponent;