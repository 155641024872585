import axios from "../axios";

class AdministrationService {
    getTrainings(data) {
        return axios.post('administration/trainings', data);
    }

    getGyms() {
        return axios.get('administration/gyms');
    }

    getSportTypes() {
        return axios.get('administration/sportTypes');
    }

    getGameLevels() {
        return axios.get('administration/gameLevels');
    }

    getUsers(data) {
        return axios.post('administration/users', data);
    }

    getInactiveUsers() {
        return axios.get('administration/users/inactive');
    }

    getEventLog(data) {
        return axios.post('administration/eventLogs', data);
    }

    getTrainingEventLogs(options) {
        return axios.post('administration/eventLogs', options);
    }

    getNews() {
        return axios.get('administration/news');
    }

    getDebtors(options) {
        return axios.post('administration/debtors', options);
    }

    getTrainingTypes() {
        return axios.get('administration/trainingTypes');
    }

    /**Парсинг строки с ранжированием взноса
     * @param str Строка с ранжированием взноса вида: взнос|до;взнос|до;взнос|до...
     * @return [] массив объектов вида: {id: хеш, price: взнос, toCount: до}
     * */
    parsePaymentRankingStr(str) {
        const newRankingElements = [];
        if (str) {
            const paymentRankingElements = str.split(';');
            for (let i = 0; i < paymentRankingElements.length; i++) {
                const priceWithCount = paymentRankingElements[i].split('|');
                if (priceWithCount && priceWithCount.length === 2) {
                    newRankingElements.push({
                        id: (Math.random() + 1).toString(36).substring(7),
                        price: priceWithCount[0] ? priceWithCount[0] : '',
                        toCount: priceWithCount[1] ? priceWithCount[1] : ''
                    });
                }
            }
        }
        return newRankingElements;
    }

    /**Формирование строки с ранжированием взноса
     * @param paymentRankingObjArr массив объектов вида: {id: хеш, price: взнос, toCount: до}
     * @return String Строка с ранжированием взноса вида: взнос|до;взнос|до;взнос|до...
     * */
    createPaymentRanking(paymentRankingObjArr) {
        let paymentRankingStr = '';
        paymentRankingObjArr.forEach(function (element) {
            paymentRankingStr += element.price + '|' + element.toCount + ';';
        });
        paymentRankingStr = paymentRankingStr.slice(0, -1);
        return paymentRankingStr;
    }
}

export default new AdministrationService()