import Button from "react-bootstrap/Button";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import {IconButton} from "@mui/material";
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import NewsService from "../../../services/NewsService";
import Stack from "@mui/material/Stack";

const EditNews = ({newsItem}) => {
    const [show, setShow] = useState(false);

    const [newsDescription, setNewsDescription] = useState('');
    const [error, setError] = useState('');
    const handleChange = (content) => {
        setNewsDescription(content);
    };

    const newsImageSrc = useMemo(() => {
        return `${process.env.REACT_APP_HOST}/images/${newsItem.imageName}`
    }, [newsItem]);

    const saveNews = useCallback(() => {
        if (newsDescription.trim() === '' || newsDescription === '<p><br></p>') {
            setError('Текст новости не может быть пустым');
        } else {
            setError('');
            const formData = new FormData();
            formData.append('description', newsDescription);
            formData.append('id', newsItem.id);

            NewsService.save(formData).then(_res => {
                window.location.reload();
            });
        }
    }, [newsItem, newsDescription]);

    useEffect(() => {
        setNewsDescription(newsItem.description);
    }, [newsItem]);

    return (
        <>
            <IconButton onClick={() => setShow(true)} title="Редактирование новости">
                <EditIcon />
            </IconButton>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование новости</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactQuill value={newsDescription} onChange={handleChange}/>
                    {error && <div style={{ color: 'red', marginTop: '0.5rem' }}>{error}</div>}

                    {newsImageSrc &&
                        <Stack gap={2} className="mt-3">
                            <Alert severity="info">Изображение нельзя отредактировать</Alert>
                            <img
                                src={newsImageSrc}
                                alt="Preview"
                                style={{width: '200px', height: '200px', objectFit: 'contain'}}
                            />
                        </Stack>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                    Отмена
                    </Button>
                    <Button onClick={saveNews}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditNews;