import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {Pagination} from "react-bootstrap";

/** Пагинация для таблиц
 * @param data данные для пагинации
 * @param pageCfg конфиг с параметрами для получения пагинации
 * @param setPageCfg функция изменения конфига с параметрами
 * @param changeItemsCount Изменять ли количество элементов на странице таблицы
 * @param hideTotalCount Скрывать ли общее количество элементов таблицы
 * @param maxVisiblePages Максимальное количество видимых страниц для их переключения
 * */
const PaginationComponent = ({
                                 data,
                                 pageCfg,
                                 setPageCfg,
                                 changeItemsCount,
                                 hideTotalCount,
                                 maxVisiblePages
                             }) => {
    const MAX_VISIBLE_PAGES = maxVisiblePages ? maxVisiblePages : 10;
    const [startVisiblePage, setStartVisiblePage] = useState(1);
    const [endVisiblePage, setEndVisiblePage] = useState(startVisiblePage + MAX_VISIBLE_PAGES - 1);

    const changeItemsOnPage = (event) => {
        const newCfg = {
            ...pageCfg,
            page: 0,
            size: event.target.value
        };
        setPageCfg(newCfg);
    }

    const paginationItemClickHandle = (value) => {
        if (value >= 1 && value <= data.totalPages) {
            if (startVisiblePage > value) {
                let startNum = startVisiblePage;
                while (startNum > value) {
                    startNum--;
                }
                setStartVisiblePage(startNum);
                setEndVisiblePage(startNum + MAX_VISIBLE_PAGES - 1);
            }

            if (endVisiblePage < value) {
                let endCount = endVisiblePage;
                while (endCount < value) {
                    endCount++;
                }
                setEndVisiblePage(endCount);
                setStartVisiblePage(endCount - MAX_VISIBLE_PAGES + 1);
            }
            const newCfg = {
                ...pageCfg,
                page: value - 1
            };
            setPageCfg(newCfg);
        }
    }

    let paginationItems = [];
    for (let number = startVisiblePage; number <= data.totalPages && number <= endVisiblePage; number++) {
        paginationItems.push(<Pagination.Item key={number} active={number === data.currentPage + 1}
                                              onClick={() => paginationItemClickHandle(number)}>
            {number}
        </Pagination.Item>);
    }

    return (
        <div className={"mt-3 w-100 justify-content-between d-flex align-items-start"}>
            {!hideTotalCount && <small className="text-muted">Кол-во записей: {data.totalItems}</small>}
            <Pagination className={`justify-content-center ${hideTotalCount && !changeItemsCount ? " w-100" : ""}`}>
                <Pagination.First onClick={() => paginationItemClickHandle(1)}/>
                <Pagination.Prev onClick={() => paginationItemClickHandle(data.currentPage)}/>
                <Pagination.Ellipsis/>
                {paginationItems}
                <Pagination.Ellipsis/>
                <Pagination.Next onClick={() => paginationItemClickHandle(data.currentPage + 2)}/>
                <Pagination.Last onClick={() => paginationItemClickHandle(data.totalPages)}/>
            </Pagination>
            {changeItemsCount &&
                <div>
                    <Form.Select onChange={changeItemsOnPage} value={pageCfg?.size}>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </Form.Select>
                </div>}
        </div>
    )
}

export default PaginationComponent;