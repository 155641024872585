import React, {useCallback} from 'react';
import {useLocalState} from "../../util/useLocatStorage";
import Card from 'react-bootstrap/Card';
import Stack from '@mui/material/Stack';
import ActionWithTrainingForAdmin from "./actions/ActionWithTrainingForAdmin";
import TrainingParticipantsPopover from "./TrainingParticipantsPopover";
import TrainingPrice from "../trainingPage/TrainingPrice";
import TrainingButtons from "../trainingPage/TrainingButtons";
import {globalProps} from "../../config";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {IconButton} from "@mui/material";
import openTrainingCard from "../trainingPage/util";

const TrainingComponent = ({training}) => {
    const [currentUserRole] = useLocalState({}, "currentUserRole");

    const openTraining = useCallback(() => {
        openTrainingCard(training.id);
    }, [training]);

    return (
        <Card style={{width: '20rem'}} className="mb-2">
            <Card.Body>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={openTraining}>
                        <OpenInNewIcon/>
                    </IconButton>
                    <Card.Text className="fw-bold mb-0">{training.gym.name}</Card.Text>
                    <TrainingPrice training={training}/>
                </Stack>
                <Card.Subtitle className="mb-2 text-muted">{training.gym.address}</Card.Subtitle>
                <div>{"C " + training.startTime + " до " + training.endTime}</div>
                <div>{training.sportType.name} ({training.minGameLevel.name})</div>
                {training.trainingType && <div>{training.trainingType.name}</div>}
                <Stack>
                    <TrainingParticipantsPopover training={training}/>
                    <TrainingButtons training={training} buttonLocation={globalProps.trainingButtonsLocation.SCHEDULE}/>
                </Stack>
                {currentUserRole === 'ADMIN' && <ActionWithTrainingForAdmin training={training}/>}
            </Card.Body>
        </Card>
    );
};

export default TrainingComponent;
