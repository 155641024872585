const globalProps = {
    mode: {
        create: "create",
        edit: "edit"
    },
    trainingStatus: {
        NEW: "Новая",
        COMPLETED: "Завершенная",
        CANCELLED: "Отмененная"
    },
    requestsStatus: {
        NEW: "Новая",
        ACCEPTED: "Принята",
        REJECTED: "Отклонена"
    },
    userRole: {
        NONE: "Без роли",
        READER: "Читатель",
        USER: "Пользователь",
        RESPONSIBLE_USER: "Админ тренировки",
        ADMIN: "Главный админ"
    },
    userStatus: {
        NONE: "Без статуса",
        ACTIVE: "Активный",
        BANNED: "Заблокированный",
        ARCHIVED: "Архивированный"
    },
    actionsWithTraining: {
        FINISH: "Отметка присутствующих на тренировке",
        MARK_PAYMENT: "Оплата тренировок"
    },
    administrationPageTabs: {
        trainings: "Тренировки",
        users: "Пользователи",
        gyms: "Залы",
        gameLevels: "Уровни игры",
        sportTypes: "Виды спорта",
        trainingTypes: "Виды тренировок",
        eventLog: "Журнал событий",
        news: "Новости",
        requests: "Заявки"
    },
    trainingButtonsLocation: {
        SCHEDULE: 'SCHEDULE',
        TRAINING_PAGE: 'TRAINING_PAGE'
    }
}
export {globalProps}