import {useState} from "react";
import GymService from "../../../services/GymService";
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Alert, FloatingLabel, Form} from "react-bootstrap";
import {globalProps} from "../../../config";

export default function CreateOrEditGymModal({gym, mode}) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const [name, setName] = useState(gym ? gym.name : null);
    const [address, setAddress] = useState(gym ? gym.address : null);
    const [isActive, setIsActive] = useState(gym ? gym.active : null);

    const changeName = (event) => {
        setName(event.target.value);
    }

    const changeAddress = (event) => {
        setAddress(event.target.value);
    }

    const changeIsActive = (event) => {
        setIsActive(event.target.checked);
    }

    const saveGym = () => {
        const currentGym = {
            name: name,
            address: address,
            mode: mode
        }
        if (mode === globalProps.mode.edit) {
            currentGym.gymId = gym.id;
            currentGym.active = isActive;
        }
        GymService.save(currentGym).then(res => {
            setShow(false);
            setIsMessageOpen(true);
            setMessageType("success");
            setMessageText(res.data.message);
            setShowOKButtonInMessage(false);

            setTimeout(() => {
                setIsMessageOpen(false);
                window.location.reload();
            }, 1500);
        }).catch(message => {
            if (message.response && message.response.data) {
                if (typeof message.response.data === "string") {
                    setMessageText(message.response.data);
                } else if (message.response.data.message && typeof message.response.data.message === "string") {
                    setMessageText(message.response.data.message);
                }
            } else {
                setMessageText("Произошла ошибка");
            }
            setIsMessageOpen(true);
            setMessageType("danger");
            setShowOKButtonInMessage(true);
        });
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (name && address) {
            event.preventDefault();
            saveGym(event);
        }
        setValidated(true);
    };

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, setMessageType] = useState("success");
    const [showOKButtonInMessage, setShowOKButtonInMessage] = useState(false);

    return (
        <>
            {mode === globalProps.mode.create ?
                <Button variant={"secondary"} onClick={handleShow}>Добавить зал</Button>
                :
                <div role={"button"} onClick={handleShow} title={"Редактировать"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path
                            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === globalProps.mode.create ? "Создание" : "Изменение"} зала</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <FloatingLabel label="Название" className="mb-3">
                            <Form.Control required type="text" onChange={changeName} defaultValue={name}/>
                        </FloatingLabel>
                        <FloatingLabel label="Адрес" className="mb-3">
                            <Form.Control required type="text" onChange={changeAddress} defaultValue={address}/>
                        </FloatingLabel>
                        {mode === globalProps.mode.edit &&
                            <Form.Check
                                reverse
                                label="Активный"
                                type="switch"
                                defaultChecked={isActive}
                                onChange={changeIsActive}
                            />
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Закрыть</Button>
                    <Button variant="primary" onClick={handleSubmit}>Сохранить</Button>
                </Modal.Footer>
            </Modal>

            {/*Всплывающее сообщение*/}
            {isMessageOpen && <Alert variant={messageType} className={"alertMessage"}>
                <div className={"p-3"}>{messageText}</div>
                {showOKButtonInMessage && <div className="d-flex justify-content-end">
                    <Button onClick={() => {
                        setIsMessageOpen(false);
                    }}
                            variant={"outline-" + messageType}
                            size="sm">
                        OK
                    </Button>
                </div>
                }
            </Alert>
            }
        </>
    )
}