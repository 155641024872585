import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import NewsService from "../../../services/NewsService";
import Message from "../../Message";

export default function CreateNewsModal({allNews}) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const [newsItemCode, setNewsItemCode] = useState({});

    const [error, setError] = useState("");
    const [isNewsCodeCorrect, setIsNewsCodeCorrect] = useState(false);

    const changeNewsItemCode = (event) => {
        let codeStr = event.target.value;
        codeStr = codeStr.replaceAll(" ", "");
        codeStr = codeStr.replaceAll("\n", "");
        if (codeStr.indexOf("(function(){") !== -1) {
            codeStr = codeStr.substring(codeStr.indexOf("(function(){") + 12, codeStr.indexOf("}());"));
            if (codeStr.indexOf("VK.Widgets.Post(") !== -1) {
                codeStr = codeStr.substring(codeStr.indexOf("VK.Widgets.Post(") + 16, codeStr.indexOf(");"));
                codeStr = codeStr.replaceAll("\"", "");
                codeStr = codeStr.replaceAll('\'', "");
                const data = codeStr.split(",");
                if (data[0] && data[1] && data[2] && data[3]) {
                    setNewsItemCode({
                        element_id: data[0],
                        owner_id: data[1],
                        post_id: data[2],
                        hash: data[3]
                    });
                    setIsNewsCodeCorrect(true);
                }
            }
        }
    }

    const saveNews = (e) => {
        e.preventDefault();

        if (!isNewsCodeCorrect) {
            setError("Данный текст не является кодом новости повторите попытку!");
            return;
        } else {
            for (let news of allNews) {
                if (news.element_id === newsItemCode.element_id) {
                    setError("Такая новость уже добавлена. Выберите другую новость и повторите попытку.");
                    return;
                }
            }
        }

        NewsService.saveNewsFromVK(newsItemCode).then(_res => {
            setShow(false);
            setIsMessageOpen(true);
            setMessageText("Успешно");
        });
    }


    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageType, _setMessageType] = useState("success");

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Понятно
            </Button>

            <Modal show={show} onHide={handleClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление новости</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error
                        ?
                        <p className="alert alert-danger alert-dismissible fade show" key={error}>
                            <strong>Ошибка:</strong> {error}
                        </p>
                        :
                        null
                    }
                    <form>
                        <div className="row mb-3">
                            <label htmlFor="newsItemCode" className="col-sm-2 col-form-label">Код новости: </label>
                            <div className="col-sm-10">
                                <textarea className="form-control" id="newsItemCode" rows={8}
                                          required={true} onChange={changeNewsItemCode}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={saveNews}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*Всплывающее сообщение*/}
            {isMessageOpen ?
                <Message text={messageText} type={messageType} showOKButton={false}/> : null}
        </>
    );
}