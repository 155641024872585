import React, {useState} from 'react';
import logo from '../../images/bls-logo.png';
import {Carousel, Image} from "react-bootstrap";
import HomeCarouselImage from "../home/HomeCarouselImage";
import volleyballImg from '../../images/carousel/volleyball.jpg';
import basketballImg from '../../images/carousel/basketball.jpg';
import streetballImg from '../../images/carousel/streetball.jpg';
import tourneyImg from '../../images/carousel/tourney.jpg';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {useLocalState} from "../../util/useLocatStorage";

const HomeComponent = () => {
    const [token] = useLocalState("", "jwt");

    // Для стилей под мобилки
    const [matches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <div className={matches ? "d-flex" : null}>
            <Carousel variant={"dark"} activeIndex={index} onSelect={handleSelect} className={matches ? "w-50" : null}>
                <Carousel.Item>
                    <HomeCarouselImage imageRef={volleyballImg}/>
                    <Carousel.Caption>
                        <h3>Волейбол</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <HomeCarouselImage imageRef={basketballImg}/>
                    <Carousel.Caption>
                        <h3>Баскетбол</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <HomeCarouselImage imageRef={streetballImg}/>
                    <Carousel.Caption>
                        <h3>Стритбол</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <HomeCarouselImage imageRef={tourneyImg}/>
                    <Carousel.Caption>
                        <h3>Турниры</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className={"d-flex flex-column justify-content-center align-items-center" + (matches ? " w-50" : null)}>
                <Image src={logo} fluid className={"w-25 m-3"}></Image>
                <main className={"d-flex flex-column justify-content-center align-items-center m-5"}>
                    <h1>Башкирская лига спорта</h1>
                    <p className="lead">Приглашаем всех желающих на игровые тренировки и тренировки с тренером</p>
                    {token ?
                        <Link to="/trainings/schedule">
                            <Button>Записаться на тренировки</Button>
                        </Link>
                        :
                        <Link to="/registration">
                            <Button>Присоединиться</Button>
                        </Link>
                    }
                </main>
            </div>
        </div>
    );
}

export default HomeComponent;
