import {useLocalState} from "../../util/useLocatStorage";
import React, {useState} from "react";
import axios from "axios";
import logo from "../../images/bls-logo.png";
import {Alert, Card, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PasswordRecoveryDialog from "./PasswordRecoveryDialog";
import {jwtDecode} from "jwt-decode";

const LoginComponent = () => {
    const [, setJwt] = useLocalState("", "jwt");
    const [, setRefreshToken] = useLocalState("", "refreshToken");
    const [, setCurrentUserRole] = useLocalState({}, "currentUserRole");

    // Для стилей под мобилки
    const [matches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    //Всплывающие сообщения
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [messageText, setMessageText] = useState("");

    const [isPasswordRecoveryDialogOpen, setIsPasswordRecoveryDialogOpen] = useState(false);

    const sendLoginRequest = (event, reqBody) => {
        axios.post(process.env.REACT_APP_HOST + "/api/auth/login", reqBody).then((response) => {
            if (response.status === 200) {
                setJwt(response.data.token);
                setRefreshToken(response.data.refreshToken);
                const decoded = jwtDecode(response.data.token);
                if (decoded.role) {
                    setCurrentUserRole(decoded.role);
                }
                window.location.href = "/";
            }
        }).catch((message) => {
            event.preventDefault();
            event.stopPropagation();
            setIsMessageOpen(true);
            if (message.response && message.response.data) {
                setMessageText(message.response.data);
            } else {
                setMessageText('Возникла непредвиденная ошибка');
            }
        });
    }

    const [isLoginValid, setIsLoginValid] = useState(true);
    const [invalidLoginTooltip, setInvalidLoginTooltip] = useState("Введите корректный e-mail или номер телефона");

    const changeLogin = (event) => {
        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
        const allPhoneNumberFormatsRegex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g;
        const validPhoneNumberFormat = /^9\d{9}/g;

        const loginValue = event.target.value;
        if (loginValue) {
            if (emailRegex.test(loginValue)) {
                setIsLoginValid(true);
            } else {
                if (!allPhoneNumberFormatsRegex.test(loginValue)) {
                    setIsLoginValid(false);
                    setInvalidLoginTooltip("Введите корректный e-mail или номер телефона");
                } else if (!validPhoneNumberFormat.test(loginValue)) {
                    setIsLoginValid(false);
                    setInvalidLoginTooltip("Номер телефона необходимо вводить с 9");
                } else {
                    setIsLoginValid(true);
                }
            }
        }
    }

    const handleSubmit = (event) => {
        if (!isLoginValid) {
            return;
        }
        setIsMessageOpen(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        const reqBody = {
            email: form.form[0].value,
            password: form.form[2].value
        }
        if (reqBody.email && reqBody.password) {
            sendLoginRequest(event, reqBody);
        }
    };

    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState("password");

    const openEyeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-eye-fill" viewBox="0 0 16 16">
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
        <path
            d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>;

    const closeEyeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
        <path
            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
        <path
            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
    </svg>;

    const togglePasswordVisibility = () => {
        if (isPasswordOpen) {
            setPasswordInputType("password");
            setIsPasswordOpen(false);
        } else {
            setPasswordInputType("text");
            setIsPasswordOpen(true);
        }
    }

    return (
        <div className={"d-flex justify-content-center align-items-center"} style={{userSelect: "none"}}>
            <Card className={"d-flex justify-content-center align-items-center " + (matches ? "w-50" : "w-100")}>
                <Card.Img variant="top" src={logo} className={"w-50 p-3"}/>
                <Card.Body className={"w-100"}>
                    <Form noValidate onSubmit={handleSubmit}
                          className={"d-flex flex-column justify-content-center align-items-center"}>
                        <FloatingLabel label="E-mail или номер телефона" className="w-100">
                            <Form.Control isInvalid={!isLoginValid} type="text" onChange={changeLogin}
                                          placeholder="name@example.com"/>
                            <Form.Control.Feedback type="invalid" tooltip>
                                {invalidLoginTooltip}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <Button variant="link" className={"ms-auto"}
                                onClick={() => setIsPasswordRecoveryDialogOpen(true)}>
                            Забыли пароль?
                        </Button>
                        <InputGroup className="mb-3 w-100">
                            <Form.Control required type={passwordInputType} placeholder="Пароль"/>
                            <InputGroup.Text onClick={togglePasswordVisibility} role={"button"}>
                                {isPasswordOpen ? closeEyeIcon : openEyeIcon}
                            </InputGroup.Text>
                        </InputGroup>

                        {isMessageOpen ? <Alert className={"w-100"} variant={"danger"}>{messageText}</Alert> : null}

                        <Button onClick={handleSubmit} className={"w-100 my-3"}>Войти</Button>

                        <div>
                            <p className="mb-0">
                                Нет аккаунта?
                                <a href="/registration" className="fw-bold">
                                    Зарегистрироваться
                                </a>
                            </p>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <PasswordRecoveryDialog open={isPasswordRecoveryDialogOpen}
                                    handleClose={() => setIsPasswordRecoveryDialogOpen(false)}/>
        </div>
    );
};

export default LoginComponent;
