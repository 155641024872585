import React, {useState} from "react";
import UsersMain from "./users/UsersMain";
import InactiveUsersComponent from "./users/InactiveUsersComponent";
import GymsComponent from "./gyms/GymsComponent";
import GameLevelsComponent from "./gameLevels/GameLevelsComponent";
import SportTypesComponent from "./sportTypes/SportTypesComponent";
import EventLogComponent from "./EventLogComponent";
import NewsComponent from "./news/NewsComponent";
import {Col, Nav, Offcanvas, Row, Tab} from "react-bootstrap";
import {useLocalState} from "../../util/useLocatStorage";
import Button from "react-bootstrap/Button";
import {globalProps} from "../../config";
import GymsComponentMobile from "./gyms/mobileComponents/GymsComponentMobile";
import GameLevelsComponentMobile from "./gameLevels/mobileComponents/GameLevelsComponentMobile";
import SportTypesMobile from "./sportTypes/mobileComponents/SportTypesMobile";
import TrainingTypesMain from "./trainingTypes/TrainingTypesMain";
import TrainingTypesMobile from "./trainingTypes/mobileComponents/TrainingTypesMobile";
import RequestsComponent from "./requests/RequestsComponent";
import TrainingsMain from "./trainings/TrainingsMain";

const AdministrationMainComponent = () => {

    const [isMobile] = useState(
        window.matchMedia("(max-width: 479px)").matches
    )

    const [activeAdministrationTab, setActiveAdministrationTab] = useLocalState("trainings", "activeAdministrationTab");


    const [show, setShow] = useState(false);
    const [tabName, setTabName] = useState(globalProps.administrationPageTabs[activeAdministrationTab]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const selectNavItem = (key) => {
        setActiveAdministrationTab(key);
        setTabName(globalProps.administrationPageTabs[key]);
        handleClose();
    }

    function NavigationComponent({variant}) {
        return (
            <Nav variant={variant} className="flex-column">
                <Nav.Item onClick={() => selectNavItem('trainings')}>
                    <Nav.Link eventKey="trainings">Тренировки</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('users')}>
                    <Nav.Link eventKey="users">Пользователи</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('gyms')}>
                    <Nav.Link eventKey="gyms">Залы</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('gameLevels')}>
                    <Nav.Link eventKey="gameLevels">Уровни игры</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('sportTypes')}>
                    <Nav.Link eventKey="sportTypes">Виды спорта</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('trainingTypes')}>
                    <Nav.Link eventKey="trainingTypes">Виды тренировок</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('eventLog')}>
                    <Nav.Link eventKey="eventLog">Журнал событий</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('news')}>
                    <Nav.Link eventKey="news">Новости</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => selectNavItem('requests')}>
                    <Nav.Link eventKey="requests">Заявки</Nav.Link>
                </Nav.Item>
            </Nav>
        );
    }

    return (
        <Tab.Container defaultActiveKey={activeAdministrationTab}>
            <Row>
                <Col sm={2}>
                    {isMobile ?
                        <>
                            <Button className="w-100 mb-2 mt-2" onClick={handleShow}>{tabName}</Button>

                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Выбор</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <NavigationComponent variant="underline"/>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </>
                        :
                        <NavigationComponent variant="pills"/>
                    }
                </Col>
                <Col sm={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="trainings">
                            <TrainingsMain/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="users">
                            <InactiveUsersComponent/>
                            <UsersMain/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gyms">{isMobile ? <GymsComponentMobile/> : <GymsComponent/>}</Tab.Pane>
                        <Tab.Pane eventKey="gameLevels">
                            {isMobile ? <GameLevelsComponentMobile/> : <GameLevelsComponent/>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="sportTypes">
                            {isMobile ? <SportTypesMobile/> : <SportTypesComponent/>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="trainingTypes">
                            {isMobile ? <TrainingTypesMobile/> : <TrainingTypesMain/>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="eventLog"><EventLogComponent/></Tab.Pane>
                        <Tab.Pane eventKey="news"><NewsComponent/></Tab.Pane>
                        <Tab.Pane eventKey="requests"><RequestsComponent/></Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default AdministrationMainComponent;
