import React, {useState, useEffect} from 'react';
import {Alert} from 'react-bootstrap';
import Button from "react-bootstrap/Button";

const Message = ({text, type, showOKButton}) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (!showOKButton) {
            const timer = setTimeout(() => {
                setIsVisible(false);
                window.location.reload();
            }, 1500); // Сообщение будет отображаться 1.5 секунды

            return () => {
                clearTimeout(timer);
            };
        }
    }, [showOKButton]);

    return (
        isVisible &&
        <Alert variant={type} className={"alertMessage"} >
            <div className={"p-3"}>{text}</div>
            {showOKButton
                ?
                <div className="d-flex justify-content-end">
                    <Button onClick={() => {
                        setIsVisible(false);
                        window.location.reload();
                    }} variant={"outline-" + type} size="sm">
                        OK
                    </Button>
                </div>
                : null}
        </Alert>
    )
};

export default Message;
