import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter} from "react-router-dom";
import "./index.css";
import {MessageProvider} from "./components/message/MessageProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <MessageProvider>
            <App/>
        </MessageProvider>
    </BrowserRouter>
);

